define("cheddargorge/helpers/sku-title", ["exports", "@ember/component/helper", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _helper, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getSkuTitle = getSkuTitle;
  var _default = _helper.default.extend({
    goods: (0, _service.inject)('goods'),
    compute(params) {
      return getSkuTitle(this.goods, params[0], params[1]);
    }
  });
  _exports.default = _default;
  function getSkuTitle(goods, sku, template) {
    // const sku = params[0];
    // const template = params[1];
    const fields = goods.fieldsToHash((0, _object.get)(sku, 'skuFields'));
    if ((0, _utils.isEmpty)(template)) {
      return fields.name;
    }
    return Object.keys(fields).reduce((title, key) => {
      let regex = new RegExp(`{{${key}}}`, 'gi');
      let value = fields[key];
      if (key === 'session-start-time') {
        value = fields[key][0];
        value = value.substring(0, value.length - 3);
      }
      return title.replace(regex, value);
    }, template);
  }
});