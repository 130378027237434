define("cheddargorge/components/global/app-new-version.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "app-new-version": "_app-new-version_2f7nji",
    "message": "_message_2f7nji",
    "dismiss": "_dismiss_2f7nji"
  };
  _exports.default = _default;
});