define("cheddargorge/components/calendar-controls/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "calendar-controls-wrapper": "_calendar-controls-wrapper_18jfn2",
    "calendar-controls": "_calendar-controls_18jfn2",
    "btn-next": "_btn-next_18jfn2",
    "btn-previous": "_btn-previous_18jfn2",
    "date": "_date_18jfn2"
  };
  _exports.default = _default;
});