define("cheddargorge/components/price-table/component", ["exports", "@ember/object/computed", "@ember/component"], function (_exports, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'price-table',
    localClassNameBindings: ['rowsAreSelectable'],
    rowsAreSelectable: (0, _computed.notEmpty)('selectRow'),
    selectRow: null,
    showTable: (0, _computed.notEmpty)('data'),
    actions: {
      selectRow(data) {
        if (this.selectRow) {
          this.selectRow(data);
        }
      }
    }
  });
  _exports.default = _default;
});