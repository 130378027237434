define("cheddargorge/components/ui-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-button": "_ui-button_17cqo0",
    "ui-spinner": "_ui-spinner_17cqo0",
    "is-loading": "_is-loading_17cqo0",
    "label": "_label_17cqo0",
    "large": "_large_17cqo0",
    "small": "_small_17cqo0",
    "theme-text": "_theme-text_17cqo0"
  };
  _exports.default = _default;
});