define("cheddargorge/templates/explorer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LWhyfnoM",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\\n\"],[1,[34,3]]],[],false,[\"find-it-fast\",\"component\",\"-outlet\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/explorer.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});