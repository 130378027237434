define("cheddargorge/components/share-with-friends/component", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'share-with-friends',
    url: (0, _object.computed)(function () {
      if ((0, _utils.isEmpty)(window)) {
        return window.location.href;
      }
      return '';
    })
  });
  _exports.default = _default;
});