define("cheddargorge/components/sku-group-quantity-input/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cm30aUrV",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[\"attributes.priority\",[33,3,[\"skus\"]]],null]],null]],null],null,[[[1,\"  \"],[1,[28,[35,4],null,[[\"sku\",\"date\",\"basketItems\",\"skuTitleTemplate\",\"skuMinQuantity\",\"skuMinAvailable\",\"canIncrementForProduct\",\"createBasketItem\",\"deleteBasketItem\"],[[30,1],[30,2],[30,3],[30,4,[\"attributes\",\"sku-title-template\"]],[30,4,[\"attributes\",\"sku-min-quantity\"]],[30,4,[\"attributes\",\"sku-min-available\"]],[30,0,[\"canIncrement\"]],[30,5],[30,6]]]]],[1,\"\\n\"]],[1]],null]],[\"sku\",\"@date\",\"@basketItems\",\"@product\",\"@createBasketItem\",\"@deleteBasketItem\"],false,[\"each\",\"-track-array\",\"sort-by\",\"skuGroup\",\"sku-quantity-minus-plus\"]]",
    "moduleName": "cheddargorge/components/sku-group-quantity-input/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});