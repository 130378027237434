define("cheddargorge/helpers/has-key", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasKey = hasKey;
  function hasKey(_ref) {
    let [object, key] = _ref;
    return Object.keys(object).includes(key);
  }
  var _default = (0, _helper.helper)(hasKey);
  _exports.default = _default;
});