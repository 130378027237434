define("cheddargorge/components/ui/hero/component", ["exports", "@glimmer/component", "@ember/utils", "@ember/object"], function (_exports, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let UiHero = (_class = class UiHero extends _component.default {
    /**
     *
     */
    get hasVideo() {
      return !(0, _utils.isEmpty)(this.args.videoMp4) && !(0, _utils.isEmpty)(this.args.videoWebm) && !(0, _utils.isEmpty)(this.args.videoOgv);
    }

    /**
     *
     */
    get hasMobileVideo() {
      return !(0, _utils.isEmpty)(this.args.mobileVideoMp4) && !(0, _utils.isEmpty)(this.args.mobileVideoWebm) && !(0, _utils.isEmpty)(this.args.mobileVideoOgv);
    }

    /**
     *
     */
    onDidInsertVideo(element) {
      let video = element.querySelector('video');
      if (!(0, _utils.isNone)(video)) {
        video.muted = true;
        video.play();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onDidInsertVideo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsertVideo"), _class.prototype)), _class);
  _exports.default = UiHero;
});