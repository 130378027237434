define("cheddargorge/components/share-with-friends/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "share-with-friends": "_share-with-friends_1mtir5",
    "title": "_title_1mtir5",
    "social-icons": "_social-icons_1mtir5",
    "facebook": "_facebook_1mtir5",
    "twitter": "_twitter_1mtir5"
  };
  _exports.default = _default;
});