define("cheddargorge/components/basket/warning-message/junior-caving-only-has-child", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="Warning"
    >
      <p>
        You only have Child Tickets in your basket. Please note that children under the age of 16 need to be accompanied by an adult (18+yrs) with a ticket for the same session.
      </p>
    </Basket::WarningMessage>
  {{/if}}
  */
  {
    "id": "FaOneVok",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"Warning\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      You only have Child Tickets in your basket. Please note that children under the age of 16 need to be accompanied by an adult (18+yrs) with a ticket for the same session.\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/junior-caving-only-has-child.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageJuniorCavingOnlyHasChild extends _component2.default {
    /**
     * Is the warning message visible? Shows if there are Junior Caving
     * tickets for children in the basket and none for adults
     */
    get isVisible() {
      let productBasketItems = this.args.basketItems.filterBy('sku.content.product.slug', 'rocksport-junior-caving');
      let adults = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Adult');
      let children = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Child');
      return (0, _utils.isPresent)(children) && (0, _utils.isEmpty)(adults);
    }
  }
  _exports.default = BasketWarningMessageJuniorCavingOnlyHasChild;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageJuniorCavingOnlyHasChild);
});