define("cheddargorge/routes/visitor-info/opening-times", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    model() {
      return this.store.query("visitor-info", {
        filter: {
          slug: "opening-times"
        }
      }).then(this._getFirstObject.bind(this));
    },
    afterModel(openingTimes) {
      (0, _object.set)(this, "headData.title", openingTimes.metaTitle);
      (0, _object.set)(this, "headData.description", openingTimes.metaDescription);
    },
    _getFirstObject(object) {
      return object.firstObject;
    }
  });
  _exports.default = _default;
});