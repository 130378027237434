define("cheddargorge/models/field-schema", ["exports", "ember-goods/models/field-schema"], function (_exports, _fieldSchema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fieldSchema.default;
    }
  });
});