define("cheddargorge/routes/education/resources/planning-and-booking", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    model() {
      return _rsvp.default.hash({
        planningAndBooking: this.store.query("education-resource", {
          filter: {
            slug: "planning-and-booking"
          }
        }).then(this._getFirstObject.bind(this)),
        healthAndSafety: this.store.query("education-resource", {
          filter: {
            slug: "health-and-safety"
          }
        }).then(this._getFirstObject.bind(this)),
        pricesAndPayment: this.store.query("education-resource", {
          filter: {
            slug: "prices-and-payment"
          }
        }).then(this._getFirstObject.bind(this)),
        frequentlyAskedQuestions: this.store.query("education-resource", {
          filter: {
            slug: "faq"
          }
        }).then(this._getFirstObject.bind(this)),
        education: this.store.query("organisation-department", {
          filter: {
            slug: "education"
          }
        }).then(this._getFirstObject.bind(this))
      });
    },
    _getFirstObject(list) {
      return list.firstObject;
    }
  });
  _exports.default = _default;
});