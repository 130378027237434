define("cheddargorge/templates/rocksport/attraction", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7gHAnnJL",
    "block": "[[[8,[39,0],null,[[\"@attraction\",\"@breadcrumbLabel\",\"@breadcrumbLink\"],[[30,0,[\"model\"]],\"Back to all Adventure Activities\",\"rocksport\"]],null],[1,\"\\n\"]],[],false,[\"attractions/attraction\"]]",
    "moduleName": "cheddargorge/templates/rocksport/attraction.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});