define("cheddargorge/components/remove-vouchercode-form/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'remove-vouchercode-form-wrapper',
    promotionLine: null,
    actions: {
      submit() {
        let promotionLine = this.promotionLine;
        const basket = (0, _object.get)(promotionLine, 'basket.content');
        return this.removePromotion(promotionLine).then(() => basket.reload());
      }
    }
  });
  _exports.default = _default;
});