define("cheddargorge/components/grid-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-card": "_grid-card_16713b",
    "is-featured": "_is-featured_16713b",
    "icons": "_icons_16713b",
    "icon": "_icon_16713b",
    "type": "_type_16713b",
    "title": "_title_16713b",
    "caption": "_caption_16713b",
    "address": "_address_16713b",
    "phone": "_phone_16713b",
    "price": "_price_16713b",
    "upsell": "_upsell_16713b",
    "cta": "_cta_16713b"
  };
  _exports.default = _default;
});