define("cheddargorge/routes/search/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    // beforeModel() {
    //   this.transitionTo("search.query", "");
    // }
  });
  _exports.default = _default;
});