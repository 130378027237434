define("cheddargorge/templates/escape-rooms/attraction", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "W6t8DI35",
    "block": "[[[8,[39,0],null,[[\"@attraction\",\"@breadcrumbLabel\",\"@breadcrumbLink\"],[[30,0,[\"model\"]],\"Back to all Escape Rooms\",\"escape-rooms\"]],null],[1,\"\\n\"]],[],false,[\"attractions/attraction\"]]",
    "moduleName": "cheddargorge/templates/escape-rooms/attraction.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});