define("cheddargorge/components/calendar-controls/component", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'calendar-controls-wrapper',
    canIncrementMonth: (0, _object.computed)('maxDate', 'displayDate', function () {
      let date = this.displayDate.clone().endOf('month');
      if (this.maxDate.isAfter(date)) {
        return null;
      } else {
        return true;
      }
    }),
    canDecrementMonth: (0, _object.computed)('minDate', 'displayDate', function () {
      if (this.minDate.isBefore(this.displayDate)) {
        return null;
      } else {
        return true;
      }
    }),
    actions: {
      previousMonth() {
        if ((0, _utils.isEmpty)(this.canDecrementMonth)) {
          this.changeDisplayDate(this.displayDate.clone().subtract(1, 'month'));
        }
      },
      nextMonth() {
        if ((0, _utils.isEmpty)(this.canIncrementMonth)) {
          this.changeDisplayDate(this.displayDate.clone().add(1, 'month'));
        }
      }
    }
  });
  _exports.default = _default;
});