define("cheddargorge/components/commerce/field/option-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button 
    local-class="option-button {{if @isSelected "is-selected"}}"
    type="button"
    {{on "click" (fn @onClick)}}
    ...attributes
  >
    {{#if @label}}
      {{@label}}
    {{else}}
      {{yield}}
    {{/if}}
  </button>
  */
  {
    "id": "Q9DIbs9D",
    "block": "[[[11,\"button\"],[16,0,[29,[[28,[37,0],[[28,[37,1],[\"option-button \",[52,[30,1],\"is-selected\"]],null]],[[\"from\"],[\"cheddargorge/components/commerce/field/option-button.scss\"]]]]]],[24,4,\"button\"],[17,2],[4,[38,3],[\"click\",[28,[37,4],[[30,3]],null]],null],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[1,[30,4]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,5,null],[1,\"\\n\"]],[]]],[13]],[\"@isSelected\",\"&attrs\",\"@onClick\",\"@label\",\"&default\"],false,[\"local-class\",\"concat\",\"if\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "cheddargorge/components/commerce/field/option-button.hbs",
    "isStrictMode": false
  });
  class CommerceFieldOptionButton extends _component2.default {}
  _exports.default = CommerceFieldOptionButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CommerceFieldOptionButton);
});