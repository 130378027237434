define("cheddargorge/components/picture-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dlDI01HI",
    "block": "[[[10,0],[15,0,[29,[\"picture-card--image-wrapper \",[28,[37,0],[\"image-wrapper\"],[[\"from\"],[\"cheddargorge/components/picture-card/styles\"]]]]]],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"    \"],[1,[28,[35,3],[[33,2]],null]],[1,\"  \\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[29,[\"picture-card--image \",[28,[37,0],[\"image\"],[[\"from\"],[\"cheddargorge/components/picture-card/styles\"]]]]]],[15,5,[36,4]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"picture-card--content \",[28,[37,0],[\"content\"],[[\"from\"],[\"cheddargorge/components/picture-card/styles\"]]]]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"if\",\"label\",\"ui-ribbon\",\"imageCSS\",\"yield\"]]",
    "moduleName": "cheddargorge/components/picture-card/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});