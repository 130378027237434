define("cheddargorge/templates/things-to-do/eating-and-drinking", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9fqVPrfq",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"image\"],[\"/images/heroes/cafe.jpg\"]]]],[1,\"\\n\\n\"],[6,[39,2],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Eating & drinking\"],[13],[1,\"\\n\\n    \"],[1,[34,4]],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      There’s so much to see and do at Cheddar Gorge and Caves, you’re definitely going to need to take a break, catch your breath and refuel.\\n    \"],[13],[1,\"\\n    \\n    \"],[1,[34,4]],[1,\"\\n\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"\\n      \"],[10,\"h4\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\\n      \"],[1,[28,[35,8],[[30,1,[\"description\"]]],null]],[1,\"\\n      \\n\"],[41,[33,10],[[[1,\"        \"],[6,[39,11],[\"Location\"],null,[[\"default\"],[[[[1,[34,10]]],[]]]]],[1,\"\\n\"]],[]],null],[41,[33,12],[[[1,\"        \"],[6,[39,11],[\"Seating\"],null,[[\"default\"],[[[[1,[34,12]]],[]]]]],[1,\"\\n\"]],[]],null],[41,[33,13],[[[6,[39,14],[\"Need to know\"],null,[[\"default\"],[[[[1,\"          \"],[18,2,[[33,16]]],[1,\"\\n\"]],[]]]]]],[]],null],[41,[30,1,[\"image\"]],[[[1,\"        \"],[10,\"img\"],[15,\"src\",[30,1,[\"image\"]]],[15,\"alt\",[29,[[30,1,[\"name\"]],\" at Cheddar Gorge\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"\\n    \"],[1,[34,17]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,18]]],[\"cateringOutlet\",\"&default\"],false,[\"find-it-fast\",\"hero-banner\",\"ui-background\",\"section-body\",\"ui-separator\",\"each\",\"-track-array\",\"model\",\"wysiwyg-field\",\"if\",\"location\",\"ui-label-value\",\"seating\",\"hasNeedToKnow\",\"reveal-content\",\"yield\",\"needToKnow\",\"share-with-friends\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/things-to-do/eating-and-drinking.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});