define("cheddargorge/components/mobiledoc-image-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XR0IWDDY",
    "block": "[[[10,\"img\"],[15,\"src\",[33,0,[\"originalUrl\"]]],[14,\"alt\",\"Picture\"],[12],[13]],[],false,[\"payload\"]]",
    "moduleName": "cheddargorge/components/mobiledoc-image-card/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});