define("cheddargorge/components/attractions/attraction/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "middle-actions": "_middle-actions_17p1ix",
    "actions": "_actions_17p1ix",
    "downloads": "_downloads_17p1ix",
    "sections": "_sections_17p1ix",
    "section-cta-link": "_section-cta-link_17p1ix",
    "ui-button": "_ui-button_17p1ix",
    "attraction": "_attraction_17p1ix",
    "description-section": "_description-section_17p1ix",
    "section": "_section_17p1ix"
  };
  _exports.default = _default;
});