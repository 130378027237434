define("cheddargorge/models/event", ["exports", "ember-goods/models/content-entry", "ember-data"], function (_exports, _contentEntry, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Event = (_dec = _emberData.default.attr('string'), _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.attr('string'), _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('string'), _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('string'), _dec9 = _emberData.default.attr('string'), _dec10 = _emberData.default.attr('string'), _dec11 = _emberData.default.attr('string'), _dec12 = _emberData.default.attr('string'), _dec13 = _emberData.default.attr('string'), _dec14 = _emberData.default.attr('string'), _dec15 = _emberData.default.attr('string'), _dec16 = _emberData.default.attr('string'), _dec17 = _emberData.default.attr('string'), _dec18 = _emberData.default.attr('string'), _dec19 = _emberData.default.attr('string'), _dec20 = _emberData.default.attr('string'), _dec21 = _emberData.default.attr('string'), _dec22 = _emberData.default.attr('string'), _dec23 = _emberData.default.attr('string'), _dec24 = _emberData.default.attr('string'), _dec25 = _emberData.default.attr('string'), _dec26 = _emberData.default.attr('string'), _dec27 = _emberData.default.attr('string'), _dec28 = _emberData.default.belongsTo('hero', {
    async: false
  }), (_class = class Event extends _contentEntry.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "slug", _descriptor2, this);
      _initializerDefineProperty(this, "heroBannerImage", _descriptor3, this);
      _initializerDefineProperty(this, "terms", _descriptor4, this);
      _initializerDefineProperty(this, "cardImage", _descriptor5, this);
      _initializerDefineProperty(this, "cardCaption", _descriptor6, this);
      _initializerDefineProperty(this, "ctaLink", _descriptor7, this);
      _initializerDefineProperty(this, "ctaLabel", _descriptor8, this);
      _initializerDefineProperty(this, "isExternalLink", _descriptor9, this);
      _initializerDefineProperty(this, "boolean", _descriptor10, this);
      _initializerDefineProperty(this, "location", _descriptor11, this);
      _initializerDefineProperty(this, "times", _descriptor12, this);
      _initializerDefineProperty(this, "price", _descriptor13, this);
      _initializerDefineProperty(this, "dates", _descriptor14, this);
      _initializerDefineProperty(this, "description", _descriptor15, this);
      _initializerDefineProperty(this, "needToKnow", _descriptor16, this);
      _initializerDefineProperty(this, "gridImage1", _descriptor17, this);
      _initializerDefineProperty(this, "gridImage2", _descriptor18, this);
      _initializerDefineProperty(this, "gridImage3", _descriptor19, this);
      _initializerDefineProperty(this, "gridImage4", _descriptor20, this);
      _initializerDefineProperty(this, "gridImage5", _descriptor21, this);
      _initializerDefineProperty(this, "gridImage6", _descriptor22, this);
      _initializerDefineProperty(this, "metaTitle", _descriptor23, this);
      _initializerDefineProperty(this, "order", _descriptor24, this);
      _initializerDefineProperty(this, "metaDescription", _descriptor25, this);
      _initializerDefineProperty(this, "purchaseCtaLabel", _descriptor26, this);
      _initializerDefineProperty(this, "purchaseCtaLink", _descriptor27, this);
      _initializerDefineProperty(this, "hero", _descriptor28, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "heroBannerImage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "terms", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cardImage", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cardCaption", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ctaLink", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ctaLabel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isExternalLink", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "boolean", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "times", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "price", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "dates", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "needToKnow", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "gridImage1", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "gridImage2", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "gridImage3", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "gridImage4", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "gridImage5", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "gridImage6", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "metaTitle", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "order", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "metaDescription", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "purchaseCtaLabel", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "purchaseCtaLink", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "hero", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // import DS from 'ember-data';
  // import ContentEntry from 'ember-goods/models/content-entry';
  // const { attr } = DS;
  // export default ContentEntry.extend({
  //   name: attr(),
  //   slug: attr(),
  //   heroBannerImage: attr(),
  //   cardImage: attr(),
  //   cardCaption: attr(),
  //   ctaLink: attr(),
  //   ctaLabel: attr(),
  //   isExternalLink: attr('boolean'),
  //   boolean: attr(),
  //   location: attr(),
  //   times: attr(),
  //   price: attr(),
  //   dates: attr(),
  //   description: attr(),
  //   needToKnow: attr(),
  //   gridImage1: attr(),
  //   gridImage2: attr(),
  //   gridImage3: attr(),
  //   gridImage4: attr(),
  //   gridImage5: attr(),
  //   gridImage6: attr(),
  //   metaTitle: attr(),
  //   order: attr(),
  //   metaDescription: attr(),
  //   purchaseCtaLabel: attr(),
  //   purchaseCtaLink: attr(),
  // });
  _exports.default = Event;
});