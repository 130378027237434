define("cheddargorge/routes/order/show/complete", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service", "cheddargorge/helpers/sku-title"], function (_exports, _route, _rsvp, _object, _service, _skuTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    goods: (0, _service.inject)('goods'),
    afterModel(order) {
      (0, _object.set)(this, 'headData.title', 'Thank you | Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
      return (0, _object.get)(order, 'orderLines').then(this._mapBy.bind(this, 'sku')).then(this._mapBy.bind(this, 'product'));
    },
    setupController(controller, order) {
      this._super(...arguments);
      let dataLayer = window['dataLayer'] || [];
      dataLayer.push(function () {
        //@ts-ignore
        this.reset();
      });
      let items = order.get('orderLines').map(orderLine => {
        let sku = orderLine.get('sku');
        let product = sku.get('product');
        let skuName = (0, _skuTitle.getSkuTitle)(this.goods, sku, sku.get('product.attrs.skuTitleTemplate'));
        return {
          item_name: product.get('name'),
          item_id: product.get('id'),
          item_brand: product.get('brand').get('name'),
          price: orderLine.get('price') / 100,
          item_variant: skuName,
          quantity: orderLine.get('quantity')
        };
      });

      //Push this purchase event
      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: order.get('id'),
          value: order.get('total') / 100,
          currency: 'GBP',
          items
        }
      });
    },
    _mapBy(key, list) {
      return _rsvp.default.all(list.mapBy(key));
    }
  });
  _exports.default = _default;
});