define("cheddargorge/components/password-access/component", ["exports", "@ember/component", "@ember/object", "@ember/utils", "ember-css-modules"], function (_exports, _component, _object, _utils, _emberCssModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PasswordAccess = (_dec = (0, _emberCssModules.localClassNames)('password-access'), _dec2 = (0, _object.computed)('product.productFields.[]'), _dec3 = (0, _object.computed)('password'), _dec(_class = (_class2 = class PasswordAccess extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "product", void 0);
      _defineProperty(this, "isAuthenticated", false);
      _defineProperty(this, "passwordInput", '');
      _defineProperty(this, "passwordError", '');
    }
    get password() {
      //@ts-ignore
      let field = (0, _object.get)(this, 'product.productFields')
      //@ts-ignore
      .toArray()
      //@ts-ignore
      .find(field => field.slug === 'password');
      return field ? field.values : undefined;
    }
    get hasPassword() {
      return (0, _utils.isPresent)(this.password);
    }
    onSubmit() {
      (0, _object.set)(this, 'passwordError', '');
      if (this.passwordInput === this.password) {
        (0, _object.set)(this, 'isAuthenticated', true);
      } else {
        (0, _object.set)(this, 'passwordError', 'This password was incorrect, please check and try again.');
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "password", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "password"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasPassword", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "hasPassword"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onSubmit"), _class2.prototype)), _class2)) || _class);
  _exports.default = PasswordAccess;
});