define("cheddargorge/components/ui-spinner/component", ["exports", "@ember/component", "@ember/object", "ember"], function (_exports, _component, _object, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    String
  } = _ember.default;
  var _default = _component.default.extend({
    classNames: 'component-ui-spinner',
    localClassNames: 'ui-spinner',
    attributeBindings: ['style'],
    tagName: 'span',
    width: '50px',
    height: '50px',
    borderWidth: '3px',
    color: '#fff',
    style: (0, _object.computed)('width', 'height', 'borderWidth', 'color', function () {
      const width = this.width;
      const height = this.height;
      const borderWidth = this.borderWidth;
      const color = this.color;
      return String.htmlSafe(`width: ${width}; height: ${height}; border: solid ${color}; border-width: ${borderWidth} 0 0 0;`);
    })
  });
  _exports.default = _default;
});