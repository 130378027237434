define("cheddargorge/components/calendar-month-day/component", ["exports", "@ember/component", "@ember/object", "@ember/utils", "moment"], function (_exports, _component, _object, _utils, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'calendar-month-day',
    localClassNameBindings: ['dayDateKey', 'isPreviousMonth', 'isNextMonth', 'isSelectable', 'isSelected', 'day.isDisabled'],
    classNames: 'calendar-month-day',
    classNameBindings: ['dayDateKey', 'isPreviousMonth', 'isNextMonth', 'isSelectable', 'isSelected', 'day.isDisabled'],
    dayDateKey: (0, _object.computed)('day.dateKey', function () {
      return (0, _object.get)(this, 'day.dateKey');
    }),
    isPreviousMonth: (0, _object.computed)('day.day', 'displayDate', function () {
      return _moment.default.utc((0, _object.get)(this, 'day.day')).isBefore(this.displayDate);
    }),
    isNextMonth: (0, _object.computed)('day.day', 'displayDate', function () {
      return _moment.default.utc((0, _object.get)(this, 'day.day')).isAfter(this.displayDate.clone().endOf('month'));
    }),
    isSelectable: (0, _object.computed)('day.isSelectable', 'isPreviousMonth', 'isNextMonth', function () {
      return (0, _object.get)(this, 'day.isSelectable') && this.isPreviousMonth === false && this.isNextMonth === false;
    }),
    isSelected: (0, _object.computed)('day.{date,day}', 'selectedDate', function () {
      return _moment.default.utc((0, _object.get)(this, 'day.day')).isSame(this.selectedDate, 'day');
    }),
    click() {
      if ((0, _utils.isEmpty)(this.selectDate)) {
        return;
      }
      if (this.isPreviousMonth || this.isNextMonth || this.isDisabled || this.isSelectable === false) {
        return;
      }
      this.selectDate(_moment.default.utc((0, _object.get)(this, 'day.day')));
    }
  });
  _exports.default = _default;
});