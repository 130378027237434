define("cheddargorge/components/sku-quantity-minus-plus/component", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/service", "@ember/object/computed"], function (_exports, _component, _utils, _object, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    goods: (0, _service.inject)('goods'),
    localClassNames: 'sku-quantity-minus-plus',
    defaultMax: 32,
    incrementAmount: 1,
    skuMinQuantity: 1,
    canIncrementForProduct: true,
    hasAge: (0, _computed.notEmpty)('fieldsHash.age'),
    hasQuantity: (0, _computed.gt)('quantity', 0),
    fields: (0, _computed.alias)('sku.skuFields'),
    firstSkuBasketItem: (0, _computed.alias)('skuBasketItems.firstObject'),
    skuBasketItemQuantities: (0, _computed.mapBy)('skuBasketItems', 'quantity'),
    skuBasketItemQuantity: (0, _computed.sum)('skuBasketItemQuantities'),
    cannotIncrement: (0, _computed.not)('canIncrement'),
    canDecrement: (0, _computed.gt)('skuBasketItemQuantity', 0),
    cannotDecrement: (0, _computed.not)('canDecrement'),
    hasProductMaxQuantity: (0, _computed.gt)('sku.product.maxQuantity', 0),
    bundleUUID: (0, _computed.alias)('firstSkuBasketItem.metadata.bundleUUID'),
    hasBundleUUID: (0, _computed.notEmpty)('bundleUUID'),
    skuBasketItems: (0, _computed.filter)('basketItems', function (basketItem) {
      return (0, _object.get)(basketItem, 'sku.id') === (0, _object.get)(this, 'sku.id');
    }),
    productMaxQuantity: (0, _object.computed)('hasProductMaxQuantity', 'sku.product.maxQuantity', 'defaultMax', function () {
      if (this.hasProductMaxQuantity) {
        return (0, _object.get)(this, 'sku.product.maxQuantity');
      }
      return this.defaultMax;
    }),
    fieldsHash: (0, _object.computed)('fields.[]', function () {
      const goods = this.goods;
      const fields = this.fields;
      return goods.fieldsToHash(fields);
    }),
    lineTotal: (0, _object.computed)('skuBasketItemQuantity', 'sku.price', 'bundledBasketItems.@each.price', function () {
      let totalPrice = this.bundledBasketItems.reduce((totalPrice, basketItem) => totalPrice + (0, _object.get)(basketItem, 'sku.price'), 0) + (0, _object.get)(this, 'sku.price');
      return this.skuBasketItemQuantity * totalPrice;
    }),
    canIncrement: (0, _object.computed)('available', 'canIncrementForProduct', 'incrementAmount', 'maxQuantity', 'skuMinQuantity', function () {
      let {
        canIncrementForProduct,
        available,
        incrementAmount
      } = (0, _object.getProperties)(this, 'canIncrementForProduct', 'available', 'maxQuantity', 'incrementAmount');
      const minQuantity = this.skuMinQuantity === undefined ? 1 : this.skuMinQuantity;
      const maxQuantity = this.maxQuantity;
      if (maxQuantity < minQuantity) {
        return false;
      }
      return canIncrementForProduct && incrementAmount <= available;
    }),
    available: (0, _object.computed)('maxQuantity', 'skuBasketItemQuantity', 'skuMinAvailable', function () {
      if (this.maxQuantity < this.skuMinAvailable) {
        return 0;
      }
      return this.maxQuantity - this.skuBasketItemQuantity;
    }),
    maxQuantity: (0, _object.computed)('defaultMax', 'fieldsHash', 'hasProductMaxQuantity', 'productMaxQuantity', 'sku.stockQuantity', function () {
      let fieldsHash = this.fieldsHash;
      let skuMaxQuantity = (0, _object.get)(fieldsHash, 'max-quantity');
      if (skuMaxQuantity > 0) {
        return Math.min(this.productMaxQuantity, (0, _object.get)(this, 'sku.stockQuantity'), skuMaxQuantity);
      } else {
        return Math.min(this.productMaxQuantity, (0, _object.get)(this, 'sku.stockQuantity'));
      }
    }),
    bundledBasketItems: (0, _object.computed)('basketItems.@each.metadata', 'hasBundleUUID', 'bundleUUID', 'firstSkuBasketItem.id', function () {
      const hasBundleUUID = this.hasBundleUUID;
      const bundleUUID = this.bundleUUID;
      const firstSkuBasketItem = this.firstSkuBasketItem;
      return this.basketItems.filter(basketItem => {
        if (basketItem == firstSkuBasketItem) {
          return false;
        }
        if (hasBundleUUID) {
          return bundleUUID === (0, _object.get)(basketItem, 'metadata.bundleUUID');
        }
      });
    }),
    actions: {
      increment() {
        const basketItems = this.basketItems;
        let basketItem = this.firstSkuBasketItem;
        let sku = this.sku;
        const minQuantity = this.skuMinQuantity === undefined ? 1 : this.skuMinQuantity;
        if (!this.canIncrement) {
          return;
        }
        if ((0, _utils.isEmpty)(basketItem)) {
          return this.createBasketItem(basketItems, sku, minQuantity);
        }
        basketItem.incrementProperty('quantity');
        this.bundledBasketItems.invoke('incrementProperty', 'quantity');
      },
      decrement() {
        var basketItem = this.firstSkuBasketItem;
        const quantity = basketItem.quantity;
        const minQuantity = this.skuMinQuantity;
        if ((0, _utils.isEmpty)(basketItem)) {
          return;
        }
        if (quantity - 1 < minQuantity) {
          (0, _object.set)(basketItem, 'quantity', 0);
        } else {
          basketItem.decrementProperty('quantity');
        }
        this.bundledBasketItems.invoke('decrementProperty', 'quantity');
        if (basketItem.quantity === 0) {
          return this.deleteBasketItem(this.basketItems, basketItem);
        }
      }
    }
  });
  _exports.default = _default;
});