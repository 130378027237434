define("cheddargorge/routes/things-to-do/eating-and-drinking", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    model() {
      return this.store.query('catering-and-retail', {
        filter: {
          slug: 'Catering'
        }
      });
    },
    afterModel() {
      (0, _object.set)(this, 'headData.title', 'Dog Friendly Cafe with Play Space Somerset - Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
    }
  });
  _exports.default = _default;
});