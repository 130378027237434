define("cheddargorge/services/session-user", ["exports", "@ember/utils", "@ember/service", "@ember/object"], function (_exports, _utils, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ember/use-ember-get-and-set: 0 */
  var _default = _service.default.extend({
    session: (0, _service.inject)(),
    goods: (0, _service.inject)(),
    basket: null,
    async initialize() {
      let basketId = (0, _object.get)(this.session, 'data.basketId');
      if ((0, _utils.isNone)(basketId)) {
        let basket = await this.goods.createBasket();
        this._setupBasket(basket);
      } else {
        let basket = await this.goods.getBasket(basketId);
        (0, _object.set)(this, 'basket', basket);
      }
    },
    async resetBasket() {
      let basket = await this.goods.createBasket();
      return this._setupBasket(basket);
    },
    _setupBasket(basket) {
      let basketId = basket.id;
      //@ts-ignore
      this.session.set('data.basketId', basketId);
      (0, _object.set)(this, 'basket', basket);
    }
  });
  _exports.default = _default;
});