define("cheddargorge/components/mobiledoc-table-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9qVwCeL5",
    "block": "[[[10,\"table\"],[15,0,[33,0,[\"cssClass\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"    \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,\"td\"],[12],[1,\"\\n          \"],[1,[30,2]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"values\",\"value\"],false,[\"payload\",\"each\",\"-track-array\",\"rows\"]]",
    "moduleName": "cheddargorge/components/mobiledoc-table-card/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});