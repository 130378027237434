define("cheddargorge/components/site-search-result-default/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result-default',
    record: (0, _computed.alias)('result.data'),
    cardImage: (0, _computed.alias)('record.cardImage.originalUrl'),
    heroBannerImage: (0, _computed.alias)('record.heroBannerImage.originalUrl'),
    image: (0, _object.computed)('cardImage.originalUrl', 'heroBannerImage.originalUrl', function () {
      if ((0, _utils.isPresent)(this.cardImage)) {
        return this.cardImage.originalUrl;
      }
      return this.heroBannerImage.originalUrl;
    }),
    typeSlug: (0, _computed.alias)('record.contentGroup.apiIdentifier'),
    hasMetaTitle: (0, _computed.notEmpty)('record.metaTitle'),
    route: (0, _object.computed)('typeSlug', function () {
      if (this.typeSlug === 'events') {
        return 'whats-on.index';
      }
      if (this.typeSlug === 'education-workshops') {
        return 'education.workshop';
      }
      if (this.typeSlug === 'education-resources') {
        return 'education.resources.show';
      }
      if (this.typeSlug === 'group-resources') {
        return 'groups.resource';
      }
      if (this.typeSlug === 'mumbo-jumbos') {
        return 'mumbo-jumbo.show';
      }
      if (this.typeSlug === 'visitor-infos') {
        return 'visitor-info.show';
      }
      return 'index';
    }),
    slug: (0, _computed.alias)('record.slug')
  });
  _exports.default = _default;
});