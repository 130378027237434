define("cheddargorge/models/education-workshop", ["exports", "ember-data", "ember-goods/models/content-entry"], function (_exports, _emberData, _contentEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;
  var _default = _contentEntry.default.extend({
    name: attr(),
    slug: attr(),
    heroBannerImage: attr(),
    cardImage: attr(),
    cardCaption: attr(),
    description: attr(),
    ctaLink: attr(),
    ctaLabel: attr(),
    stages: attr(),
    gridImage1: attr(),
    gridImage2: attr(),
    gridImage3: attr(),
    metaTitle: attr(),
    metaDescription: attr(),
    educationWorkshopType: belongsTo('education-workshop-type')
  });
  _exports.default = _default;
});