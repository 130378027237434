define("cheddargorge/components/hero-banner-large-caption/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HWu2OyPh",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"banner-caption\"],[[\"from\"],[\"cheddargorge/components/hero-banner-large-caption/styles\"]]]]]],[12],[1,\"\\n  \"],[10,\"h1\"],[15,0,[29,[[28,[37,0],[\"heading\"],[[\"from\"],[\"cheddargorge/components/hero-banner-large-caption/styles\"]]]]]],[12],[1,[30,0,[\"heading\"]]],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"subheading\"],[[\"from\"],[\"cheddargorge/components/hero-banner-large-caption/styles\"]]]]]],[12],[1,[30,0,[\"subheading\"]]],[13],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cheddargorge/components/hero-banner-large-caption/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});