define("cheddargorge/controllers/add-extras", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _controller, _computed, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    session: (0, _service.inject)("session-user"),
    product: (0, _computed.alias)("model"),
    promotionLines: (0, _computed.filter)("session.basket.basketItems", function (basketItem) {
      return (0, _utils.isPresent)((0, _object.get)(basketItem, "promotion.content"));
    })
  });
  _exports.default = _default;
});