define("cheddargorge/components/park-map/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wpvTZszq",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"park-map-inner\"],[[\"from\"],[\"cheddargorge/components/park-map/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"title\"],[[\"from\"],[\"cheddargorge/components/park-map/styles\"]]]]]],[12],[1,\"\\n    \"],[1,[34,1]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"img\"],[15,0,[29,[[28,[37,0],[\"map-image\"],[[\"from\"],[\"cheddargorge/components/park-map/styles\"]]]]]],[15,\"src\",[36,2]],[14,\"alt\",\"Map\"],[12],[13],[1,\"\\n\\n  \"],[8,[39,3],[[16,0,[29,[[28,[37,0],[\"download-btn\"],[[\"from\"],[\"cheddargorge/components/park-map/styles\"]]]]]],[24,\"target\",\"_blank\"],[24,\"rel\",\"nonreferrer noopener noreferrer\"]],[[\"@href\"],[[30,0,[\"downloadLink\"]]]],[[\"default\"],[[[[1,\"\\n    Download map PDF\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"title\",\"mapImage\",\"ui/link-button\",\"yield\"]]",
    "moduleName": "cheddargorge/components/park-map/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});