define("cheddargorge/templates/education/workshop", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hms6NdzC",
    "block": "[[[1,[28,[35,0],null,[[\"image\"],[[33,1,[\"workshop\",\"heroBannerImage\"]]]]]],[1,\"\\n\\n\"],[6,[39,2],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[1,[28,[35,4],null,[[\"label\",\"link\",\"linkModel\"],[[28,[37,5],[\"Back to \",[33,1,[\"workshop\",\"educationWorkshopType\",\"name\"]]],null],\"education.workshop-type\",[33,1,[\"workshop\",\"educationWorkshopType\",\"slug\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"template--sub-title\"],[12],[1,[33,1,[\"workshop\",\"educationWorkshopType\",\"name\"]]],[13],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,1,[\"workshop\",\"name\"]]],[13],[1,\"\\n    \"],[1,[28,[35,6],null,[[\"iconDirectory\",\"icons\"],[\"education\",[33,1,[\"workshop\",\"stages\"]]]]]],[1,\"\\n    \"],[1,[28,[35,7],[[33,1,[\"workshop\",\"description\"]]],null]],[1,\"\\n\\n    \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[33,1,[\"workshop\",\"ctaLink\"]],[[[1,\"        \"],[10,3],[15,6,[33,1,[\"workshop\",\"ctaLink\"]]],[14,0,\"ui-button-outline\"],[12],[1,[33,1,[\"workshop\",\"ctaLabel\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,3],[15,6,[33,1,[\"education\",\"brochure\"]]],[14,\"target\",\"_blank\"],[14,0,\"ui-button\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        Download Education Brochure\\n      \"],[13],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"],[41,[33,1,[\"workshop\",\"gridImage1\"]],[[[1,\"  \"],[1,[28,[35,10],null,[[\"large\",\"topSmall\",\"bottomSmall\"],[[33,1,[\"workshop\",\"gridImage1\"]],[33,1,[\"workshop\",\"gridImage2\"]],[33,1,[\"workshop\",\"gridImage3\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"hero-banner\",\"model\",\"ui-background\",\"section-body\",\"ui-breadcrumbs\",\"concat\",\"icon-list\",\"wysiwyg-field\",\"section-double-actions\",\"if\",\"picture-grid\"]]",
    "moduleName": "cheddargorge/templates/education/workshop.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});