define("cheddargorge/components/icons-affiliations/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icons-affiliations": "_icons-affiliations_8al4hc",
    "dog-friendly": "_dog-friendly_8al4hc",
    "abis": "_abis_8al4hc",
    "sta": "_sta_8al4hc",
    "visit-england": "_visit-england_8al4hc",
    "visit-somerset": "_visit-somerset_8al4hc",
    "scoc": "_scoc_8al4hc"
  };
  _exports.default = _default;
});