define("cheddargorge/components/template/product-list.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "card-title": "_card-title_k507o6",
    "card-text": "_card-text_k507o6",
    "cards": "_cards_k507o6",
    "card": "_card_k507o6",
    "card-cta": "_card-cta_k507o6"
  };
  _exports.default = _default;
});