define("cheddargorge/routes/products/tesco", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "rsvp"], function (_exports, _route, _object, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    model() {
      return (0, _rsvp.all)([this.store.query('product', {
        filter: {
          slug: 'tesco-gorge-and-caves-explorer-ticket'
        }
      }).then(this._getFirstObject.bind(this))]);
    },
    afterModel() {
      (0, _object.set)(this, 'headData.title', 'Tesco Tickets | Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
    },
    actions: {
      displayProduct(product) {
        this.transitionTo('product', product.slug);
      }
    },
    _getFirstObject(object) {
      return object.firstObject;
    }
  });
  _exports.default = _default;
});