define("cheddargorge/routes/explorer/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    model() {
      return this.store.query("attraction-area", {
        filter: {
          slug: "explorer"
        }
      }).then(this._getFirstObject.bind(this));
    },
    afterModel(attractionArea) {
      (0, _object.set)(this, "headData.title", attractionArea.metaTitle);
      (0, _object.set)(this, "headData.description", attractionArea.metaDescription);
    },
    _getFirstObject(objects) {
      return objects.firstObject;
    }
  });
  _exports.default = _default;
});