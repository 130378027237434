define("cheddargorge/components/icons-payment-cards/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sIO9D4Be",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"mastercard\"],[[\"from\"],[\"cheddargorge/components/icons-payment-cards/styles\"]]]]]],[12],[1,\"Mastercard\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"visa\"],[[\"from\"],[\"cheddargorge/components/icons-payment-cards/styles\"]]]]]],[12],[1,\"Visa\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"amex\"],[[\"from\"],[\"cheddargorge/components/icons-payment-cards/styles\"]]]]]],[12],[1,\"American Express\"],[13]],[],false,[\"local-class\"]]",
    "moduleName": "cheddargorge/components/icons-payment-cards/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});