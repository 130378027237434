define("cheddargorge/components/picture-card/component", ["exports", "@ember/string", "@ember/component", "@ember/object"], function (_exports, _string, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'picture-card',
    classNames: 'picture-card',
    backgroundColour: '#d9d2d0',
    backgroundSize: 'cover',
    imageCSS: (0, _object.computed)('backgroundColour', 'backgroundSize', 'cardImage', function () {
      return new _string.htmlSafe('background: ' + this.backgroundColour + " url('" + this.cardImage + "') no-repeat center center; background-size: " + this.backgroundSize + ';');
    })
  });
  _exports.default = _default;
});