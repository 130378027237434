define("cheddargorge/components/empty-basket-message/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iGaB+YvI",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"empty-basket-message\"],[[\"from\"],[\"cheddargorge/components/empty-basket-message/styles\"]]]]]],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"local-class\",\"box-raised\",\"yield\"]]",
    "moduleName": "cheddargorge/components/empty-basket-message/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});