define("cheddargorge/instance-initializers/app-version", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const addonConfig = _emberGetConfig.default.APP["app-version"];
  function initialize(appInstance) {
    if (addonConfig.autostart === true && addonConfig.isEnabled === true) {
      const {
        container = appInstance
      } = appInstance;
      const appVersion = container.lookup("service:app-version");
      appVersion.start();
    }
  }
  var _default = {
    name: "app-version",
    initialize
  };
  _exports.default = _default;
});