define("cheddargorge/components/site-search-result-product/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "site-search-result-product": "_site-search-result-product_15asmz",
    "result": "_result_15asmz",
    "result-title": "_result-title_15asmz"
  };
  _exports.default = _default;
});