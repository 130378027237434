define("cheddargorge/components/mobiledoc-table-card/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'mobiledoc-table-card',
    rows: (0, _object.computed)('payload.rows', function () {
      return JSON.parse((0, _object.get)(this, 'payload.rows'));
    })
  });
  _exports.default = _default;
});