define("cheddargorge/components/share-with-friends/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tliZqxDV",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"social-icons\"],[[\"from\"],[\"cheddargorge/components/share-with-friends/styles\"]]]]]],[12],[1,\"\\n  \"],[8,[39,1],[[16,0,[29,[[28,[37,0],[\"facebook\"],[[\"from\"],[\"cheddargorge/components/share-with-friends/styles\"]]]]]]],[[\"@tagName\",\"@url\"],[\"a\",[99,2,[\"@url\"]]]],[[\"default\"],[[[[1,\"\\n    Cheddar on Facebook\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,3],[[16,0,[29,[[28,[37,0],[\"twitter\"],[[\"from\"],[\"cheddargorge/components/share-with-friends/styles\"]]]]]]],[[\"@tagName\",\"@url\"],[\"a\",[99,2,[\"@url\"]]]],[[\"default\"],[[[[1,\"\\n    Cheddar on Twitter\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"local-class\",\"facebook-share\",\"url\",\"twitter-share\"]]",
    "moduleName": "cheddargorge/components/share-with-friends/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});