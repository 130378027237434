define("cheddargorge/models/order", ["exports", "ember-data", "ember-goods/models/order", "ember-cp-validations", "@ember/object"], function (_exports, _emberData, _order, _emberCpValidations, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      debounce: 250,
      description: "Name",
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
        min: 3
      })]
    },
    emailAddress: {
      debounce: 500,
      description: "Email",
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("format", {
        type: "email"
      })],
      disabled: _object.computed.not("model.shouldValidateEmail")
    },
    emailConfirmation: (0, _emberCpValidations.validator)("confirmation", {
      on: "emailAddress",
      message: "Email addresses do not match",
      debounce: 500
    }),
    phoneNumber: {
      debounce: 500,
      description: "Phone number",
      validators: [(0, _emberCpValidations.validator)("presence", true)]
    },
    billingAddress1: {
      debounce: 500,
      description: "Address",
      validators: [(0, _emberCpValidations.validator)("presence", true)]
    },
    billingCity: {
      debounce: 500,
      description: "City",
      validators: [(0, _emberCpValidations.validator)("presence", true)]
    },
    billingPostcode: {
      debounce: 500,
      description: "Post/Zip code",
      validators: [(0, _emberCpValidations.validator)("presence", true)]
    },
    billingRegion: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)("dependent", {
        on: ["billingCountry"]
      }), (0, _emberCpValidations.validator)("state-presence")]
    },
    billingCountry: {
      debounce: 500,
      description: "Country",
      validators: [(0, _emberCpValidations.validator)("presence", true)]
    },
    acceptedTerms: {
      debounce: 0,
      message: "Please accept the terms to continue",
      validators: [(0, _emberCpValidations.validator)("inclusion", {
        in: [true]
      })]
    }
  });

  //@ts-ignore
  let Order = (_dec = _emberData.default.attr("string"), _dec2 = _emberData.default.attr("string"), _dec3 = _emberData.default.attr("string"), _dec4 = _emberData.default.attr("string"), _dec5 = _emberData.default.attr("string"), _dec6 = _emberData.default.attr("string"), _dec7 = _emberData.default.attr("string"), _dec8 = _emberData.default.attr("string"), _dec9 = _emberData.default.attr("string"), _dec10 = _emberData.default.attr("string"), _dec11 = _emberData.default.attr(), _dec12 = _emberData.default.attr("string"), _dec13 = _emberData.default.attr("string"), _dec14 = _emberData.default.attr("boolean"), _dec15 = _emberData.default.attr("boolean"), _dec16 = _emberData.default.attr("boolean"), (_class = class Order extends _order.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uuid", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "emailAddress", _descriptor3, this);
      _initializerDefineProperty(this, "phoneNumber", _descriptor4, this);
      _initializerDefineProperty(this, "billingAddress1", _descriptor5, this);
      _initializerDefineProperty(this, "address2", _descriptor6, this);
      _initializerDefineProperty(this, "billingCity", _descriptor7, this);
      _initializerDefineProperty(this, "billingRegion", _descriptor8, this);
      _initializerDefineProperty(this, "billingPostcode", _descriptor9, this);
      _initializerDefineProperty(this, "billingCountry", _descriptor10, this);
      _initializerDefineProperty(this, "metadata", _descriptor11, this);
      _initializerDefineProperty(this, "insertedAt", _descriptor12, this);
      _initializerDefineProperty(this, "status", _descriptor13, this);
      _initializerDefineProperty(this, "marketingOptIn", _descriptor14, this);
      _initializerDefineProperty(this, "acceptedTerms", _descriptor15, this);
      _initializerDefineProperty(this, "acceptedCovid19Terms", _descriptor16, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "emailAddress", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "phoneNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "billingAddress1", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "address2", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "billingCity", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "billingRegion", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "billingPostcode", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "billingCountry", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "metadata", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "insertedAt", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "marketingOptIn", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "acceptedTerms", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "acceptedCovid19Terms", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Order;
});