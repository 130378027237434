define("cheddargorge/components/ui-form-select/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-form-select": "_ui-form-select_lblrtw"
  };
  _exports.default = _default;
});