define("cheddargorge/components/grid-cards/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-cards": "_grid-cards_ulvsvo",
    "grid-card": "_grid-card_ulvsvo"
  };
  _exports.default = _default;
});