define("cheddargorge/styles/buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-button": "_ui-button_eb8z68",
    "ui-button-outline": "_ui-button-outline_eb8z68",
    "ui-button-outline-inverse": "_ui-button-outline-inverse_eb8z68",
    "ui-button-jade": "_ui-button-jade_eb8z68"
  };
  _exports.default = _default;
});