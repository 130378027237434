define("cheddargorge/components/site-search-result-attraction/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result-attraction',
    attraction: (0, _computed.alias)('result.data'),
    attractionArea: (0, _computed.alias)('attraction.attractionArea'),
    image: (0, _computed.alias)('attraction.cardImage.originalUrl'),
    route: (0, _object.computed)('attractionArea.slug', function () {
      const attractionAreaSlug = this.attractionArea.get('slug');
      if (attractionAreaSlug === 'adventure-activities') {
        return 'rocksport.attraction';
      }
      return 'explorer.attraction';
    }),
    slug: (0, _computed.alias)('attraction.slug')
  });
  _exports.default = _default;
});