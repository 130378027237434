define("cheddargorge/models/price", ["exports", "ember-goods/models/price"], function (_exports, _price) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _price.default;
    }
  });
});