define("cheddargorge/components/site-search-result-organisation-department/component", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result-organisation-department',
    organisationDepartment: (0, _computed.alias)('result.data'),
    image: (0, _computed.alias)('organisationDepartment.heroBannerImage.originalUrl'),
    typeSlug: (0, _computed.alias)('organisationDepartment.contentGroup.apiIdentifier'),
    hasMetaTitle: (0, _computed.notEmpty)('organisationDepartment.attributes.metaTitle'),
    slug: (0, _computed.alias)('organisationDepartment.slug')
  });
  _exports.default = _default;
});