define("cheddargorge/components/attraction-social-actions/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "attraction-social-actions": "_attraction-social-actions_13hvuv"
  };
  _exports.default = _default;
});