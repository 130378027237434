define("cheddargorge/components/ui-spinner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2Faap3LC",
    "block": "[[],[],false,[]]",
    "moduleName": "cheddargorge/components/ui-spinner/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});