define("cheddargorge/components/global/mobile-nav/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "navbar": "_navbar_1x0w0o",
    "nav-wrapper": "_nav-wrapper_1x0w0o",
    "menu-btn": "_menu-btn_1x0w0o",
    "basket": "_basket_1x0w0o",
    "brand": "_brand_1x0w0o",
    "cheddar-logo": "_cheddar-logo_1x0w0o",
    "show-side-nav": "_show-side-nav_1x0w0o",
    "show-basket": "_show-basket_1x0w0o",
    "icon": "_icon_1x0w0o",
    "quantity": "_quantity_1x0w0o",
    "side-nav": "_side-nav_1x0w0o",
    "is-side-nav-visible": "_is-side-nav-visible_1x0w0o",
    "close-menu": "_close-menu_1x0w0o",
    "btn-close-menu": "_btn-close-menu_1x0w0o",
    "menu": "_menu_1x0w0o",
    "primary-links": "_primary-links_1x0w0o",
    "secondary-links": "_secondary-links_1x0w0o",
    "opening-times-link": "_opening-times-link_1x0w0o",
    "ticket-prices-link": "_ticket-prices-link_1x0w0o",
    "location-link": "_location-link_1x0w0o",
    "accommodation-link": "_accommodation-link_1x0w0o",
    "social-links": "_social-links_1x0w0o",
    "link-text": "_link-text_1x0w0o",
    "social-icons": "_social-icons_1x0w0o",
    "facebook": "_facebook_1x0w0o",
    "twitter": "_twitter_1x0w0o",
    "instagram": "_instagram_1x0w0o"
  };
  _exports.default = _default;
});