define("cheddargorge/components/opening-hours-today/component", ["exports", "@ember/service", "@ember/object/computed", "@ember/component"], function (_exports, _service, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'opening-hours-today',
    openingTimes: (0, _service.inject)('opening-times'),
    tagName: 'span',
    isOpenToday: (0, _computed.alias)('openingTimes.isOpenToday')
  });
  _exports.default = _default;
});