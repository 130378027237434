define("cheddargorge/components/apply-vouchercode-form/component", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _component, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'apply-vouchercode-container',
    code: '',
    errors: null,
    hasError: (0, _computed.notEmpty)('errors'),
    actions: {
      submit() {
        const code = this.code.trim();
        let errors = [];
        if ((0, _utils.isBlank)(code)) {
          errors.pushObject({
            title: 'IS_EMPTY'
          });
          (0, _object.set)(this, 'errors', errors);
          return;
        }
        this.applyPromotion(code);
      }
    }
  });
  _exports.default = _default;
});