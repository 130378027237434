define("cheddargorge/models/shop", ["exports", "ember-goods/models/shop"], function (_exports, _shop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _shop.default;
    }
  });
});