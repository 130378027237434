define("cheddargorge/components/product-card-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TmJCyhg6",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"      \"],[1,[30,1,[\"item\"]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,5]],null]],null],null,[[[1,\"      \"],[8,[30,2,[\"item\"]],null,[[\"@cardImage\",\"@product\",\"@ctaLinkLabel\",\"@label\"],[[30,3,[\"attributes\",\"cardHeroImage\"]],[30,3],\"Prices and booking\",[30,3,[\"attributes\",\"tagline\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"template--title\"],[12],[1,\"\\n          \"],[1,[30,3,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"template--caption\"],[12],[1,\"\\n          \"],[1,[30,3,[\"summary\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"\\n  \"]],[2]]]]],[1,\" \\n\"]],[]]]],[\"card\",\"card\",\"product\"],false,[\"if\",\"product-cards\",\"each\",\"-track-array\",\"productSlugs\",\"products\"]]",
    "moduleName": "cheddargorge/components/product-card-list/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});