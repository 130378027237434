define("cheddargorge/templates/accommodation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Yqba60+N",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"image\"],[[33,2,[\"accommodation\",\"heroBannerImage\"]]]]]],[1,\"\\n\\n\"],[6,[39,3],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,2,[\"education\",\"name\"]]],[13],[1,\"\\n    \"],[1,[28,[35,5],[[33,2,[\"accommodation\",\"description\"]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[6,[39,3],[\"amethyst\"],null,[[\"default\"],[[[[1,\"    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"accommodations\"]]],null]],null],null,[[[1,\"        \"],[8,[30,1,[\"item\"]],null,[[\"@cardImage\"],[[30,2,[\"cardImage\"]]]],[[\"default\"],[[[[1,\"        \\n          \"],[10,0],[14,0,\"template--title\"],[12],[1,\"\\n            \"],[1,[30,2,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"template--address\"],[12],[1,\"  \\n            \"],[1,[30,2,[\"address\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"template--phone\"],[12],[1,\"\\n            \"],[1,[30,2,[\"phone\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,3],[15,6,[29,[\"mailto:\",[30,2,[\"email\"]]]]],[12],[1,[30,2,[\"email\"]]],[13],[1,\"\\n          \"],[10,3],[15,6,[29,[\"http://\",[30,2,[\"url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[30,2,[\"url\"]]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,9]]],[\"card\",\"accommodationCard\"],false,[\"find-it-fast\",\"hero-banner-large\",\"model\",\"ui-background\",\"section-intro\",\"wysiwyg-field\",\"grid-cards-no-link\",\"each\",\"-track-array\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/accommodation.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});