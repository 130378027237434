define("cheddargorge/components/icons-social/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aZC2LDbw",
    "block": "[[[10,3],[15,0,[29,[[28,[37,0],[\"facebook\"],[[\"from\"],[\"cheddargorge/components/icons-social/styles\"]]]]]],[14,6,\"https://www.facebook.com/cheddargorgeandcaves\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n  Cheddar Gorge on Facebook\\n\"],[13],[1,\"\\n\"],[10,3],[15,0,[29,[[28,[37,0],[\"twitter\"],[[\"from\"],[\"cheddargorge/components/icons-social/styles\"]]]]]],[14,6,\"https://www.twitter.com/cheddargorge_\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n  Cheddar Gorge on Twitter\\n\"],[13],[1,\"\\n\"],[10,3],[15,0,[29,[[28,[37,0],[\"instagram\"],[[\"from\"],[\"cheddargorge/components/icons-social/styles\"]]]]]],[14,6,\"https://www.instagram.com/cheddargorgeandcaves\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n  Cheddar Gorge on Instagram\\n\"],[13],[1,\"\\n\"],[10,3],[15,0,[29,[[28,[37,0],[\"tiktok\"],[[\"from\"],[\"cheddargorge/components/icons-social/styles\"]]]]]],[14,6,\"https://www.tiktok.com/@cheddargorgeandcaves\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n  Cheddar Gorge on TikTok\\n\"],[13],[1,\"\\n\"]],[],false,[\"local-class\"]]",
    "moduleName": "cheddargorge/components/icons-social/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});