define("cheddargorge/components/event-properties/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XMrSp2cU",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"column\"],[[\"from\"],[\"cheddargorge/components/event-properties/styles\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"location\"]],[[[1,\"    \"],[6,[39,2],[\"Location\"],null,[[\"default\"],[[[[1,[30,0,[\"location\"]]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"times\"]],[[[1,\"    \"],[6,[39,2],[\"Times\"],null,[[\"default\"],[[[[1,[30,0,[\"times\"]]]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"column\"],[[\"from\"],[\"cheddargorge/components/event-properties/styles\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"price\"]],[[[1,\"    \"],[6,[39,2],[\"Prices\"],null,[[\"default\"],[[[[1,[30,0,[\"price\"]]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"dates\"]],[[[1,\"    \"],[6,[39,2],[\"Dates\"],null,[[\"default\"],[[[[1,[30,0,[\"dates\"]]]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"local-class\",\"if\",\"ui-label-value\"]]",
    "moduleName": "cheddargorge/components/event-properties/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});