define("cheddargorge/templates/products", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ygf9XtaB",
    "block": "[[[1,[28,[35,0],null,[[\"group\"],[\"Products\"]]]],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"broadcast-message\",\"component\",\"-outlet\"]]",
    "moduleName": "cheddargorge/templates/products.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});