define("cheddargorge/routes/accommodation", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service"], function (_exports, _route, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    queryParams: {
      filter: {
        refreshModel: true
      }
    },
    model() {
      let store = this.store;
      return _rsvp.default.hash({
        accommodations: store.findAll("accommodation"),
        accommodation: store.query("organisation-department", {
          filter: {
            slug: "accommodation"
          }
        }).then(this._getFirstObject.bind(this))
      });
    },
    afterModel(hash) {
      (0, _object.set)(this, "headData.title", hash.accommodation.metaTitle);
      (0, _object.set)(this, "headData.description", hash.accommodation.metaDescription);
    },
    _getFirstObject(object) {
      return object.firstObject;
    }
  });
  _exports.default = _default;
});