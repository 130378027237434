define("cheddargorge/routes/whats-on/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    model() {
      return this.store.findAll('event', {
        reload: true
      }).then(this._sortByOrder.bind(this));
    },
    afterModel() {
      (0, _object.set)(this, 'headData.title', "What's on Somerset - Events on Near Bristol at Cheddar Gorge");
      (0, _object.set)(this, 'headData.description', 'Come and have a look at what Cheddar Gorge and Caves have to offer this season');
    },
    _sortByOrder(model) {
      return model.sortBy('order');
    }
  });
  _exports.default = _default;
});