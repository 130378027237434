define("cheddargorge/components/ui/link-to", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@ember/utils", "@glimmer/component"], function (_exports, _component, _templateFactory, _service, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isExternal}}
    <a href={{@href}} target="_blank" rel="noopener noreferrer" ...attributes>
      {{yield}}
    </a>
  {{else if this.model}}
    <LinkTo @route={{this.route}} @model={{this.model}} ...attributes>
      {{yield}}
    </LinkTo>
  {{else}}
    <LinkTo @route={{this.route}} ...attributes>
      {{yield}}
    </LinkTo>
  {{/if}}
  */
  {
    "id": "5IphPIiP",
    "block": "[[[41,[30,0,[\"isExternal\"]],[[[1,\"  \"],[11,3],[16,6,[30,1]],[24,\"target\",\"_blank\"],[24,\"rel\",\"noopener noreferrer\"],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"model\"]],[[[1,\"  \"],[8,[39,2],[[17,2]],[[\"@route\",\"@model\"],[[30,0,[\"route\"]],[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],[[17,2]],[[\"@route\"],[[30,0,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]]],[\"@href\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"link-to\"]]",
    "moduleName": "cheddargorge/components/ui/link-to.hbs",
    "isStrictMode": false
  });
  let UiLinkButtonComponent = (_class = class UiLinkButtonComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get href() {
      if (this.isExternal) {
        return this.args.href;
      }
      if ((0, _utils.isNone)(this.args.href)) {
        return undefined;
      }
      if (this.args.href[0] != '/') {
        return `/${this.args.href}`;
      }
      return this.args.href;
    }
    get model() {
      if (this.isExternal) {
        return null;
      }
      if (!(0, _utils.isNone)(this.args.model)) {
        return this.args.model;
      }
      if ((0, _utils.isNone)(this.href)) {
        return null;
      }
      let routeInfo = this.router.recognize(this.href);
      if ((0, _utils.isEmpty)(routeInfo.params) || Object.keys(routeInfo.params).length == 0) {
        return null;
      }
      return routeInfo.params[routeInfo.paramNames[0]];
    }
    get route() {
      if (this.isExternal) {
        return null;
      }
      if (!(0, _utils.isNone)(this.args.route)) {
        return this.args.route;
      }
      if ((0, _utils.isNone)(this.href)) {
        return `index`;
      }
      let routeInfo = this.router.recognize(this.href);
      return routeInfo.name;
    }
    get urlParts() {
      if ((0, _utils.isNone)(this.href)) {
        return [];
      }

      //Remove protocol
      let href = this.href.replace(/(^\w+:|^)\/\//, '');

      //Remove leading and trailinng slash
      href = href.replace(/^\/|\/$/g, '');
      let parts = href.split('/');
      if (parts[0] == '') {
        return [];
      }
      return parts;
    }

    /**
     *
     */
    get isExternal() {
      if ((0, _utils.isNone)(this.args.href)) {
        return false;
      }
      let match = this.args.href.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
      if (match == null) {
        return false;
      }
      if (typeof match[1] === 'string' && match[1].length > 0 && match[1].toLowerCase() !== location.protocol) {
        return true;
      }
      if (typeof match[2] === 'string' && match[2].length > 0 && match[2].replace(new RegExp(':(' + {
        'http:': 80,
        'https:': 443
      }[location.protocol] + ')?$'), '') !== location.host) {
        return true;
      }
      return false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = UiLinkButtonComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiLinkButtonComponent);
});