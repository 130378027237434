define("cheddargorge/components/modal-smart/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal-smart": "_modal-smart_1278zc",
    "component-ui-message": "_component-ui-message_1278zc"
  };
  _exports.default = _default;
});