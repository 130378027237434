define("cheddargorge/components/broadcast-message/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "blPB3RVU",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,2],[\"broadcast\"],[[\"from\"],[\"cheddargorge/components/broadcast-message/styles\"]]]]]],[12],[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[[28,[37,2],[\"btn-close\"],[[\"from\"],[\"cheddargorge/components/broadcast-message/styles\"]]]]]],[24,4,\"button\"],[4,[38,3],[[30,0],\"close\"],null],[12],[1,\"x\"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,2],[\"title\"],[[\"from\"],[\"cheddargorge/components/broadcast-message/styles\"]]]]]],[12],[1,[33,4,[\"title\"]]],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,2],[\"body\"],[[\"from\"],[\"cheddargorge/components/broadcast-message/styles\"]]]]]],[12],[1,[28,[35,5],[[33,4,[\"body\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showBroadcast\",\"local-class\",\"action\",\"broadcast\",\"wysiwyg-field\"]]",
    "moduleName": "cheddargorge/components/broadcast-message/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});