define("cheddargorge/components/basket-items-summary/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "basket-items-summary": "_basket-items-summary_16n4ji",
    "warning-message": "_warning-message_16n4ji",
    "footer-actions": "_footer-actions_16n4ji",
    "voucher-code-btn": "_voucher-code-btn_16n4ji",
    "annual-passholder-btn": "_annual-passholder-btn_16n4ji",
    "need-to-know-btn": "_need-to-know-btn_16n4ji",
    "component-ui-button": "_component-ui-button_16n4ji",
    "component-ui-link-button": "_component-ui-link-button_16n4ji"
  };
  _exports.default = _default;
});