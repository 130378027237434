define("cheddargorge/components/basket/warning-message/has-only-child-tickets", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="Warning"
    >
      <p><strong>You only have Child Tickets in your basket.</strong></p>
      <p>
        Please remember that any children visiting Cheddar Gorge & Caves must be accompanied by a responsible adult at all times.
      </p>
    </Basket::WarningMessage>
  {{/if}}
  
  */
  {
    "id": "mNvF1SNI",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"Warning\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[10,\"strong\"],[12],[1,\"You only have Child Tickets in your basket.\"],[13],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Please remember that any children visiting Cheddar Gorge & Caves must be accompanied by a responsible adult at all times.\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/has-only-child-tickets.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageHasOnlyChildTickets extends _component2.default {
    /**
     * Is the warning message visible?
     */
    get isVisible() {
      return (0, _utils.isPresent)(this.args.childBasketItems) && (0, _utils.isEmpty)(this.args.adultBasketItems) && (0, _utils.isEmpty)(this.args.carerBasketItems);
    }
  }
  _exports.default = BasketWarningMessageHasOnlyChildTickets;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageHasOnlyChildTickets);
});