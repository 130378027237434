define("cheddargorge/components/password-access/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VgJnXJOA",
    "block": "[[[41,[28,[37,1],[[30,0,[\"hasPassword\"]],[28,[37,2],[[30,0,[\"isAuthenticated\"]]],null]],null],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,3],[\"password-wrapper\"],[[\"from\"],[\"cheddargorge/components/password-access/styles\"]]]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,3],[\"password-inner\"],[[\"from\"],[\"cheddargorge/components/password-access/styles\"]]]]]],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,\"\\n        Please enter the password to enter this page\\n      \"],[13],[1,\"\\n      \"],[10,0],[15,0,[29,[[28,[37,3],[\"password-input-section\"],[[\"from\"],[\"cheddargorge/components/password-access/styles\"]]]]]],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"type\",\"id\",\"value\",\"class\"],[\"password\",\"password-input\",[28,[37,5],[[30,0,[\"passwordInput\"]]],null],[28,[37,6],[[28,[37,3],[\"textfield-input\"],[[\"from\"],[\"cheddargorge/components/password-access/styles\"]]]],null]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"passwordError\"]],[[[1,\"          \"],[10,0],[15,0,[29,[[28,[37,3],[\"password-error\"],[[\"from\"],[\"cheddargorge/components/password-access/styles\"]]]]]],[12],[1,\"\\n            \"],[1,[30,0,[\"passwordError\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[1,[28,[35,7],null,[[\"onclick\",\"label\"],[[28,[37,8],[[30,0],\"onSubmit\"],null],\"Submit\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"and\",\"not\",\"local-class\",\"input\",\"mut\",\"concat\",\"ui-button\",\"action\",\"yield\"]]",
    "moduleName": "cheddargorge/components/password-access/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});