define("cheddargorge/components/picture-gallery/component", ["exports", "@ember/object/computed", "@ember/component", "@ember/utils", "@ember/object"], function (_exports, _computed, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    didReceiveAttrs() {
      this._super();
      this.setActiveItem(this.get('items.firstObject'));
    },
    classNames: ['component-picture-gallery'],
    localClassNames: ['picture-gallery'],
    items: (0, _object.computed)('images.[]', function () {
      let previous = null,
        item = null;
      return this.images.map(function (image) {
        item = {
          image: image,
          isActive: false,
          previous: previous,
          next: null
        };
        if (previous !== null) {
          previous.next = item;
        }
        previous = item;
        return item;
      });
    }),
    firstItem: (0, _computed.alias)('items.firstObject'),
    lastItem: (0, _computed.alias)('items.lastObject'),
    actions: {
      setActive(item) {
        this.setActiveItem(item);
      },
      previous() {
        if ((0, _utils.isEmpty)((0, _object.get)(this, 'activeItem.previous'))) {
          return this.setActiveItem(this.lastItem);
        }
        this.setActiveItem((0, _object.get)(this, 'activeItem.previous'));
      },
      next() {
        if ((0, _utils.isEmpty)((0, _object.get)(this, 'activeItem.next'))) {
          return this.setActiveItem(this.firstItem);
        }
        this.setActiveItem((0, _object.get)(this, 'activeItem.next'));
      }
    },
    setActiveItem(item) {
      if ((0, _utils.isEmpty)(item)) {
        return false;
      }
      if (!(0, _utils.isEmpty)(this.activeItem)) {
        (0, _object.set)(this, 'activeItem.isActive', false);
      }
      (0, _object.set)(this, 'activeItem', item);
      (0, _object.set)(this, 'activeItem.isActive', true);
    }
  });
  _exports.default = _default;
});