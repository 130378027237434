define("cheddargorge/components/ui-background/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6lovA00/",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,2],[\"background-top\"],[[\"from\"],[\"cheddargorge/components/ui-background/styles\"]]]]]],[12],[13],[1,\"\\n\"]],[]],null],[10,0],[15,0,[29,[[28,[37,2],[\"background-inner\"],[[\"from\"],[\"cheddargorge/components/ui-background/styles\"]]]]]],[12],[18,1,null],[13],[1,\"\\n\"],[41,[33,4],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,2],[\"background-bottom\"],[[\"from\"],[\"cheddargorge/components/ui-background/styles\"]]]]]],[12],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"hasBackgroundTop\",\"local-class\",\"yield\",\"hasBackgroundBottom\"]]",
    "moduleName": "cheddargorge/components/ui-background/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});