define("cheddargorge/models/basket-item", ["exports", "ember-data", "ember-goods/models/basket-item"], function (_exports, _emberData, _basketItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // //@ts-ignore

  const {
    attr
  } = _emberData.default;
  var _default = _basketItem.default.extend({
    insertedAt: attr('date')
  });
  _exports.default = _default;
});