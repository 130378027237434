define("cheddargorge/templates/promo/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NzqyNXko",
    "block": "[[[8,[39,0],null,[[\"@slug\"],[[30,1,[\"slug\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"template/partnerships\"]]",
    "moduleName": "cheddargorge/templates/promo/show.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});