define("cheddargorge/components/find-it-fast/component", ["exports", "@ember/service", "@ember/object/computed", "@ember/component"], function (_exports, _service, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'find-it-fast',
    openingTimesService: (0, _service.inject)('opening-times'),
    isOpenToday: (0, _computed.alias)('openingTimesService.isOpenToday'),
    todaysOpeningTime: (0, _computed.alias)('openingTimesService.todaysOpeningTime'),
    todaysClosingTime: (0, _computed.alias)('openingTimesService.todaysClosingTime')
  });
  _exports.default = _default;
});