define("cheddargorge/components/ui/link-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "link-button": "_link-button_r8zexa"
  };
  _exports.default = _default;
});