define("cheddargorge/components/button-text/component", ["exports", "@ember/component", "@ember/utils"], function (_exports, _component, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: 'button-text',
    localClassNames: 'button-text',
    tagName: 'button',
    actions: {
      onclick() {
        const onclick = this.onclick;
        if ((0, _utils.isEmpty)(onclick)) {
          return;
        }
        onclick();
      }
    },
    click() {
      this.send('onclick');
      return false;
    }
  });
  _exports.default = _default;
});