define("cheddargorge/components/icons-payment-cards/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icons-payment-cards": "_icons-payment-cards_1qsss4",
    "mastercard": "_mastercard_1qsss4",
    "visa": "_visa_1qsss4",
    "amex": "_amex_1qsss4",
    "paypal": "_paypal_1qsss4",
    "is-tiny": "_is-tiny_1qsss4"
  };
  _exports.default = _default;
});