define("cheddargorge/controllers/visitor-info/opening-times", ["exports", "@ember/controller", "@ember/object", "moment", "@ember/object/computed"], function (_exports, _controller, _object, _moment, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    openingTimes: (0, _computed.alias)('model'),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'displayDate', _moment.default.utc().startOf('month'));
    }
  });
  _exports.default = _default;
});