define("cheddargorge/components/section-homepage-intro/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "section-homepage-intro": "_section-homepage-intro_k7wekd"
  };
  _exports.default = _default;
});