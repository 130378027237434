define("cheddargorge/templates/products/list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "u9rt0Y0U",
    "block": "[[[8,[39,0],null,[[\"@productListData\",\"@productsData\"],[[30,0,[\"model\",\"productListData\"]],[30,0,[\"model\",\"productsData\"]]]],null]],[],false,[\"template/product-list\"]]",
    "moduleName": "cheddargorge/templates/products/list.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});