define("cheddargorge/models/broadcast", ["exports", "ember-data", "ember-goods/models/content-entry"], function (_exports, _emberData, _contentEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;
  var _default = _contentEntry.default.extend({
    name: attr("string"),
    body: attr("string"),
    groups: attr(),
    isClosed: attr("boolean", {
      default: false
    })
  });
  _exports.default = _default;
});