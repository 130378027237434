define("cheddargorge/components/picture-grid/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VIff6DN4",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"large-image\"],[[\"from\"],[\"cheddargorge/components/picture-grid/styles\"]]]]]],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,0,[\"large\"]]],[14,\"alt\",\"Large image\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"small-image-1\"],[[\"from\"],[\"cheddargorge/components/picture-grid/styles\"]]]]]],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,0,[\"topSmall\"]]],[14,\"alt\",\"Small image\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"small-image-2\"],[[\"from\"],[\"cheddargorge/components/picture-grid/styles\"]]]]]],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,0,[\"bottomSmall\"]]],[14,\"alt\",\"Small image\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"local-class\"]]",
    "moduleName": "cheddargorge/components/picture-grid/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});