define("cheddargorge/components/ui-form-minus-plus-field/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CFq3G4i3",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"quantity\"],[[\"from\"],[\"cheddargorge/components/ui-form-minus-plus-field/styles\"]]]]]],[12],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[[28,[37,0],[\"decrement\"],[[\"from\"],[\"cheddargorge/components/ui-form-minus-plus-field/styles\"]]]]]],[16,\"disabled\",[36,1]],[24,4,\"button\"],[4,[38,2],[[30,0],\"decrement\"],null],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[[28,[37,0],[\"minus\"],[[\"from\"],[\"cheddargorge/components/ui-form-minus-plus-field/styles\"]]]]]],[12],[1,\"-\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[33,4],[[[1,\"    \"],[1,[28,[35,5],null,[[\"value\",\"key-up\",\"class\"],[[28,[37,6],[[33,7]],null],[28,[37,2],[[30,0],\"setQuantity\"],null],[28,[37,8],[[28,[37,0],[\"quantity-input\"],[[\"from\"],[\"cheddargorge/components/ui-form-minus-plus-field/styles\"]]]],null]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,0],[\"quantity-value\"],[[\"from\"],[\"cheddargorge/components/ui-form-minus-plus-field/styles\"]]]]]],[12],[1,\"\\n      \"],[1,[34,7]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[[28,[37,0],[\"increment\"],[[\"from\"],[\"cheddargorge/components/ui-form-minus-plus-field/styles\"]]]]]],[16,\"disabled\",[36,9]],[24,4,\"button\"],[4,[38,2],[[30,0],\"increment\"],null],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[[28,[37,0],[\"plus\"],[[\"from\"],[\"cheddargorge/components/ui-form-minus-plus-field/styles\"]]]]]],[12],[1,\"+\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"local-class\",\"cannotDecrement\",\"action\",\"if\",\"isDirectlyEditable\",\"input\",\"mut\",\"quantity\",\"concat\",\"cannotIncrement\"]]",
    "moduleName": "cheddargorge/components/ui-form-minus-plus-field/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});