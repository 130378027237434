define("cheddargorge/components/basket/warning-message/multiple-booking-dates", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="Warning"
    >
      <p><strong>You have tickets in your basket for more than one date.</strong></p>
      <p>
        Please check the dates for the tickets in your basket below and only continue to payment if they are correct. If they are incorrect please remove them and add your tickets again with the correct dates.
      </p>
    </Basket::WarningMessage>
  {{/if}}
  */
  {
    "id": "TqkzkL8M",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"Warning\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[10,\"strong\"],[12],[1,\"You have tickets in your basket for more than one date.\"],[13],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Please check the dates for the tickets in your basket below and only continue to payment if they are correct. If they are incorrect please remove them and add your tickets again with the correct dates.\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/multiple-booking-dates.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageMultipleBookingDates extends _component2.default {
    /**
     * Is the warning message visible?
     */
    get isVisible() {
      let dates = this.args.basketItems.mapBy('sku.content.attributes.bookable-date.firstObject').compact().uniq();
      return dates.get('length') > 1;
    }
  }
  _exports.default = BasketWarningMessageMultipleBookingDates;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageMultipleBookingDates);
});