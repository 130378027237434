define("cheddargorge/components/social-widget", ["exports", "ember-social/components/social-widget"], function (_exports, _socialWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _socialWidget.default;
    }
  });
});