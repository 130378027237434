define("cheddargorge/components/product-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "n7zR0wnp",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"product\",[30,1,[\"slug\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@cardImage\",\"@label\"],[[99,3,[\"@cardImage\"]],[99,4,[\"@label\"]]]],[[\"default\"],[[[[1,\"\\n\\n      \"],[18,3,null],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"        \"],[10,0],[15,0,[29,[[28,[37,6],[\"cta\"],[[\"from\"],[\"cheddargorge/components/product-card/styles\"]]]]]],[12],[1,\"\\n          \"],[8,[39,7],null,[[\"@route\",\"@model\"],[\"product\",[30,1,[\"slug\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@product\",\"@ctaLinkLabel\",\"&default\"],false,[\"if\",\"ui/link-to\",\"picture-card\",\"cardImage\",\"label\",\"yield\",\"local-class\",\"ui/link-button\"]]",
    "moduleName": "cheddargorge/components/product-card/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});