define("cheddargorge/components/ui-form-checkbox/component", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/object/computed"], function (_exports, _component, _object, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'ui-form-checkbox',
    localClassNameBindings: ['hasError', 'isValid'],
    notValidating: (0, _computed.not)('validations.isValidating'),
    didValidate: (0, _computed.oneWay)('target.didValidate'),
    hasContent: (0, _computed.notEmpty)('isChecked'),
    isValid: (0, _computed.and)('hasContent', 'validations.isValid', 'notValidating'),
    isInvalid: (0, _computed.oneWay)('validations.isInvalid'),
    showErrorClass: (0, _computed.and)('notValidating', 'showMessage', 'hasContent', 'validations'),
    hasError: (0, _computed.alias)('showMessage'),
    showMessage: (0, _object.computed)('validations.isDirty', 'isInvalid', 'didValidate', function () {
      return ((0, _object.get)(this, 'validations.isDirty') || this.didValidate) && this.isInvalid;
    }),
    actions: {
      toggleValue() {
        this.toggleProperty('isChecked');
        if ((0, _utils.isPresent)(this.setValue)) {
          this.setValue(this.isChecked);
        }
      }
    }
  });
  _exports.default = _default;
});