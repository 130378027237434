define("cheddargorge/components/picture-grid/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'picture-grid',
    localClassNameBindings: ['attrs.isReversed']
  });
  _exports.default = _default;
});