define("cheddargorge/components/mobiledoc-image-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "mobiledoc-image-card": "_mobiledoc-image-card_j0v37h"
  };
  _exports.default = _default;
});