define("cheddargorge/components/order-summary/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'order-summary',
    payments: (0, _computed.filterBy)('source.payments', 'isNew', false),
    hasPayments: (0, _computed.notEmpty)('payments'),
    hasPromotionLines: (0, _computed.notEmpty)('promotionLines'),
    totalBeforeDiscount: (0, _object.computed)('source.{total,discount}', function () {
      return (0, _object.get)(this, 'source.total') - (0, _object.get)(this, 'source.discount');
    })
  });
  _exports.default = _default;
});