define("cheddargorge/components/calendar-month/component", ["exports", "@ember/component", "@ember/object", "moment"], function (_exports, _component, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'calendar-month',
    daysOfWeek: (0, _object.computed)(function () {
      return [(0, _moment.default)().weekday(1), (0, _moment.default)().weekday(2), (0, _moment.default)().weekday(3), (0, _moment.default)().weekday(4), (0, _moment.default)().weekday(5), (0, _moment.default)().weekday(6), (0, _moment.default)().weekday(0)];
    })
  });
  _exports.default = _default;
});