define("cheddargorge/components/commerce/field/gift-information", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "cheddargorge/constants/basket-item-metadata-fields"], function (_exports, _component, _templateFactory, _component2, _service, _basketItemMetadataFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @gifts key="@index" as |gift index|}}
  
    <div local-class="gift">
      <div local-class="gift-heading">
        {{this.skuName}}
      </div>
      {{#each-in this.metadataFields as |key field|}}
  
        {{#if (has-key gift key)}}
      
          {{#if (eq field.type "short-text")}}
            <UiFormInsetTextfield
              @label={{field.label}}
              @value={{get gift key}}
            />
          {{/if}}
  
          {{#if (eq field.type "select")}}
            <UiFormSelect
              @options={{field.options}}
              @selected={{get gift key}}
              @placeholder={{field.label}}
              @onchange={{fn (mut (get gift key))}}
            as |option|>
              {{option}}
            </UiFormSelect>
          {{/if}}
  
        {{/if}}
      {{/each-in}}
  
    </div>
  {{/each}}
  
  */
  {
    "id": "s4IgZfoJ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],\"@index\",[[[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,2],[\"gift\"],[[\"from\"],[\"cheddargorge/components/commerce/field/gift-information.scss\"]]]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,2],[\"gift-heading\"],[[\"from\"],[\"cheddargorge/components/commerce/field/gift-information.scss\"]]]]]],[12],[1,\"\\n      \"],[1,[30,0,[\"skuName\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,3],[[30,0,[\"metadataFields\"]]],null],null,[[[1,\"\\n\"],[41,[28,[37,5],[[30,2],[30,5]],null],[[[1,\"    \\n\"],[41,[28,[37,6],[[30,4,[\"type\"]],\"short-text\"],null],[[[1,\"          \"],[8,[39,7],null,[[\"@label\",\"@value\"],[[30,4,[\"label\"]],[28,[37,8],[[30,2],[30,5]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,6],[[30,4,[\"type\"]],\"select\"],null],[[[1,\"          \"],[8,[39,9],null,[[\"@options\",\"@selected\",\"@placeholder\",\"@onchange\"],[[30,4,[\"options\"]],[28,[37,8],[[30,2],[30,5]],null],[30,4,[\"label\"]],[28,[37,10],[[28,[37,11],[[28,[37,8],[[30,2],[30,5]],null]],null]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,6]],[1,\"\\n          \"]],[6]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]],null]],[4,5]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2,3]],null]],[\"@gifts\",\"gift\",\"index\",\"field\",\"key\",\"option\"],false,[\"each\",\"-track-array\",\"local-class\",\"-each-in\",\"if\",\"has-key\",\"eq\",\"ui-form-inset-textfield\",\"get\",\"ui-form-select\",\"fn\",\"mut\"]]",
    "moduleName": "cheddargorge/components/commerce/field/gift-information.hbs",
    "isStrictMode": false
  });
  let CommerceFieldGiftInformation = (_class = class CommerceFieldGiftInformation extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "tickets", _descriptor, this);
    }
    get metadataFields() {
      return _basketItemMetadataFields.CHRISTMAS_GIFT_FIELDS;
    }
    get skuName() {
      let productAttributes = this.args.sku.get('product').get('attrs');
      let skuAttributes = this.args.sku.get('attrs');
      return this.tickets.skuName(skuAttributes, productAttributes.skuTitleTemplate);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tickets", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = CommerceFieldGiftInformation;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CommerceFieldGiftInformation);
});