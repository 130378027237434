define("cheddargorge/models/bom-sku", ["exports", "ember-goods/models/bom-sku"], function (_exports, _bomSku) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _bomSku.default;
    }
  });
});