define("cheddargorge/components/broadcast-message/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "broadcast-message": "_broadcast-message_1qvop2",
    "broadcast": "_broadcast_1qvop2",
    "title": "_title_1qvop2",
    "body": "_body_1qvop2",
    "btn-close": "_btn-close_1qvop2"
  };
  _exports.default = _default;
});