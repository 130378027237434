define("cheddargorge/components/global/menu/component", ["exports", "@glimmer/component", "@ember/utils", "@ember/object"], function (_exports, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let GlobalMenu = (_class = class GlobalMenu extends _component.default {
    /**
     *
     */
    get appearance() {
      return this.args.appearance ?? 'default';
    }

    /**
     * Groups the links so that highlighted boundaries can be managed by the CSS
     */
    get groups() {
      let groups = [];
      let links = this.args.menu.get('links').toArray();
      this.args.menu.get('attrs').links.map(linkId => links.findBy('id', linkId.toString())).compact().forEach(link => {
        let group = groups[groups.length - 1];
        if ((0, _utils.isNone)(group) || group.isClosed) {
          groups.pushObject({
            isPriority: link.get('isHighlighted'),
            isPrimary: link.get('isPrimary'),
            isClosed: false,
            links: [link]
          });
        } else {
          if (link.get('isSeparator')) {
            group.isClosed = true;
          } else if (link.get('isHighlighted') == group.isPriority && group.isPrimary == false) {
            group.links.pushObject(link);
          } else {
            groups.pushObject({
              isPriority: link.get('isHighlighted'),
              isClosed: false,
              isPrimary: false,
              links: [link]
            });
          }
        }
      });
      return groups;
    }

    /**
     * Calls the passed onClickLink handler if it exists
     */
    onClickLink() {
      if (this.args.onClickLink) {
        this.args.onClickLink();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onClickLink", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickLink"), _class.prototype)), _class);
  _exports.default = GlobalMenu;
});