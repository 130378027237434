define("cheddargorge/components/box-raised-two-columns/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5Wcfu/2k",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "cheddargorge/components/box-raised-two-columns/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});