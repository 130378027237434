define("cheddargorge/components/reveal-content/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "p3A45v40",
    "block": "[[[11,\"button\"],[16,0,[29,[\"reveal-content--btn \",[28,[37,0],[\"reveal-button\"],[[\"from\"],[\"cheddargorge/components/reveal-content/styles\"]]]]]],[24,4,\"button\"],[4,[38,1],[[30,0],\"toggleReveal\"],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"reveal-content--title\"],[12],[1,\"\\n    \"],[1,[34,2]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[10,1],[15,0,[29,[\"reveal-content--caret \",[28,[37,0],[\"reveal-button-caret\"],[[\"from\"],[\"cheddargorge/components/reveal-content/styles\"]]]]]],[12],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"reveal-content\"],[[\"from\"],[\"cheddargorge/components/reveal-content/styles\"]]]]]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"action\",\"title\",\"if\",\"showCaret\",\"yield\"]]",
    "moduleName": "cheddargorge/components/reveal-content/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});