define("cheddargorge/utils/load-order", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadOrder = loadOrder;
  _exports.reloadOrder = reloadOrder;
  async function reloadOrder(store, order) {
    return loadOrder(store, order.get("uuid"));
  }
  async function loadOrder(store, uuid) {
    let orders = await store.query("order", {
      filter: {
        uuid: uuid
      }
    });
    let order = orders.get("firstObject");
    if ((0, _utils.isNone)(order)) {
      throw new Error("order_not_found");
    }
    return order;
  }
});