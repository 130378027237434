define("cheddargorge/controllers/checkout", ["exports", "@ember/controller", "@ember/utils", "@ember/object", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _utils, _object, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    session: (0, _service.inject)("session-user"),
    selectedCountry: {
      id: "GB",
      value: "United Kingdom"
    },
    selectedState: null,
    isSubmitting: false,
    countries: null,
    states: null,
    order: (0, _computed.alias)("model"),
    basket: (0, _computed.alias)("session.basket"),
    hasBalance: (0, _computed.gt)("basket.total", 0),
    isEmailDisabled: false,
    promotionLines: (0, _computed.filter)("basket.basketItems", function (basketItem) {
      return (0, _utils.isPresent)((0, _object.get)(basketItem, "promotion.content"));
    }),
    paymentBtnLabel: (0, _object.computed)("hasBalance", function () {
      if (this.hasBalance) {
        return "Continue to payment";
      }
      return "Complete order";
    }),
    actions: {
      changedState(state) {
        (0, _object.setProperties)(this, {
          selectedState: state,
          "order.billingRegion": state.code
        });
      },
      changedCountry(country) {
        (0, _object.setProperties)(this, {
          selectedCountry: country,
          "order.billingCountry": country.alpha2
        });
      }
    }
  });
  _exports.default = _default;
});