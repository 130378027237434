define("cheddargorge/components/template/partnerships/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hero": "_hero_1i70oo",
    "welcome-section": "_welcome-section_1i70oo",
    "partners": "_partners_1i70oo",
    "partner": "_partner_1i70oo",
    "image": "_image_1i70oo",
    "partner-details": "_partner-details_1i70oo",
    "partner-name": "_partner-name_1i70oo",
    "about": "_about_1i70oo",
    "discount-code-text": "_discount-code-text_1i70oo",
    "instructions": "_instructions_1i70oo",
    "terms-and-conditions": "_terms-and-conditions_1i70oo"
  };
  _exports.default = _default;
});