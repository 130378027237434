define("cheddargorge/routes/application", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "@ember/object"], function (_exports, _route, _rsvp, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Application = (_class = class Application extends _route.default {
    beforeModel() {
      //    this.get('appVersionCheck').start();
      return (0, _object.get)(this, 'sessionUser').initialize();
    }
    afterModel() {
      return _rsvp.default.hash({
        dateKeys: (0, _object.get)(this, 'store').findAll('dateKey')
      }).then(this._setDateKeysFromHash.bind(this));
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "openingTimes", _descriptor2, this);
      _initializerDefineProperty(this, "sessionUser", _descriptor3, this);
      _initializerDefineProperty(this, "metrics", _descriptor4, this);
      let router = this.router;
      router.on('routeDidChange', () => {
        this.trackPage(router);
      });
    }
    trackPage(router) {
      const page = router.currentURL;
      const title = router.currentRouteName || 'unknown';
      this.metrics.trackPage({
        page,
        title
      });
    }
    error(error) {
      if ((0, _object.get)(error, 'status') === 404) {
        this.transitionTo('error404', 'page-not-found');
      } else {
        return true;
      }
      return;
    }
    _setDateKeysFromHash(hash) {
      this.openingTimes.set('dateKeys', hash.dateKeys);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "openingTimes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class);
  _exports.default = Application;
});