define("cheddargorge/components/commerce/field/visitors", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "@ember/service", "tracked-built-ins"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _service, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isLoading}}
  {{else}}
    <div local-class="visitors-selector layout-{{this.layout}}">
  
      <div local-class="visitors">
        {{#each this.visitors as |visitor|}}
          <Commerce::Field::Visitor
            local-class="visitor"
            @visitor={{visitor}}
            @productQuantity={{this.totalQuantity}}
            @productQuantityMax={{this.productQuantityMax}}
            @onIncrement={{fn this.onIncrement visitor}}
            @onDecrement={{fn this.onDecrement visitor}}
          />
        {{/each}}
      </div>
      <Ui::Button
        local-class="continue-btn"
        @label="continue"
        @size={{if (eq this.layout "default") "large"}}
        @isDisabled={{not this.canContinue}}
        @onClick={{this.onContinue}}
      />
  
    </div>
  {{/if}}
  */
  {
    "id": "WMGx7Sf/",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[],[]],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,1],[[28,[37,2],[\"visitors-selector layout-\",[30,0,[\"layout\"]]],null]],[[\"from\"],[\"cheddargorge/components/commerce/field/visitors.scss\"]]]]]],[12],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[[28,[37,1],[\"visitors\"],[[\"from\"],[\"cheddargorge/components/commerce/field/visitors.scss\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"visitors\"]]],null]],null],null,[[[1,\"        \"],[8,[39,5],[[16,0,[29,[[28,[37,1],[\"visitor\"],[[\"from\"],[\"cheddargorge/components/commerce/field/visitors.scss\"]]]]]]],[[\"@visitor\",\"@productQuantity\",\"@productQuantityMax\",\"@onIncrement\",\"@onDecrement\"],[[30,1],[30,0,[\"totalQuantity\"]],[30,0,[\"productQuantityMax\"]],[28,[37,6],[[30,0,[\"onIncrement\"]],[30,1]],null],[28,[37,6],[[30,0,[\"onDecrement\"]],[30,1]],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[8,[39,7],[[16,0,[29,[[28,[37,1],[\"continue-btn\"],[[\"from\"],[\"cheddargorge/components/commerce/field/visitors.scss\"]]]]]]],[[\"@label\",\"@size\",\"@isDisabled\",\"@onClick\"],[\"continue\",[52,[28,[37,8],[[30,0,[\"layout\"]],\"default\"],null],\"large\"],[28,[37,9],[[30,0,[\"canContinue\"]]],null],[30,0,[\"onContinue\"]]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"visitor\"],false,[\"if\",\"local-class\",\"concat\",\"each\",\"-track-array\",\"commerce/field/visitor\",\"fn\",\"ui/button\",\"eq\",\"not\"]]",
    "moduleName": "cheddargorge/components/commerce/field/visitors.hbs",
    "isStrictMode": false
  });
  let CommerceFieldVisitors = (_class = class CommerceFieldVisitors extends _component2.default {
    /**
     *
     */

    /**
     *
     */

    /**
     *
     */

    /**
     *
     */
    get isLoading() {
      return this.args.productData.isLoading || this.args.masterVariantsData.isLoading;
    }

    /**
     *
     */
    get product() {
      return this.args.productData.value;
    }

    /**
     *
     */
    get masterVariants() {
      return this.args.masterVariantsData.value;
    }

    /**
     *
     */
    get productAttributes() {
      return this.product.get('attributes');
    }

    /**
     * The maximum quantity of the products that can be added to the basket
     */
    get productQuantityMax() {
      if ((0, _utils.isEmpty)(this.productAttributes.maxQuantity)) {
        // return DEFAULT_PRODUCT_MAX_QUANTITY;
        return 32;
      }
      return this.productAttributes.maxQuantity;
    }

    /**
     * The minimum quantity of the products that can be added to the basket
     */
    get productQuantityMin() {
      if ((0, _utils.isEmpty)(this.productAttributes.minQuantity)) {
        return 0;
      }
      return this.productAttributes.minQuantity;
    }

    /**
     * Default type of layout
     */
    get layout() {
      return this.args.layout ?? 'default';
    }

    /**
     *
     */
    async buildVisitors() {
      let visitors = await this.tickets.getVisitors(this.args.productData, this.args.masterVariantsData, this.args.selectedVisitors);
      this.visitors.pushObjects(visitors);
      return;
    }

    /**
     * Total quantity selected
     */
    get totalQuantity() {
      return this.visitors.reduce((total, visitor) => total + (0, _object.get)(visitor, 'quantity'), 0);
    }

    /**
     * Total quantity selected
     */
    get totalPaidQuantity() {
      return this.visitors.reduce((total, visitor) => {
        if (visitor.price == 0) {
          return total;
        }
        return total + (0, _object.get)(visitor, 'quantity');
      }, 0);
    }

    /**
     * Is the form valid?
     */
    get canContinue() {
      return this.totalPaidQuantity >= this.productQuantityMin && this.totalQuantity <= this.productQuantityMax;
    }

    /**
     *
     */
    onContinue() {
      let selected = JSON.parse(JSON.stringify(this.visitors));
      selected = selected.filter(visitor => visitor.quantity > 0);
      this.args.onContinue(selected);
    }

    /**
     *
     * @param visitor
     */
    onIncrement(visitor) {
      (0, _object.set)(visitor, 'quantity', visitor.quantity + 1);
    }

    /**
     *
     * @param visitor
     */
    onDecrement(visitor) {
      (0, _object.set)(visitor, 'quantity', visitor.quantity - 1);
    }

    /**
     *
     * @param owner
     * @param args
     */
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "skuGroups", _descriptor, this);
      _initializerDefineProperty(this, "tickets", _descriptor2, this);
      _defineProperty(this, "visitors", new _trackedBuiltIns.TrackedArray());
      this.buildVisitors();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "skuGroups", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tickets", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onContinue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onContinue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onIncrement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onIncrement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDecrement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDecrement"), _class.prototype)), _class);
  _exports.default = CommerceFieldVisitors;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CommerceFieldVisitors);
});