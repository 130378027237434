define("cheddargorge/components/jumbo-select/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "jumbo-select": "_jumbo-select_yv3v5o",
    "jumbo-button": "_jumbo-button_yv3v5o",
    "popup": "_popup_yv3v5o",
    "label": "_label_yv3v5o",
    "value": "_value_yv3v5o",
    "is-disabled": "_is-disabled_yv3v5o",
    "caret": "_caret_yv3v5o",
    "is-showing-popup": "_is-showing-popup_yv3v5o",
    "icon-calendar": "_icon-calendar_yv3v5o",
    "icon-people": "_icon-people_yv3v5o"
  };
  _exports.default = _default;
});