define("cheddargorge/components/site-search-result-event/component", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result-event',
    event: (0, _computed.alias)('result.data'),
    image: (0, _computed.alias)('event.cardImage.originalUrl'),
    typeSlug: (0, _computed.alias)('event.contentGoup.apiIdentifier'),
    hasMetaTitle: (0, _computed.notEmpty)('event.metaTitle')
  });
  _exports.default = _default;
});