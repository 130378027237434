define("cheddargorge/components/site-search-result/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Wc9SNOxG",
    "block": "[[[46,[30,0,[\"resultComponent\"]],null,[[\"result\"],[[30,0,[\"result\"]]]],null]],[],false,[\"component\"]]",
    "moduleName": "cheddargorge/components/site-search-result/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});