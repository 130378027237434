define("cheddargorge/templates/rocksport/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JrIR5zvu",
    "block": "[[[1,[28,[35,0],null,[[\"image\"],[[33,1,[\"heroBannerImage\"]]]]]],[1,\"\\n\\n\"],[6,[39,2],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,1,[\"rocksport\",\"name\"]]],[13],[1,\"\\n    \"],[1,[28,[35,4],[[33,1,[\"description\"]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[33,8]],null]],null],null,[[[6,[39,9],null,[[\"route\",\"model\"],[\"rocksport.attraction\",[30,2,[\"slug\"]]]],[[\"default\"],[[[[1,\"        \"],[8,[30,1,[\"item\"]],null,[[\"@cardImage\"],[[30,2,[\"cardImage\"]]]],[[\"default\"],[[[[1,\"        \\n          \"],[10,0],[14,0,\"template--title\"],[12],[1,\"\\n            \"],[1,[30,2,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"template--caption\"],[12],[1,\"\\n            \"],[1,[30,2,[\"cardCaption\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[2]],null],[1,\"  \"]],[1]]]]],[1,\"\\n\\n  \"],[8,[39,10],null,null,[[\"default\"],[[[[1,\"\\n    For more information or to book please call 01934 742343\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]]],[\"card\",\"attraction\"],false,[\"hero-banner\",\"model\",\"ui-background\",\"section-intro\",\"wysiwyg-field\",\"grid-cards\",\"each\",\"-track-array\",\"sortedAttractions\",\"link-to\",\"section-double-actions\"]]",
    "moduleName": "cheddargorge/templates/rocksport/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});