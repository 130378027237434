define("cheddargorge/components/ui-background/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-background": "_ui-background_m83w55",
    "very-light-grain": "_very-light-grain_m83w55",
    "quartz-with-top": "_quartz-with-top_m83w55",
    "background-top": "_background-top_m83w55",
    "background-inner": "_background-inner_m83w55",
    "quartz-with-bottom": "_quartz-with-bottom_m83w55",
    "background-bottom": "_background-bottom_m83w55",
    "quartz": "_quartz_m83w55",
    "amethyst": "_amethyst_m83w55",
    "white": "_white_m83w55",
    "azurite": "_azurite_m83w55",
    "black-squeeze": "_black-squeeze_m83w55",
    "amber": "_amber_m83w55"
  };
  _exports.default = _default;
});