define("cheddargorge/templates/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wmNINy9S",
    "block": "[[[8,[39,0],null,[[\"@data\"],[[28,[37,1],null,[[\"bannersData\",\"instagramImagesData\"],[[30,1,[\"bannersData\"]],[30,1,[\"instagramImagesData\"]]]]]]],null]],[\"@model\"],false,[\"template/home\",\"hash\"]]",
    "moduleName": "cheddargorge/templates/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});