define("cheddargorge/components/find-it-fast/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "find-it-fast": "_find-it-fast_1cqo35",
    "link": "_link_1cqo35",
    "opening-times": "_opening-times_1cqo35",
    "ticket-prices": "_ticket-prices_1cqo35",
    "location": "_location_1cqo35",
    "accommodation": "_accommodation_1cqo35",
    "social-links": "_social-links_1cqo35",
    "icon": "_icon_1cqo35",
    "teaser": "_teaser_1cqo35",
    "highlight": "_highlight_1cqo35",
    "facebook": "_facebook_1cqo35",
    "twitter": "_twitter_1cqo35",
    "instagram": "_instagram_1cqo35",
    "tiktok": "_tiktok_1cqo35"
  };
  _exports.default = _default;
});