define("cheddargorge/components/icon-list/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9SlNZGn3",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,2],[\"icons\"],[[\"from\"],[\"cheddargorge/components/icon-list/styles\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,1]],null]],null],null,[[[1,\"      \"],[10,\"img\"],[15,\"src\",[28,[37,5],[\"/images/icons/\",[30,1],\".png\"],null]],[15,\"alt\",[30,1]],[12],[13],[1,\"  \\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"icon\"],false,[\"if\",\"sortedIcons\",\"local-class\",\"each\",\"-track-array\",\"concat\"]]",
    "moduleName": "cheddargorge/components/icon-list/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});