define("cheddargorge/components/ui-background/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let component = _component.default.extend({
    localClassNames: 'ui-background',
    localClassNameBindings: ['type'],
    hasBackgroundTop: (0, _object.computed)('type', function () {
      return this.getAttr('type') === 'quartz-with-top';
    }),
    hasBackgroundBottom: (0, _object.computed)('type', function () {
      return this.getAttr('type') === 'quartz-with-bottom';
    })
  });
  component.reopenClass({
    positionalParams: ['type']
  });
  var _default = component;
  _exports.default = _default;
});