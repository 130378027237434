define("cheddargorge/routes/mumbo-jumbo/show", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    storeHelper: (0, _service.inject)("store-helper"),
    model(params) {
      const storeHelper = this.storeHelper;
      return storeHelper.queryRecord("mumbo-jumbo", {
        slug: params.slug
      }, true);
    },
    afterModel(mumboJumbo) {
      (0, _object.set)(this, "headData.title", mumboJumbo.metaTitle);
      (0, _object.set)(this, "headData.description", mumboJumbo.metaDescription);
    },
    serialize: function (event) {
      return {
        slug: event.slug
      };
    },
    _getFirstObject(list) {
      return list.firstObject;
    }
  });
  _exports.default = _default;
});