define("cheddargorge/components/site-search/component", ["exports", "@ember/component", "ember-concurrency", "@ember/object", "@ember/service", "rsvp", "@ember/object/computed"], function (_exports, _component, _emberConcurrency, _object, _service, _rsvp, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search',
    query: '',
    index: '',
    algolia: (0, _service.inject)(),
    isQueryTooShort: (0, _computed.lt)('query.length', 3),
    init() {
      this._super(...arguments);
      this.results = [];
    },
    didRender() {
      this._super(...arguments);
      this.element.querySelector('#site-search-input').focus();
    },
    search: (0, _emberConcurrency.task)(function* (query) {
      const algolia = this.algolia;
      const index = this.index;
      if (query.length < 3) {
        return (0, _object.set)(this, 'results', []);
      }
      yield (0, _emberConcurrency.timeout)(100);
      let results = yield new _rsvp.Promise(function (resolve, reject) {
        algolia.search({
          indexName: index,
          query: query
        }, function searchDone(err, content) {
          if (err) {
            reject(err);
          }
          resolve(content);
        });
      });
      return (0, _object.set)(this, 'results', results.hits);
    }).restartable(),
    didReceiveAttrs() {
      this._super();
      let query = this.query;
      this.search.perform(query);
    }
  });
  _exports.default = _default;
});