define("cheddargorge/components/ui-form-checkbox/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0F/JivCA",
    "block": "[[[1,[28,[35,0],null,[[\"id\",\"type\",\"name\",\"checked\",\"change\",\"class\"],[[33,1],\"checkbox\",[33,2],[28,[37,3],[[33,4]],null],[28,[37,5],[[30,0],\"toggleValue\"],null],[28,[37,6],[[28,[37,7],[\"checkbox-input\"],[[\"from\"],[\"cheddargorge/components/ui-form-checkbox/styles\"]]]],null]]]]],[1,\"\\n\"],[10,\"label\"],[15,0,[29,[[28,[37,7],[\"checkbox-label\"],[[\"from\"],[\"cheddargorge/components/ui-form-checkbox/styles\"]]]]]],[15,\"for\",[36,1]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[33,10],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,7],[\"error\"],[[\"from\"],[\"cheddargorge/components/ui-form-checkbox/styles\"]]]]]],[12],[1,\"\\n    \"],[1,[33,11,[\"message\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[\"&default\"],false,[\"input\",\"fieldId\",\"fieldName\",\"mut\",\"isChecked\",\"action\",\"concat\",\"local-class\",\"yield\",\"if\",\"showMessage\",\"validations\"]]",
    "moduleName": "cheddargorge/components/ui-form-checkbox/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});