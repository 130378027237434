define("cheddargorge/components/order-summary/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "heading": "_heading_ob8yg9",
    "total-item-count": "_total-item-count_ob8yg9",
    "total-price": "_total-price_ob8yg9",
    "payment": "_payment_ob8yg9",
    "unpaid-balance": "_unpaid-balance_ob8yg9",
    "label": "_label_ob8yg9",
    "value": "_value_ob8yg9",
    "payments": "_payments_ob8yg9",
    "footer-actions": "_footer-actions_ob8yg9",
    "saving": "_saving_ob8yg9",
    "amount": "_amount_ob8yg9",
    "order-summary": "_order-summary_ob8yg9",
    "back-to-basket-btn": "_back-to-basket-btn_ob8yg9"
  };
  _exports.default = _default;
});