define("cheddargorge/models/mumbo-jumbo", ["exports", "ember-data", "ember-goods/models/content-entry"], function (_exports, _emberData, _contentEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;
  var _default = _contentEntry.default.extend({
    slug: attr(),
    name: attr(),
    heroBannerImage: attr(),
    description: attr(),
    metaTitle: attr(),
    metaDescription: attr()
  });
  _exports.default = _default;
});