define("cheddargorge/templates/product", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MCT5EOB8",
    "block": "[[[8,[39,0],null,[[\"@productData\",\"@priceListSkusData\"],[[30,0,[\"model\",\"productData\"]],[30,0,[\"model\",\"priceListSkusData\"]]]],null]],[],false,[\"template/product\"]]",
    "moduleName": "cheddargorge/templates/product.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});