define("cheddargorge/components/basket/warning-message/junior-rock-climbing-only-has-child", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="Please note"
    >
      <p>
        Children under the age of 16 must be supervised by an adult at all times while participating in the rock climbing activity - One adult may supervise up to 9 children
      </p>
    </Basket::WarningMessage>
  {{/if}}
  */
  {
    "id": "6ODFJWoi",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"Please note\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Children under the age of 16 must be supervised by an adult at all times while participating in the rock climbing activity - One adult may supervise up to 9 children\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/junior-rock-climbing-only-has-child.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageJuniorRockClimbingOnlyHasChild extends _component2.default {
    /**
     * Is the warning message visible? Shows if there are Junior Rock Climbing
     * tickets for children in the basket and none for adults
     */
    get isVisible() {
      let productBasketItems = this.args.basketItems.filterBy('sku.content.product.slug', 'rocksport-junior-rock-climbing');
      let adults = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Adult');
      let children = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Child');
      return (0, _utils.isPresent)(children) && (0, _utils.isEmpty)(adults);
    }
  }
  _exports.default = BasketWarningMessageJuniorRockClimbingOnlyHasChild;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageJuniorRockClimbingOnlyHasChild);
});