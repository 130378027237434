define("cheddargorge/components/site-search-result/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result',
    typeSlug: (0, _computed.alias)('record.content_group.api_identifier'),
    resultComponent: (0, _object.computed)('result.type', function () {
      const typeSlug = (0, _object.get)(this.result, 'data.contentGroup.apiIdentifier');
      if (typeSlug === 'attractions') {
        return 'site-search-result-attraction';
      }
      if (typeSlug === 'events') {
        return 'site-search-result-event';
      }
      if (typeSlug === 'partners') {
        return 'site-search-result-partner';
      }
      if (typeSlug === 'organisation-departments') {
        return 'site-search-result-organisation-department';
      }
      if (typeSlug === 'attraction-areas') {
        return 'site-search-result-attraction-area';
      }
      if (typeSlug === 'catering-and-retails') {
        return 'site-search-result-catering-retail';
      }
      if (this.result.type == 'product') {
        return 'site-search-result-product';
      }
      return 'site-search-result-default';
    })
  });
  _exports.default = _default;
});