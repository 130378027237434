define("cheddargorge/components/checkout-progress-bar/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "checkout-progress-bar": "_checkout-progress-bar_rx3e2q",
    "steps": "_steps_rx3e2q",
    "step": "_step_rx3e2q",
    "is-delivery": "_is-delivery_rx3e2q",
    "is-active": "_is-active_rx3e2q",
    "is-done": "_is-done_rx3e2q",
    "count": "_count_rx3e2q",
    "label": "_label_rx3e2q"
  };
  _exports.default = _default;
});