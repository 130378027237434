define("cheddargorge/components/picture-gallery/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "picture-gallery": "_picture-gallery_14gtrg",
    "gallery-image": "_gallery-image_14gtrg",
    "gallery-video": "_gallery-video_14gtrg",
    "gallery-navigation": "_gallery-navigation_14gtrg",
    "nav-item": "_nav-item_14gtrg",
    "is-active": "_is-active_14gtrg",
    "thumb": "_thumb_14gtrg",
    "previous": "_previous_14gtrg",
    "next": "_next_14gtrg"
  };
  _exports.default = _default;
});