define("cheddargorge/routes/things-to-do/shopping-and-souvenirs", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service"], function (_exports, _route, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    model() {
      return _rsvp.default.all([this.store.query("catering-and-retail", {
        filter: {
          slug: "shop-at-the-gorge"
        }
      }).then(this._getFirstObject.bind(this)), this.store.query("catering-and-retail", {
        filter: {
          slug: "caveman-shop"
        }
      }).then(this._getFirstObject.bind(this))]);
    },
    afterModel() {
      (0, _object.set)(this, "headData.title", "Shopping & Souvenirs | Cheddar Gorge and Caves");
      (0, _object.set)(this, "headData.description", "");
    },
    _getFirstObject(object) {
      return object.firstObject;
    }
  });
  _exports.default = _default;
});