define("cheddargorge/components/event-properties/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "event-properties": "_event-properties_16pwii",
    "column": "_column_16pwii"
  };
  _exports.default = _default;
});