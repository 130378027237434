define("cheddargorge/components/grid-card/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'grid-card',
    classNames: 'grid-card',
    sortedIcons: (0, _object.computed)('icons', function () {
      return this.icons.sort();
    })
  });
  _exports.default = _default;
});