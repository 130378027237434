define("cheddargorge/components/ui/hero/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-hero": "_ui-hero_1gdkwc",
    "hero": "_hero_1gdkwc",
    "has-caption": "_has-caption_1gdkwc",
    "image": "_image_1gdkwc",
    "video": "_video_1gdkwc",
    "has-mobile-image": "_has-mobile-image_1gdkwc",
    "desktop-image": "_desktop-image_1gdkwc",
    "mobile-image": "_mobile-image_1gdkwc",
    "caption": "_caption_1gdkwc",
    "layout": "_layout_1gdkwc",
    "title": "_title_1gdkwc",
    "subtitle": "_subtitle_1gdkwc",
    "actions": "_actions_1gdkwc"
  };
  _exports.default = _default;
});