define("cheddargorge/components/ui-label-value/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-label-value": "_ui-label-value_1npy85",
    "label": "_label_1npy85",
    "value": "_value_1npy85"
  };
  _exports.default = _default;
});