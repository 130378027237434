define("cheddargorge/components/product-layout/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'product-layout',
    breadcrumbs: {
      isBreadcrumbs: true
    },
    carousel: {
      isCarousel: true
    },
    form: {
      isForm: true
    },
    details: {
      isDetails: true
    },
    needToKnow: {
      isNeedToKnow: true
    },
    terms: {
      isTerms: true
    }
  });
  _exports.default = _default;
});