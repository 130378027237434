define("cheddargorge/components/ui-button/component", ["exports", "@ember/component", "@ember/utils", "@ember/object/computed", "@ember/service"], function (_exports, _component, _utils, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    metrics: (0, _service.inject)('metrics'),
    tagName: 'button',
    classNames: 'ui-button',
    localClassNames: 'ui-button',
    localClassNameBindings: ['isLoading:is-loading', 'size', 'themeClass'],
    attributeBindings: ['disabled'],
    trackEvent: null,
    disabled: (0, _computed.or)('isDisabled', 'isLoading'),
    isSubmitButton: (0, _computed.equal)('type', 'submit'),
    isNotSubmitButton: (0, _computed.not)('isSubmitButton'),
    actions: {
      onclick() {
        const trackEvent = this.trackEvent;
        const onclick = this.onclick;
        const metrics = this.metrics;
        if ((0, _utils.isPresent)(trackEvent)) {
          metrics.trackEvent({
            event: 'custom-event',
            category: trackEvent.category,
            action: trackEvent.action,
            label: trackEvent.label,
            value: trackEvent.value
          });
        }
        if ((0, _utils.isEmpty)(onclick)) {
          return;
        }
        onclick();
      }
    },
    click() {
      this.send('onclick');
      return false;
    }
  });
  _exports.default = _default;
});