define("cheddargorge/templates/order/show/payment/challenge-complete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "x0+GUDkD",
    "block": "[[[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "cheddargorge/templates/order/show/payment/challenge-complete.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});