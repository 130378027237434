define("cheddargorge/routes/promo/show", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PromoShow extends _route.default {
    model(_ref) {
      let {
        slug
      } = _ref;
      return {
        slug
      };
    }
  }
  _exports.default = PromoShow;
});