define("cheddargorge/components/notification-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notification-card": "_notification-card_1y07bm",
    "is-popup-closed": "_is-popup-closed_1y07bm",
    "ui-button": "_ui-button_1y07bm",
    "exit-btn": "_exit-btn_1y07bm",
    "open": "_open_1y07bm",
    "heading": "_heading_1y07bm",
    "chevron": "_chevron_1y07bm",
    "line": "_line_1y07bm",
    "ui-spinner": "_ui-spinner_1y07bm",
    "body": "_body_1y07bm"
  };
  _exports.default = _default;
});