define("cheddargorge/components/ui-form-minus-plus-field/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'ui-form-minus-plus-field',
    maxQuantity: 32,
    minQuantity: 0,
    hasQuantity: (0, _computed.gt)('quantity', 0),
    cannotIncrement: (0, _computed.not)('canIncrement'),
    cannotDecrement: (0, _computed.not)('canDecrement'),
    canIncrement: (0, _object.computed)('quantity', 'maxQuantity', function () {
      return this.quantity < this.maxQuantity;
    }),
    canDecrement: (0, _object.computed)('quantity', 'minQuantity', function () {
      return this.quantity > this.minQuantity;
    }),
    actions: {
      increment() {
        if (this.cannotIncrement) {
          return;
        }
        this.setQuantity(parseInt(this.quantity, 10) + 1);
      },
      decrement() {
        if (this.cannotDecrement) {
          return;
        }
        this.setQuantity(parseInt(this.quantity, 10) - 1);
      },
      setQuantity() {
        let quantity = this.quantity;
        if (quantity < this.minQuantity) {
          quantity = this.minQuantity;
        }
        if (quantity > this.maxQuantity) {
          quantity = this.maxQuantity;
        }
        this.setQuantity(parseInt(quantity, 10));
      }
    }
  });
  _exports.default = _default;
});