define("cheddargorge/components/site-search-result-product/component", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result-product',
    product: (0, _computed.alias)('result.data'),
    image: (0, _computed.alias)('product.cardHeroImage'),
    hasMetaTitle: (0, _computed.notEmpty)('product.metaTitle')
  });
  _exports.default = _default;
});