define("cheddargorge/components/hero-banner-large/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hero-banner-large": "_hero-banner-large_ri1wov"
  };
  _exports.default = _default;
});