define("cheddargorge/controllers/search/index", ["exports", "@ember/controller", "@ember/utils"], function (_exports, _controller, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    actions: {
      changedQuery(query) {
        if ((0, _utils.isPresent)(query)) {
          return this.transitionToRoute("search.query", query);
        }
        return this.transitionToRoute("search.index");
      }
    }
  });
  _exports.default = _default;
});