define("cheddargorge/templates/visitor-info/opening-times", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pr0ERsGJ",
    "block": "[[[1,[28,[35,0],null,[[\"group\"],[\"Opening times\"]]]],[1,\"\\n\\n\"],[1,[34,1]],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"image\"],[[33,3,[\"heroBannerImage\"]]]]]],[1,\"\\n\\n\"],[6,[39,4],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[33,3,[\"name\"]]],[13],[1,\"\\n\\n    \"],[10,\"h5\"],[12],[1,\"Today's opening hours: \"],[10,\"strong\"],[12],[1,[34,6]],[13],[13],[1,\"\\n\\n\"],[6,[39,4],[\"white\"],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,7],null,[[\"displayDate\"],[[33,8]]]]],[1,\"\\n\\n      \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,9],[[33,3,[\"description\"]]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,10]],[1,\"\\n\"]],[],false,[\"broadcast-message\",\"find-it-fast\",\"hero-banner\",\"model\",\"ui-background\",\"section-body\",\"opening-hours-today\",\"opening-times-calendar\",\"displayDate\",\"wysiwyg-field\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/visitor-info/opening-times.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});