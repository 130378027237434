define("cheddargorge/components/product-details/component", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "@ember/service", "cheddargorge/helpers/sku-title"], function (_exports, _computed, _component, _object, _utils, _service, _skuTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */
  /* eslint-disable ember/no-classic-components */
  /* eslint-disable ember/require-tagless-components */
  var _default = _component.default.extend({
    goods: (0, _service.inject)('goods'),
    localClassNames: 'product-details',
    classNames: 'product-details',
    description: {
      isDescription: true
    },
    skus: [],
    hasProductTitleTemplate: (0, _computed.notEmpty)('productTitleTemplate'),
    priceTableFields: (0, _object.computed)(function () {
      return [{
        heading: 'Visitor',
        path: 'title',
        isStrong: true
      }, {
        heading: ''
      }, {
        heading: 'Price*',
        path: 'price',
        isStrong: true,
        isCentered: true
      }];
    }),
    priceTableData: (0, _object.computed)('priceListRowLabel', 'productTitleTemplate', 'skus.{[],firstObject.skuFields}', function () {
      let skuFields = this.skus.get('firstObject.skuFields');
      // Return blank if no sku is specified
      if ((0, _utils.isNone)(skuFields)) {
        return;
      }
      let rows = this.skus.reduce((rows, sku) => {
        let title = (0, _skuTitle.getSkuTitle)(this.goods, sku, this.productTitleTemplate);
        rows.push({
          title: title,
          price: '£' + (sku.get('price') / 100).toFixed(2),
          explanation: sku.get('attributes.explanation.firstObject')
        });
        return rows;
      }, []);
      return rows;
    })
  });
  _exports.default = _default;
});