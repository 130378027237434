define("cheddargorge/components/picture-grid/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "picture-grid": "_picture-grid_vg6yqn",
    "large-image": "_large-image_vg6yqn",
    "small-image-1": "_small-image-1_vg6yqn",
    "small-image-2": "_small-image-2_vg6yqn"
  };
  _exports.default = _default;
});