define("cheddargorge/templates/visitor-info/maps", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QYVBlzZb",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[6,[39,1],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[1,[34,2]],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5,[\"assets\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,6],null,[[\"title\",\"mapImage\",\"downloadLink\",\"isFirst\"],[[30,1,[\"name\"]],[30,1,[\"image\"]],[30,1,[\"file\"]],true]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,7]]],[\"map\"],false,[\"find-it-fast\",\"ui-background\",\"global-nav-buffer\",\"each\",\"-track-array\",\"model\",\"park-map\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/visitor-info/maps.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});