define("cheddargorge/components/product-cross-sell/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "product-cross-sell": "_product-cross-sell_e1biat",
    "header": "_header_e1biat",
    "heading": "_heading_e1biat",
    "subheading": "_subheading_e1biat",
    "cross-sell": "_cross-sell_e1biat",
    "actions": "_actions_e1biat",
    "buy-btn": "_buy-btn_e1biat",
    "skip-btn": "_skip-btn_e1biat"
  };
  _exports.default = _default;
});