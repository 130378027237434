define("cheddargorge/components/custom-attributes-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "form": "_form_1wuwgj",
    "metadata-instructions": "_metadata-instructions_1wuwgj",
    "basket-items": "_basket-items_1wuwgj",
    "actions": "_actions_1wuwgj",
    "metadata-row": "_metadata-row_1wuwgj",
    "btn-submit": "_btn-submit_1wuwgj",
    "ui-modal": "_ui-modal_1wuwgj",
    "is-narrow": "_is-narrow_1wuwgj",
    "modal-dialog-wrapper": "_modal-dialog-wrapper_1wuwgj"
  };
  _exports.default = _default;
});