define("cheddargorge/routes/products/partners", ["exports", "@ember/routing/route", "rsvp", "@ember/utils", "@ember/object", "@ember/service"], function (_exports, _route, _rsvp, _utils, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    session: (0, _service.inject)('sessionUser'),
    goods: (0, _service.inject)('goods'),
    model() {
      return this._getPartnerSku().then(this._getPartnerProducts.bind(this));
    },
    afterModel() {
      (0, _object.set)(this, 'headData.title', 'Partners | Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
    },
    beforeModel() {
      return this._getPartnerSku().then(this._validatePartnerSku.bind(this));
    },
    _getPartnerProducts(sku) {
      const partnerType = sku.get('attributes.partner-type.firstObject');
      let products = [];
      if (partnerType === 'Accommodation') {
        products = this.store.query('product', {
          filter: {
            slug: ['accommodation-gorge-and-caves-explorer-ticket', 'christmas-at-cheddar']
          }
        });
      } else if (partnerType === 'Corporate employee') {
        products = this.store.query('product', {
          filter: {
            slug: ['corporate-rewards-gorge-&-caves-explorer-ticket', 'christmas-at-cheddar']
          }
        });
      } else if (partnerType === 'Corporate employee 20%') {
        products = this.store.query('product', {
          filter: {
            slug: ['corporate-employee-gorge-and-caves-explorer-20-ticket', 'christmas-at-cheddar']
          }
        });
      }
      return _rsvp.default.hash({
        products: products,
        partnerSku: sku
      });
    },
    _getPartnerSku() {
      return (0, _object.get)(this, 'session.basket.basketItems').then(this._mapBy.bind(this, 'sku')).then(this._mapBy.bind(this, 'product')).then(this._findPartnerSku.bind(this, (0, _object.get)(this, 'session.basket.basketItems').mapBy('sku')));
    },
    _mapBy(prop, list) {
      return _rsvp.default.all(list.mapBy(prop));
    },
    _findPartnerSku(skus) {
      return skus.find(sku => (0, _object.get)(sku, 'product.slug') === 'partner');
    },
    _validatePartnerSku(sku) {
      if ((0, _utils.isEmpty)(sku)) {
        return this.transitionTo('partners');
      }
    }
  });
  _exports.default = _default;
});