define("cheddargorge/components/more-tesco-vouchers-message/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "T5jRhGnw",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[],[]],[[[41,[33,1],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,2],[\"message\"],[[\"from\"],[\"cheddargorge/components/more-tesco-vouchers-message/styles\"]]]]]],[12],[1,\"\\n      \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,\"h4\"],[12],[1,\"Have another Tesco Clubcard voucher?\"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"You can pay up to \"],[10,\"strong\"],[12],[1,[28,[35,4],[[28,[37,5],[[33,6]],null]],[[\"symbol\"],[\"£\"]]]],[13],[1,\" more with your Tesco Clubcard vouchers.\"],[13],[1,\"\\n        \"],[6,[39,7],null,[[\"route\"],[\"order.show.payment.tesco\"]],[[\"default\"],[[[[1,\"Apply another Tesco Clubcard voucher\"]],[]]]]],[1,\"\\n        \\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"hasTescoBalance\",\"local-class\",\"box-raised\",\"format-money\",\"pence-to-pounds\",\"tescoBalance\",\"link-to\"]]",
    "moduleName": "cheddargorge/components/more-tesco-vouchers-message/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});