define("cheddargorge/components/opening-times-calendar/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BxCRmEnj",
    "block": "[[[1,[28,[35,0],null,[[\"displayDate\",\"maxDate\",\"minDate\",\"changeDisplayDate\"],[[33,1],[33,2],[33,3],[28,[37,4],[[30,0],\"changeDisplayDate\"],null]]]]],[1,\"\\n\"],[1,[28,[35,5],null,[[\"displayDate\",\"days\"],[[33,1],[33,6]]]]],[1,\"\\n\"],[1,[28,[35,7],null,[[\"key\"],[[33,8]]]]],[1,\"\\n\"],[1,[28,[35,9],null,[[\"key\"],[[33,8]]]]]],[],false,[\"calendar-controls\",\"displayDate\",\"maxDate\",\"minDate\",\"action\",\"calendar-month\",\"days\",\"calendar-key\",\"key\",\"calendar-key-small-print\"]]",
    "moduleName": "cheddargorge/components/opening-times-calendar/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});