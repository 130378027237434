define("cheddargorge/components/select-tickets-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "select-tickets-form": "_select-tickets-form_amx2rz",
    "ticket-title": "_ticket-title_amx2rz",
    "instructions": "_instructions_amx2rz",
    "skus-wrapper": "_skus-wrapper_amx2rz",
    "select-visitors": "_select-visitors_amx2rz",
    "add-to-basket-btn": "_add-to-basket-btn_amx2rz",
    "continue-btn": "_continue-btn_amx2rz",
    "summary": "_summary_amx2rz",
    "group-upsell-message": "_group-upsell-message_amx2rz",
    "small-print": "_small-print_amx2rz",
    "error-message": "_error-message_amx2rz",
    "replaces-form": "_replaces-form_amx2rz",
    "ui-form-checkbox": "_ui-form-checkbox_amx2rz",
    "checkbox-label": "_checkbox-label_amx2rz",
    "ui-spinner": "_ui-spinner_amx2rz",
    "has-loading-error": "_has-loading-error_amx2rz",
    "ui-message": "_ui-message_amx2rz",
    "select-date": "_select-date_amx2rz",
    "is-loading-skus": "_is-loading-skus_amx2rz",
    "sku-group-header": "_sku-group-header_amx2rz",
    "quiet": "_quiet_amx2rz",
    "moderate": "_moderate_amx2rz",
    "busy": "_busy_amx2rz",
    "sold-out": "_sold-out_amx2rz",
    "covid-message": "_covid-message_amx2rz",
    "capacity-key": "_capacity-key_amx2rz",
    "key": "_key_amx2rz",
    "key-colour": "_key-colour_amx2rz",
    "key-label": "_key-label_amx2rz",
    "off-peak": "_off-peak_amx2rz",
    "peak": "_peak_amx2rz"
  };
  _exports.default = _default;
});