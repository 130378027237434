define("cheddargorge/components/product-carousel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "product-carousel": "_product-carousel_17t2ah"
  };
  _exports.default = _default;
});