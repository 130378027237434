define("cheddargorge/models/product", ["exports", "ember-data", "@ember/object", "@ember/object/computed"], function (_exports, _emberData, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Product = (_dec = _emberData.default.attr('string'), _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.attr('string'), _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr(), _dec6 = _emberData.default.hasMany('sku'), _dec7 = _emberData.default.belongsTo('brand'), _dec8 = _emberData.default.hasMany('product-field'), _dec9 = _emberData.default.hasMany('product-image'), _dec10 = _emberData.default.hasMany('product-category'), _dec11 = _emberData.default.hasMany('field-schema'), _dec12 = _emberData.default.hasMany('field-schema'), _dec13 = _emberData.default.hasMany('shop-product-payment-method'), _dec14 = (0, _object.computed)('productFields.[]'), _dec15 = (0, _object.computed)('skus.length'), _dec16 = (0, _computed.not)('hasSkus'), (_class = class Product extends _emberData.default.Model {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "summary", _descriptor2, this);
      _initializerDefineProperty(this, "description", _descriptor3, this);
      _initializerDefineProperty(this, "slug", _descriptor4, this);
      _initializerDefineProperty(this, "attrs", _descriptor5, this);
      _initializerDefineProperty(this, "skus", _descriptor6, this);
      _initializerDefineProperty(this, "brand", _descriptor7, this);
      _initializerDefineProperty(this, "productFields", _descriptor8, this);
      _initializerDefineProperty(this, "productImages", _descriptor9, this);
      _initializerDefineProperty(this, "productCategories", _descriptor10, this);
      _initializerDefineProperty(this, "schema", _descriptor11, this);
      _initializerDefineProperty(this, "skuSchema", _descriptor12, this);
      _initializerDefineProperty(this, "shopProductPaymentMethods", _descriptor13, this);
      _initializerDefineProperty(this, "hasNoSkus", _descriptor14, this);
    }
    get attributes() {
      return this.productFields.reduce((hash, attribute) => {
        hash[(0, _object.get)(attribute, 'slug')] = (0, _object.get)(attribute, 'values');
        return hash;
      }, {});
    }
    get hasSkus() {
      //@ts-ignore
      return this.hasMany('skus').ids().length > 0;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "summary", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "attrs", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "skus", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "productFields", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "productImages", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "productCategories", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "schema", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "skuSchema", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "shopProductPaymentMethods", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "attributes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasSkus", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "hasSkus"), _class.prototype), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasNoSkus", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Product;
});