define("cheddargorge/components/commerce/field/visitors.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "visitors-selector": "_visitors-selector_1pbqg5",
    "layout-condensed": "_layout-condensed_1pbqg5",
    "visitor": "_visitor_1pbqg5"
  };
  _exports.default = _default;
});