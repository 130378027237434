define("cheddargorge/components/ui-form-minus-plus-field/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "increment": "_increment_7msh08",
    "decrement": "_decrement_7msh08",
    "quantity-value": "_quantity-value_7msh08",
    "quantity-input": "_quantity-input_7msh08"
  };
  _exports.default = _default;
});