define("cheddargorge/components/calendar-key-small-print/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "X8EmqkfI",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"small-print\"],[[\"from\"],[\"cheddargorge/components/calendar-key-small-print/styles\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"key\"]]],null]],null],null,[[[41,[30,1,[\"qualifierSymbol\"]],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,0],[\"qualifier\"],[[\"from\"],[\"cheddargorge/components/calendar-key-small-print/styles\"]]]]]],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[[28,[37,0],[\"symbol\"],[[\"from\"],[\"cheddargorge/components/calendar-key-small-print/styles\"]]]]]],[12],[1,[30,1,[\"qualifierSymbol\"]]],[13],[1,\"\\n        \"],[10,1],[15,0,[29,[[28,[37,0],[\"copy\"],[[\"from\"],[\"cheddargorge/components/calendar-key-small-print/styles\"]]]]]],[12],[1,\"\\n          \"],[1,[30,1,[\"qualifierText\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[1]],null],[13]],[\"keyItem\"],false,[\"local-class\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "cheddargorge/components/calendar-key-small-print/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});