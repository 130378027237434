define("cheddargorge/components/ui-spinner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-spinner": "_ui-spinner_dfradm",
    "spin": "_spin_dfradm"
  };
  _exports.default = _default;
});