define("cheddargorge/components/password-access/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "password-wrapper": "_password-wrapper_6tnufk",
    "password-inner": "_password-inner_6tnufk",
    "password-input-section": "_password-input-section_6tnufk",
    "password-error": "_password-error_6tnufk"
  };
  _exports.default = _default;
});