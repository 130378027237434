define("cheddargorge/components/product-carousel/component", ["exports", "@ember/object", "@ember/component", "@ember/object/computed"], function (_exports, _object, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: 'product-carousel',
    localClassNames: 'product-carousel',
    ribbonLabel: (0, _computed.alias)('product.attributes.tagline'),
    images: (0, _object.computed)('product.attributes.images.[]', function () {
      let images = (0, _object.get)(this, 'product.attributes.images') === undefined ? [] : (0, _object.get)(this, 'product.attributes.images');
      if (Array.isArray(images) === false) {
        images = [images];
      }
      return images.map(image => {
        return {
          originalUrl: image
        };
      });
    })
  });
  _exports.default = _default;
});