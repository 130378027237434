define("cheddargorge/components/iconic-action/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lAjaQusK",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"icon\"],[[\"from\"],[\"cheddargorge/components/iconic-action/styles\"]]]]]],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[36,1]],[15,\"alt\",[36,1]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"local-class\",\"iconName\",\"yield\"]]",
    "moduleName": "cheddargorge/components/iconic-action/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});