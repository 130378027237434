define("cheddargorge/components/commerce/field/visitor.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "inset-border": "5px",
    "button-size": "44px",
    "quantity-selector": "_quantity-selector_1m6flm",
    "info": "_info_1m6flm",
    "quantity": "_quantity_1m6flm",
    "name": "_name_1m6flm",
    "price": "_price_1m6flm",
    "explanation": "_explanation_1m6flm",
    "quantity-value": "_quantity-value_1m6flm",
    "is-zero": "_is-zero_1m6flm",
    "decrement-btn": "_decrement-btn_1m6flm",
    "increment-btn": "_increment-btn_1m6flm"
  };
  _exports.default = _default;
});