define("cheddargorge/components/grid-picture-card/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tVIPIOuV",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"card-inner\"],[[\"from\"],[\"cheddargorge/components/grid-picture-card/styles\"]]]]]],[15,5,[28,[37,1],[[33,2]],null]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"safe-background-image\",\"cardImage\",\"yield\"]]",
    "moduleName": "cheddargorge/components/grid-picture-card/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});