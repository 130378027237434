define("cheddargorge/components/order-completion-layout/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5csLFf+A",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"column-wrapper\"],[[\"from\"],[\"cheddargorge/components/order-completion-layout/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"column\"],[[\"from\"],[\"cheddargorge/components/order-completion-layout/styles\"]]]]]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"yield\"]]",
    "moduleName": "cheddargorge/components/order-completion-layout/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});