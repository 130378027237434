define("cheddargorge/templates/explorer/attraction", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dP0rcR8X",
    "block": "[[[1,[28,[35,0],null,[[\"image\"],[[33,1,[\"heroBannerImage\"]]]]]],[1,\"\\n\\n\"],[6,[39,2],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],null,[[\"label\",\"link\"],[\"Back to all Explorer attractions\",\"explorer\"]]]],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,1,[\"name\"]]],[13],[1,\"\\n\\n    \"],[1,[28,[35,5],[[33,1,[\"description\"]]],null]],[1,\"\\n\\n    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n      \"],[6,[39,7],null,[[\"class\",\"route\"],[\"ui-button\",\"products.index\"]],[[\"default\"],[[[[1,\"Get tickets\"]],[]]]]],[1,\"\\n\"],[41,[33,1,[\"ctaUrl\"]],[[[1,\"        \"],[10,3],[15,6,[33,1,[\"ctaUrl\"]]],[14,0,\"ui-button\"],[12],[1,[33,1,[\"ctaLabel\"]]],[13],[1,\"\\n\"]],[]],null],[41,[33,1,[\"fileDownload1\"]],[[[1,\"        \"],[10,3],[15,6,[33,1,[\"fileDownload1\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[33,1,[\"fileDownloadLabel1\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n    \"],[1,[34,9]],[1,\"\\n\\n    \"],[1,[34,10]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"],[41,[33,1,[\"gridImage1\"]],[[[1,\"  \"],[1,[28,[35,11],null,[[\"large\",\"topSmall\",\"bottomSmall\"],[[33,1,[\"gridImage1\"]],[33,1,[\"gridImage2\"]],[33,1,[\"gridImage3\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,1,[\"gridImage4\"]],[[[1,\"  \"],[1,[28,[35,11],null,[[\"large\",\"topSmall\",\"bottomSmall\",\"isReversed\"],[[33,1,[\"gridImage4\"]],[33,1,[\"gridImage5\"]],[33,1,[\"gridImage6\"]],true]]]],[1,\"\\n\"]],[]],null]],[],false,[\"hero-banner\",\"model\",\"ui-background\",\"section-body\",\"ui-breadcrumbs\",\"wysiwyg-field\",\"section-double-actions\",\"link-to\",\"if\",\"ui-separator\",\"attraction-social-actions\",\"picture-grid\"]]",
    "moduleName": "cheddargorge/templates/explorer/attraction.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});