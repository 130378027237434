define("cheddargorge/components/product-details/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "product-details": "_product-details_1py700",
    "product-columns": "_product-columns_1py700",
    "product-prices": "_product-prices_1py700",
    "product-description": "_product-description_1py700",
    "small-print": "_small-print_1py700",
    "component-price-table": "_component-price-table_1py700"
  };
  _exports.default = _default;
});