define("cheddargorge/components/jumbo-select/component", ["exports", "jquery", "@ember/runloop", "@ember/object/computed", "@ember/component"], function (_exports, _jquery, _runloop, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'jumbo-select',
    localClassNameBindings: ['isShowingPopup', 'hasValue', 'isDisabled'],
    hasValue: (0, _computed.notEmpty)('value'),
    didInsertElement() {
      this._super(...arguments);
      this._clickListener = (0, _runloop.bind)(this, this.clickHandler);
      (0, _jquery.default)(document).on('click', this._clickListener);
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this._clickListener !== null) {
        (0, _jquery.default)(document).off('click', this._clickListener);
      }
    },
    clickHandler(e) {
      if (this.$().find(e.target).length === 0) {
        (0, _runloop.scheduleOnce)('afterRender', this, () => {
          this.set('isShowingPopup', false);
        });
      }
    },
    actions: {
      togglePopup() {
        this.toggleProperty('isShowingPopup');
      }
    }
  });
  _exports.default = _default;
});