define("cheddargorge/components/icons-social/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icons-social": "_icons-social_80kt9k",
    "facebook": "_facebook_80kt9k",
    "twitter": "_twitter_80kt9k",
    "youtube": "_youtube_80kt9k",
    "instagram": "_instagram_80kt9k",
    "tiktok": "_tiktok_80kt9k"
  };
  _exports.default = _default;
});