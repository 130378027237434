define("cheddargorge/routes/education/index", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service"], function (_exports, _route, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    queryParams: {
      filter: {
        refreshModel: true
      }
    },
    model() {
      let store = this.store;
      return _rsvp.default.hash({
        education: store.query("organisation-department", {
          filter: {
            slug: "education"
          }
        }, {
          reload: true
        }).then(this._getFirstObject.bind(this)),
        workshopTypes: store.findAll("education-workshop-type", {
          reload: true
        }).then(this._sortByOrder.bind(this)),
        resources: store.findAll("education-resource", {
          reload: true
        }).then(this._getIconicResources.bind(this))
      });
    },
    afterModel(hash) {
      (0, _object.set)(this, "headData.title", hash.education.metaTitle);
      (0, _object.set)(this, "headData.description", hash.education.metaDescription);
    },
    serialize: function (event) {
      return {
        slug: event.slug
      };
    },
    _getFirstObject(objects) {
      return objects.firstObject;
    },
    _getIconicResources(resources) {
      return {
        planningAndBooking: resources.findBy("slug", "planning-and-booking"),
        healthAndSafety: resources.findBy("slug", "health-and-safety"),
        pricesAndPayment: resources.findBy("slug", "prices-and-payment")
      };
    },
    _sortByOrder(model) {
      return model.sortBy("order");
    }
  });
  _exports.default = _default;
});