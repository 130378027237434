define("cheddargorge/validators/state-presence", ["exports", "@ember/utils", "ember-cp-validations/validators/base"], function (_exports, _utils, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    validate(value, options, model) {
      if (model.country !== 'US') {
        return true;
      }
      return (0, _utils.isPresent)(value);
    }
  });
  _exports.default = _default;
});