define("cheddargorge/helpers/format-session-time", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatSessionTime = formatSessionTime;
  function formatSessionTime(params /*, hash*/) {
    let time = params[0];
    return time.substring(0, time.length - 3);
  }
  var _default = (0, _helper.helper)(formatSessionTime);
  _exports.default = _default;
});