define("cheddargorge/components/payment-form/component", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/object/computed", "cheddargorge/utils/credit-card-type"], function (_exports, _component, _utils, _object, _computed, _creditCardType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ember/no-observers: 0 */
  var _default = _component.default.extend({
    localClassNames: 'payment-form',
    error: null,
    expiryMonth: null,
    expiryYear: null,
    validFromMonth: null,
    validFromYear: null,
    isCardNumberValid: (0, _computed.notEmpty)('payment.cardNumber'),
    isCardholderValid: (0, _computed.notEmpty)('payment.cardholder'),
    isExpiryDateValid: (0, _computed.notEmpty)('payment.expiryDate'),
    isCVCValid: (0, _computed.notEmpty)('payment.cvv'),
    canSubmit: (0, _computed.and)('isCardNumberValid', 'isCardholderValid', 'isExpiryDateValid', 'isCVCValid'),
    cannotSubmit: (0, _computed.not)('canSubmit'),
    cardNumberChanges: (0, _object.observer)('payment.cardNumber', function () {
      const cardNumber = (0, _object.get)(this, 'payment.cardNumber').replace(/\s+/g, '');
      const cardType = (0, _creditCardType.default)(cardNumber);
      (0, _object.set)(this, 'payment.cardType', cardType);
    }),
    expiryDateChanged: (0, _object.observer)('expiryMonth', 'expiryYear', function () {
      let expiryYear = this.expiryYear;
      if ((0, _utils.isPresent)(expiryYear)) {
        expiryYear = expiryYear.slice(-2);
      }
      (0, _object.set)(this, 'payment.expiryDate', this.expiryMonth + expiryYear);
    })
  });
  _exports.default = _default;
});