define("cheddargorge/components/icons-affiliations/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ieT84dIp",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"dog-friendly\"],[[\"from\"],[\"cheddargorge/components/icons-affiliations/styles\"]]]]]],[12],[1,\"Dog Friendly\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"visit-england\"],[[\"from\"],[\"cheddargorge/components/icons-affiliations/styles\"]]]]]],[12],[1,\"Visit England\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"visit-somerset\"],[[\"from\"],[\"cheddargorge/components/icons-affiliations/styles\"]]]]]],[12],[1,\"Visit Somerset\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"abis\"],[[\"from\"],[\"cheddargorge/components/icons-affiliations/styles\"]]]]]],[12],[1,\"ABIS\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"sta\"],[[\"from\"],[\"cheddargorge/components/icons-affiliations/styles\"]]]]]],[12],[1,\"School Travel Awards\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"scoc\"],[[\"from\"],[\"cheddargorge/components/icons-affiliations/styles\"]]]]]],[12],[1,\"Somerset Chamber of Commerce\"],[13],[1,\"\\n\"]],[],false,[\"local-class\"]]",
    "moduleName": "cheddargorge/components/icons-affiliations/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});