define("cheddargorge/routes/products/list", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "@ember/utils", "cheddargorge/helpers/load"], function (_exports, _route, _service, _object, _utils, _load) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProductsList = (_class = class ProductsList extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "headData", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
    }
    model(_ref) {
      let {
        slug
      } = _ref;
      let productListData = (0, _load.load)(this.loadProductListData(this.store, this.router, this.headData, slug));
      return {
        productListData
      };
    }

    /**
     * Loads the product list data
     *
     * @param {Store} store
     * @param {RouterService} router
     * @param {HeadData} headData
     * @param {string} slug
     */
    async loadProductListData(store, router, headData, slug) {
      let productLists = await store.query('product-list', {
        filter: {
          slug: slug
        },
        include: ['cards'].join(',')
      });
      if ((0, _utils.isEmpty)(productLists)) {
        return router.transitionTo('error404', 'page-not-found');
      }
      let productList = productLists.get('firstObject');
      (0, _object.set)(headData, 'title', productList.get('metaTitle') + ' | Cheddar Gorge');
      (0, _object.set)(headData, 'description', productList.get('metaDescription'));
      return productList;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ProductsList;
});