define("cheddargorge/components/calendar-month/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "calendar-month": "_calendar-month_tt2vmx",
    "days-of-week": "_days-of-week_tt2vmx",
    "days": "_days_tt2vmx",
    "day-of-week": "_day-of-week_tt2vmx"
  };
  _exports.default = _default;
});