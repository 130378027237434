define("cheddargorge/components/ui-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "35qFg9LX",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],null,[[\"borderWidth\",\"width\",\"height\"],[\"2px\",\"16px\",\"16px\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"label\"],[12],[1,[34,5]],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"isLoading\",\"ui-spinner\",\"has-block\",\"yield\",\"label\"]]",
    "moduleName": "cheddargorge/components/ui-button/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});