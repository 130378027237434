define("cheddargorge/templates/order/show/payment/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eBOQ8OKD",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[6,[39,1],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],null,[[\"step\"],[\"payment\"]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,1],[\"black-squeeze\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n\\n\"],[41,[30,1,[\"isMajorContent\"]],[[[1,\"\\n      \"],[1,[28,[35,6],null,[[\"orderId\"],[[33,7,[\"id\"]]]]]],[1,\"\\n\\n      \"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n\\n        \"],[10,\"h1\"],[12],[1,\"Enter card details\"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"Almost there! Enter your card details and complete your order.\"],[13],[1,\"\\n\\n        \"],[1,[28,[35,9],null,[[\"payment\",\"errors\",\"error\",\"isSubmitting\",\"createPayment\"],[[33,10],[33,11],[33,12],[33,13],[28,[37,14],[\"createPayment\"],null]]]]],[1,\"\\n      \\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"isMinorContent\"]],[[[1,\"      \"],[1,[28,[35,15],null,[[\"source\",\"promotionLines\"],[[33,10,[\"order\"]],[33,16]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\\n  \"],[1,[34,17]],[1,\"\\n  \\n\"]],[]]]]],[1,\"\\n\"],[1,[34,18]],[1,\"\\n\\n\"],[8,[39,19],null,[[\"@payment\"],[[30,0,[\"payment\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,21],null,null],null,null,null],[1,\"\\n\"]],[\"sections\"],false,[\"global-nav-buffer\",\"ui-background\",\"heading-group-strong\",\"checkout-progress-bar\",\"checkout-layout\",\"if\",\"more-tesco-vouchers-message\",\"order\",\"box-raised\",\"payment-form\",\"payment\",\"errors\",\"error\",\"isSubmitting\",\"route-action\",\"order-summary\",\"promotionLines\",\"purchase-promise\",\"global-footer\",\"commerce/payment/challenge-form\",\"component\",\"-outlet\"]]",
    "moduleName": "cheddargorge/templates/order/show/payment/index.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});