define("cheddargorge/components/custom-attributes-form/component", ["exports", "@glimmer/component", "@ember/utils", "@ember/object"], function (_exports, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class CustomAttributesForm extends _component.default {
    get customAttributeBasketItems() {
      return this.args.basketItems.filter(basketItem => {
        //@ts-ignore
        let giftInfo = basketItem.get('sku.attributes.gift-info');
        return (0, _utils.isPresent)(giftInfo) && giftInfo[0] == true;
      });
    }
    get cannotSubmit() {
      let gifts = this.customAttributeBasketItems.flatMap(basketItem => (0, _object.get)(basketItem, 'metadata.gifts'));
      return gifts.any(gift => (0, _utils.isEmpty)((0, _object.get)(gift, 'fullName')) || (0, _utils.isEmpty)((0, _object.get)(gift, 'age')) || (0, _utils.isEmpty)((0, _object.get)(gift, 'favouriteHobbies')));
    }
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "ages", ['0 to 12 months', '1 year old', '2 years old', '3 years old', '4 years old', '5 years old', '6 years old', '7 years old', '8 years old', '9 years old', '10 years old and over']);
      this.customAttributeBasketItems.forEach(basketItem => {
        if ((0, _utils.isEmpty)(basketItem.get('metadata'))) {
          let metadata = [];
          for (let i = 0; i < basketItem.get('quantity'); i++) {
            metadata.pushObject({
              fullName: '',
              age: '',
              favouriteHobbies: ''
            });
          }
          (0, _object.set)(basketItem, 'metadata', {
            gifts: metadata
          });
        }
      });
    }
  }
  _exports.default = CustomAttributesForm;
});