define("cheddargorge/instance-initializers/algolia-config", ["exports", "@ember/object", "cheddargorge/config/environment", "algolia-search"], function (_exports, _object, _environment, _algoliaSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    const service = appInstance.lookup('service:algolia');
    const algoliaConfig = _environment.default['ember-algolia'];
    (0, _object.set)(service, 'client', (0, _algoliaSearch.default)(algoliaConfig.algoliaId, algoliaConfig.algoliaKey));
  }
  var _default = {
    name: 'algolia-config',
    initialize
  };
  _exports.default = _default;
});