define("cheddargorge/routes/order/show", ["exports", "@ember/routing/route", "cheddargorge/utils/load-order"], function (_exports, _route, _loadOrder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class OrderShow extends _route.default {
    async model(_ref) {
      let {
        uuid: uuid
      } = _ref;
      try {
        let order = await (0, _loadOrder.loadOrder)(this.store, uuid);
        if (["reserved", "fulfilled"].includes(order.get("status")) == false) {
          throw new Error("order_status_invalid");
        }
        return order;
      } catch (e) {
        return this.transitionTo("checkout");
      }
    }
  }
  _exports.default = OrderShow;
});