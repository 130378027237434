define("cheddargorge/components/site-search/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vRkvhK/b",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"search\"],[[\"from\"],[\"cheddargorge/components/site-search/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"search-input-wrapper\"],[[\"from\"],[\"cheddargorge/components/site-search/styles\"]]]]]],[12],[1,\"\\n    \"],[10,\"input\"],[15,0,[29,[[28,[37,0],[\"search-input\"],[[\"from\"],[\"cheddargorge/components/site-search/styles\"]]]]]],[14,1,\"site-search-input\"],[15,2,[36,1]],[15,\"oninput\",[28,[37,2],[[30,0],[30,0,[\"changedQuery\"]]],[[\"value\"],[\"target.value\"]]]],[14,4,\"text\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"results\"],[[\"from\"],[\"cheddargorge/components/site-search/styles\"]]]]]],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"Search results\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"search\",\"isRunning\"]],[[[1,\"    \"],[10,0],[15,0,[29,[[28,[37,0],[\"is-loading\"],[[\"from\"],[\"cheddargorge/components/site-search/styles\"]]]]]],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"borderWidth\",\"width\",\"height\"],[\"2px\",\"32px\",\"32px\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"results\",\"length\"]],[[[42,[28,[37,6],[[28,[37,6],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,7],null,[[\"result\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[41,[30,0,[\"isQueryTooShort\"]],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,0],[\"is-too-short\"],[[\"from\"],[\"cheddargorge/components/site-search/styles\"]]]]]],[12],[1,\"\\n        Please enter at least three characters to start your search.\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,0],[\"no-results\"],[[\"from\"],[\"cheddargorge/components/site-search/styles\"]]]]]],[12],[1,\"\\n        No results found\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]]],[]]],[1,\"\\n\"],[13]],[\"result\"],false,[\"local-class\",\"query\",\"action\",\"if\",\"ui-spinner\",\"each\",\"-track-array\",\"site-search-result\"]]",
    "moduleName": "cheddargorge/components/site-search/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});