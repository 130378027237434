define("cheddargorge/components/commerce/payment/challenge-form", ["exports", "@ember/component", "@ember/template-factory", "@ember/utils", "@glimmer/component"], function (_exports, _component, _templateFactory, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isChallengingPayment}}
    <UiModal
      @isNarrow={{true}}
    > 
      <div local-class="payment-challenge-wrapper">
        <PaymentChallenge
          @payment={{@payment}}
        />
      </div>
    </UiModal> 
  {{/if}}
  */
  {
    "id": "n6XOQlM1",
    "block": "[[[41,[30,0,[\"isChallengingPayment\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@isNarrow\"],[true]],[[\"default\"],[[[[1,\" \\n    \"],[10,0],[15,0,[29,[[28,[37,2],[\"payment-challenge-wrapper\"],[[\"from\"],[\"cheddargorge/components/commerce/payment/challenge-form.scss\"]]]]]],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@payment\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\" \\n\"]],[]],null]],[\"@payment\"],false,[\"if\",\"ui-modal\",\"local-class\",\"payment-challenge\"]]",
    "moduleName": "cheddargorge/components/commerce/payment/challenge-form.hbs",
    "isStrictMode": false
  });
  class CommercePaymentChallengeFormComponent extends _component2.default {
    get isChallengingPayment() {
      return !(0, _utils.isEmpty)(this.args.payment.get("challengeUrl"));
    }
  }
  _exports.default = CommercePaymentChallengeFormComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CommercePaymentChallengeFormComponent);
});