define("cheddargorge/templates/visitor-info/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4PDklm/A",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"image\"],[[33,2,[\"heroBannerImage\"]]]]]],[1,\"\\n\\n\"],[6,[39,3],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,2,[\"name\"]]],[13],[1,\"\\n\\n    \"],[1,[34,5]],[1,\"\\n\\n    \"],[1,[28,[35,6],[[33,2,[\"description\"]]],null]],[1,\"\\n\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,7]]],[],false,[\"find-it-fast\",\"hero-banner\",\"model\",\"ui-background\",\"section-body\",\"ui-separator\",\"wysiwyg-field\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/visitor-info/show.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});