define("cheddargorge/templates/upsell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "styLFAwn",
    "block": "[[[8,[39,0],null,[[\"@date\",\"@visitors\",\"@upsellsData\"],[[30,0,[\"model\",\"date\"]],[30,0,[\"model\",\"visitors\"]],[30,0,[\"model\",\"upsellsData\"]]]],null]],[],false,[\"template/upsell\"]]",
    "moduleName": "cheddargorge/templates/upsell.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});