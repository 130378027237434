define("cheddargorge/routes/education/workshop", ["exports", "@ember/routing/route", "rsvp", "@ember/service"], function (_exports, _route, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    storeHelper: (0, _service.inject)("store-helper"),
    model(params) {
      const storeHelper = this.storeHelper;
      return _rsvp.default.hash({
        workshop: storeHelper.queryRecord("education-workshop", {
          slug: params.slug
        }, true),
        education: storeHelper.queryRecord("organisation-department", {
          slug: "education"
        })
      });
    },
    serialize: function (workshops) {
      return {
        slug: workshops.slug
      };
    },
    _getFirstObject(object) {
      return object.firstObject;
    }
  });
  _exports.default = _default;
});