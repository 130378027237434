define("cheddargorge/routes/vip-pass", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class VipPass extends _route.default.extend({
    // anything which *must* be merged to prototype here
  }) {
    // normal class body definition here
  }
  _exports.default = VipPass;
});