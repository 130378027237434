define("cheddargorge/components/basket/warning-message/caving-only-has-one-ticket", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="Warning"
    >
      <p>
        For health and safety reasons, caving sessions need a minimum of two participants to run. If you are the only participant to book this session, we will contact you the day before to find an alternative slot.
      </p>
    </Basket::WarningMessage>
  {{/if}}
  */
  {
    "id": "7Pxpt5dW",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"Warning\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      For health and safety reasons, caving sessions need a minimum of two participants to run. If you are the only participant to book this session, we will contact you the day before to find an alternative slot.\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/caving-only-has-one-ticket.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageCavingOnlyHasOneTicket extends _component2.default {
    /**
     * Is the warning message visible? Shows if there is only one Caving ticket in the basket
     */
    get isVisible() {
      let productBasketItems = this.args.basketItems.filterBy('sku.content.product.slug', 'rocksport-caving');
      let quantity = productBasketItems.mapBy('quantity').reduce((total, quantity) => quantity + total, 0);
      return quantity == 1;
    }
  }
  _exports.default = BasketWarningMessageCavingOnlyHasOneTicket;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageCavingOnlyHasOneTicket);
});