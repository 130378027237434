define("cheddargorge/components/basket/warning-message/only-has-adult-junior-rock-caving", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="Please note"
    >
      <p>
        Please note - You only have adult tickets in your basket - This activity is dedicated to Junior Adventurers, accompanied by a ticketed adult. To book only adult tickets, please <LinkTo @route="product" @model="rocksport-junior-caving">reselect your activity category here</LinkTo>.
      </p>
    </Basket::WarningMessage>
  {{/if}}
  */
  {
    "id": "dCsD4otw",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"Please note\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Please note - You only have adult tickets in your basket - This activity is dedicated to Junior Adventurers, accompanied by a ticketed adult. To book only adult tickets, please \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"product\",\"rocksport-junior-caving\"]],[[\"default\"],[[[[1,\"reselect your activity category here\"]],[]]]]],[1,\".\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\",\"link-to\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/only-has-adult-junior-rock-caving.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageOnlyHasAdultJuniorRockCaving extends _component2.default {
    /**
     * Is the warning message visible? Shows if there are Junior Caving
     * tickets for adults in the basket and none for children
     */
    get isVisible() {
      let productBasketItems = this.args.basketItems.filterBy('sku.content.product.slug', 'rocksport-junior-caving');
      let adults = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Adult');
      let children = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Child');
      return (0, _utils.isPresent)(adults) && (0, _utils.isEmpty)(children);
    }
  }
  _exports.default = BasketWarningMessageOnlyHasAdultJuniorRockCaving;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageOnlyHasAdultJuniorRockCaving);
});