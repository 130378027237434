define("cheddargorge/components/ui/link-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::LinkTo
    local-class="link-button"
    @href={{@href}}
    @route={{@route}}
    @model={{@model}}
    ...attributes
  >
    {{#if @label}}
      {{@label}}
    {{else}}
      {{yield}}
    {{/if}}
  </Ui::LinkTo>
  */
  {
    "id": "D21EyRdt",
    "block": "[[[8,[39,0],[[16,0,[29,[[28,[37,1],[\"link-button\"],[[\"from\"],[\"cheddargorge/components/ui/link-button.scss\"]]]]]],[17,1]],[[\"@href\",\"@route\",\"@model\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[1,[30,5]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,6,null],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"@href\",\"@route\",\"@model\",\"@label\",\"&default\"],false,[\"ui/link-to\",\"local-class\",\"if\",\"yield\"]]",
    "moduleName": "cheddargorge/components/ui/link-button.hbs",
    "isStrictMode": false
  });
  class UiLinkButton extends _component2.default {}
  _exports.default = UiLinkButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiLinkButton);
});