define("cheddargorge/components/ui-form-inset-textfield/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-form-inset-textfield": "_ui-form-inset-textfield_t5t52z",
    "textfield-label": "_textfield-label_t5t52z",
    "textfield-message": "_textfield-message_t5t52z",
    "textfield-input": "_textfield-input_t5t52z",
    "show-mini-label": "_show-mini-label_t5t52z",
    "is-valid": "_is-valid_t5t52z",
    "has-error": "_has-error_t5t52z",
    "can-clear": "_can-clear_t5t52z",
    "clear-btn": "_clear-btn_t5t52z",
    "is-disabled": "_is-disabled_t5t52z",
    "error": "_error_t5t52z"
  };
  _exports.default = _default;
});