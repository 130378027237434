define("cheddargorge/components/commerce/field/collapsible.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "collapsible-field": "_collapsible-field_13yvty",
    "toggle-expanded-btn": "_toggle-expanded-btn_13yvty",
    "label": "_label_13yvty",
    "collapsed": "_collapsed_13yvty"
  };
  _exports.default = _default;
});