define("cheddargorge/components/reveal-content/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var component = _component.default.extend({
    classNames: 'reveal-content',
    localClassNames: 'reveal-content-wrapper',
    localClassNameBindings: ['isRevealed', 'theme'],
    theme: 'default',
    isRevealed: false,
    showCaret: true,
    init() {
      this._super(...arguments);
      this.isRevealed = false;
    },
    actions: {
      toggleReveal() {
        this.toggleProperty('isRevealed');
      }
    }
  });
  component.reopenClass({
    positionalParams: ['title']
  });
  var _default = component;
  _exports.default = _default;
});