define("cheddargorge/services/tickets", ["exports", "@ember/service", "@ember/object", "@ember/utils", "@ember/string", "moment"], function (_exports, _service, _object, _utils, _string, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Tickets extends _service.default {
    /**
     *
     * @param productData
     * @param skusData
     */
    async getVisitors(productData, skusData, selection) {
      await productData;
      await skusData;
      let skus = skusData.value;
      let product = productData.value;
      let productAttributes = product.get('attrs');
      let visitors = [];
      if ((0, _utils.isNone)(selection)) {
        selection = [];
      }
      skus.forEach(sku => {
        let skuAttributes = sku.get('attrs');
        let name = this.skuName(skuAttributes, productAttributes.skuTitleTemplate);
        let visitor = visitors.find(row => row['name'] == name);
        let price = sku.get('price');
        let quantity = 0;
        let existingSelection = selection.find(row => row['name'] == name);
        if (!(0, _utils.isNone)(existingSelection)) {
          quantity = existingSelection.quantity;
        }
        if ((0, _utils.isNone)(visitor)) {
          visitors.pushObject({
            name,
            price,
            quantity: quantity,
            isFrom: false
          });
        } else {
          if (price < visitor.price) {
            visitor.price = price;
            visitor.isFrom = true;
          }
        }
      }, visitors);
      return visitors;
    }

    /**
     *
     * @param attributes
     * @param template
     * @returns
     */
    skuName(attributes, template) {
      return Object.keys(attributes).reduce((title, key) => {
        let regex = new RegExp(`{{${key}}}`, 'gi');
        let value = attributes[key];
        if (key === 'session-start-time') {
          value = attributes[key][0];
          value = value.substring(0, value.length - 3);
        }
        return title.replace(regex, value);
      }, template);
    }

    /**
     *
     * @param {Sku} sku The SKU
     * @returns {boolean} true if the sku has gift info
     */
    hasGiftInfo(sku) {
      let giftInfo = sku.get('attributes.gift-info');
      return !(0, _utils.isNone)(giftInfo) && giftInfo[0] == true;
    }

    /**
     *
     */
    getGiftFields(product) {
      let fields = product.get('attrs')['lineItemCustomisationFields'] ?? [];
      return fields.map(field => (0, _string.camelize)(field));
    }

    /**
     *
     */
    basketContainsProductForAfterDate(basket, date) {
      return basket.basketItems.map(basketItem => {
        let skuAttributes = basketItem.get('sku').get('attributes');
        let bookableDate = (0, _object.get)(skuAttributes, 'bookable-date');
        if (!(0, _utils.isEmpty)(bookableDate)) {
          return bookableDate[0];
        }
        return null;
      }).compact().any(bookableDate => {
        let mBookableDate = (0, _moment.default)(bookableDate);
        return mBookableDate.isAfter(date);
      });
    }

    /**
     * Returns the latest visit date in the list basket items
     * @param {BasketItem[]} basketItems
     * @returns the latest visit date or defaults to todays date
     */
    getLatestVisitDate(basketItems) {
      return basketItems.reduce((date, basketItem) => {
        let attrs = basketItem.get('sku').get('attrs');
        let itemDate = (0, _object.get)(attrs, 'bookableDate');
        if ((0, _utils.isNone)(itemDate)) {
          return date;
        }
        if ((0, _moment.default)(itemDate).isAfter(date)) {
          return (0, _moment.default)(itemDate);
        }
        return date;
      }, (0, _moment.default)().endOf('day'));
    }
  }
  _exports.default = Tickets;
});