define("cheddargorge/components/button-text/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button-text": "_button-text_6m2en4",
    "active": "_active_6m2en4"
  };
  _exports.default = _default;
});