define("cheddargorge/components/calendar-month-day/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "calendar-month-day": "_calendar-month-day_1j0n73",
    "is-previous-month": "_is-previous-month_1j0n73",
    "is-next-month": "_is-next-month_1j0n73",
    "is-disabled": "_is-disabled_1j0n73",
    "day": "_day_1j0n73",
    "is-selectable": "_is-selectable_1j0n73",
    "is-selected": "_is-selected_1j0n73"
  };
  _exports.default = _default;
});