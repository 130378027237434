define("cheddargorge/components/section-instagram/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "section-instagram": "_section-instagram_16b8yi",
    "description": "_description_16b8yi",
    "gallery": "_gallery_16b8yi",
    "gallery-image": "_gallery-image_16b8yi",
    "cta-link": "_cta-link_16b8yi"
  };
  _exports.default = _default;
});