define("cheddargorge/templates/escape-rooms", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MWDZ4ywU",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@attraction\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[1,[34,2]]],[],false,[\"find-it-fast\",\"attractions/attraction\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/escape-rooms.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});