define("cheddargorge/helpers/format-currency", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;
  var _default = _helper.default.extend({
    compute(_ref) {
      let [value, currency = 'GBP', addSymbol = true] = _ref;
      return formatCurrency(value, currency, addSymbol);
    }
  });
  _exports.default = _default;
  function formatCurrency(value) {
    let currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GBP';
    let addSymbol = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    if (currency === 'GBP') {
      let formatted = (value / 100).toFixed(2).toString();
      if (addSymbol === true) {
        formatted = `£${formatted}`;
      }
      return formatted;
    }
    return value.toString();
  }
});