define("cheddargorge/components/apply-vouchercode-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "apply-vouchercode-container": "_apply-vouchercode-container_h47hy1",
    "apply-vouchercode-form": "_apply-vouchercode-form_h47hy1",
    "input": "_input_h47hy1",
    "action": "_action_h47hy1",
    "vouchercode-input": "_vouchercode-input_h47hy1",
    "error": "_error_h47hy1",
    "message": "_message_h47hy1",
    "title": "_title_h47hy1",
    "suggestion": "_suggestion_h47hy1"
  };
  _exports.default = _default;
});