define("cheddargorge/components/remove-vouchercode-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "53SOdfef",
    "block": "[[[10,\"form\"],[15,0,[29,[[28,[37,0],[\"remove-vouchercode-form\"],[[\"from\"],[\"cheddargorge/components/remove-vouchercode-form/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"title\"],[[\"from\"],[\"cheddargorge/components/remove-vouchercode-form/styles\"]]]]]],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[[28,[37,0],[\"code\"],[[\"from\"],[\"cheddargorge/components/remove-vouchercode-form/styles\"]]]]]],[12],[1,[33,1,[\"promotion\",\"code\"]]],[13],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"label\",\"theme\",\"size\",\"onclick\"],[\"Remove\",\"subtle\",\"small\",[28,[37,3],[[30,0],\"submit\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"description\"],[[\"from\"],[\"cheddargorge/components/remove-vouchercode-form/styles\"]]]]]],[12],[2,[33,1,[\"promotion\",\"description\"]]],[13],[1,\"\\n\"],[13]],[],false,[\"local-class\",\"promotionLine\",\"button-text\",\"action\"]]",
    "moduleName": "cheddargorge/components/remove-vouchercode-form/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});