define("cheddargorge/components/wysiwyg-field/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "M60w4jCR",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],null,[[\"mobiledoc\",\"cardNames\"],[[28,[37,3],[[33,4]],null],[33,5]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"hasMobiledoc\",\"render-mobiledoc\",\"json-parse\",\"content\",\"cardNames\"]]",
    "moduleName": "cheddargorge/components/wysiwyg-field/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});