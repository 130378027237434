define("cheddargorge/components/product-carousel/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ujHhADRi",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],[[33,1]],null]],[1,\"\\n\"]],[]],null],[1,[28,[35,3],null,[[\"images\"],[[33,4]]]]]],[],false,[\"if\",\"ribbonLabel\",\"ui-ribbon\",\"picture-gallery\",\"images\"]]",
    "moduleName": "cheddargorge/components/product-carousel/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});