define("cheddargorge/components/grid-picture-cards/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/TY7heAn",
    "block": "[[[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"grid-picture-card\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "cheddargorge/components/grid-picture-cards/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});