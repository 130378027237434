define("cheddargorge/components/opening-hours-today/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iXT+NsBz",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[33,2,[\"todaysOpeningTime\"]]],[1,\" - \"],[1,[33,2,[\"todaysClosingTime\"]]],[1,\"\\n\"]],[]],[[[1,\"  Closed\\n\"]],[]]]],[],false,[\"if\",\"isOpenToday\",\"openingTimes\"]]",
    "moduleName": "cheddargorge/components/opening-hours-today/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});