define("cheddargorge/routes/order/show/payment/index", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service", "moment"], function (_exports, _route, _rsvp, _object, _service, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    session: (0, _service.inject)('session-user'),
    router: (0, _service.inject)(),
    beforeModel() {
      if (window.location !== window.top.location) {
        window.top.location = window.location;
      }
    },
    model() {
      const order = this.modelFor('order.show');
      return order.get('orderPaymentMethods').then(this._mapBy.bind(this, 'shopPaymentMethod')).then(this._mapByUniq.bind(this, 'paymentMethod')).then(this._createPaymentRecord.bind(this, order));
    },
    afterModel(payment) {
      const order = this.modelFor('order.show');
      (0, _object.set)(this, 'headData.title', 'Payment | Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
      if ((0, _object.get)(payment, 'order.balance') === 0) {
        //@ts-ignore
        this.session.resetBasket();
        return this.transitionTo('order.show.complete', (0, _object.get)(payment, 'order.uuid'));
      }
      return order.get('orderLines').then(this._mapBy.bind(this, 'promotion'));
    },
    resetController(controller) {
      this._super(...arguments);
      (0, _object.set)(controller, 'errors', []);
    },
    actions: {
      createPayment(payment) {
        (0, _object.set)(this, 'controller.isSubmitting', true);
        return payment.save().then(this._reloadOrder.bind(this)).then(this._didSave.bind(this)).catch(this._handleCreatePaymentErrors.bind(this)).finally(this._finishCreatingPayment.bind(this));
      }
    },
    sagepayOrderPaymentMethod(order) {
      const orderPaymentMethods = order.get('orderPaymentMethods');
      return orderPaymentMethods.findBy('shopPaymentMethod.paymentMethod.content.name', 'Sagepay Direct');
    },
    _didSave(_ref) {
      let [order, payment] = _ref;
      const balance = order.get('balance');
      const session = this.session;
      if (balance <= 0) {
        return session.resetBasket().then(this._successfullyResetBasket.bind(this, payment));
      }
    },
    _reloadOrder(payment) {
      const store = this.store;
      return (0, _rsvp.all)([store.findRecord('order', (0, _object.get)(payment, 'order.id')), payment]);
    },
    _handleCreatePaymentErrors(e) {
      (0, _object.set)(this, 'controller.errors', e.errors);
    },
    _finishCreatingPayment() {
      (0, _object.set)(this, 'controller.isSubmitting', false);
    },
    _successfullyResetBasket(payment) {
      return this.transitionTo('order.show.complete', (0, _object.get)(payment, 'order.uuid'));
    },
    _mapBy(key, list) {
      return _rsvp.default.all(list.mapBy(key));
    },
    _mapByUniq(key, list) {
      return _rsvp.default.all(list.mapBy(key).uniq());
    },
    _createPaymentRecord(order) {
      const store = this.store;
      const orderPaymentMethod = this.sagepayOrderPaymentMethod(order);
      let urlError = `${window.location.protocol}//${window.location.host}${this.router.urlFor('order.show.payment.challenge-complete', order)}?error=challenge_failed`;
      let urlSuccess = `${window.location.protocol}//${window.location.host}${this.router.urlFor('order.show.payment.challenge-complete', order)}`;
      return store.createRecord('payment', {
        amount: orderPaymentMethod.maxPayableAmount,
        order: order,
        shopPaymentMethod: orderPaymentMethod.shopPaymentMethod,
        token: '',
        challengeCompletionUrl: '',
        browserJavascriptEnabled: true,
        browserJavaEnabled: navigator.javaEnabled,
        browserColorDepth: screen.colorDepth,
        browserScreenHeight: screen.height,
        browserScreenWidth: screen.width,
        browserTimezone: (0, _moment.default)().utcOffset(),
        browserLanguage: navigator.language,
        challengeSuccessUrl: urlSuccess,
        challengeFailedUrl: urlError
      });
    }
  });
  _exports.default = _default;
});