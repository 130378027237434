define("cheddargorge/components/ui-form-select/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uzxeeB9f",
    "block": "[[[8,[39,0],null,[[\"@options\",\"@selected\",\"@onchange\",\"@renderInPlace\",\"@searchEnabled\",\"@placeholder\",\"@tabindex\"],[[99,1,[\"@options\"]],[99,2,[\"@selected\"]],[99,3,[\"@onchange\"]],true,false,[99,4,[\"@placeholder\"]],[99,5,[\"@tabindex\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]]]]]],[\"value\",\"&default\"],false,[\"power-select\",\"options\",\"selected\",\"onchange\",\"placeholder\",\"tabindex\",\"yield\"]]",
    "moduleName": "cheddargorge/components/ui-form-select/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});