define("cheddargorge/routes/checkout/privacy-policy", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    storeHelper: (0, _service.inject)("store-helper"),
    model() {
      const storeHelper = this.storeHelper;
      return storeHelper.queryRecord("mumbo-jumbo", {
        slug: "privacy-policy"
      }, true);
    }
  });
  _exports.default = _default;
});