define("cheddargorge/components/commerce/extras/component", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "ember-concurrency-decorators"], function (_exports, _component, _service, _object, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let CommerceExtras = (_class = class CommerceExtras extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "goods", _descriptor, this);
      _initializerDefineProperty(this, "sessionUser", _descriptor2, this);
    }
    get isLoading() {
      return this.args.productsData.isLoading;
    }
    get products() {
      return this.args.productsData.value;
    }
    get orderedProducts() {
      return this.products.sortBy('attributes.order');
    }
    get isSubmitting() {
      //@ts-ignore
      return this.addToBasketTask.isRunning;
    }
    onAddToBasket(basketItems) {
      //@ts-ignore
      this.addToBasketTask.perform(this.goods, this.sessionUser.get('basket'), basketItems);
    }
    *addToBasketTask(goods, basket, basketItems) {
      yield goods.addToBasket(basketItems, basket);
      window.scrollTo(0, 0);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "goods", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onAddToBasket", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onAddToBasket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToBasketTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "addToBasketTask"), _class.prototype)), _class);
  _exports.default = CommerceExtras;
});