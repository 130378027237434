define("cheddargorge/components/global/nav/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "global-nav": "_global-nav_bhmacb",
    "navs-wrapper": "_navs-wrapper_bhmacb",
    "nav-wrapper": "_nav-wrapper_bhmacb",
    "brand": "_brand_bhmacb",
    "nav": "_nav_bhmacb",
    "cheddar-gorge-logo": "_cheddar-gorge-logo_bhmacb",
    "major-nav": "_major-nav_bhmacb",
    "minor-nav": "_minor-nav_bhmacb",
    "search-link": "_search-link_bhmacb",
    "basket-link": "_basket-link_bhmacb",
    "icon": "_icon_bhmacb",
    "quantity": "_quantity_bhmacb"
  };
  _exports.default = _default;
});