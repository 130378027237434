define("cheddargorge/components/privacy-notification/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ux7PYxCl",
    "block": "[[[41,[30,0,[\"acceptsCookies\"]],[[],[]],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,1],[\"privacy-notification\"],[[\"from\"],[\"cheddargorge/components/privacy-notification/styles\"]]]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,1],[\"content\"],[[\"from\"],[\"cheddargorge/components/privacy-notification/styles\"]]]]]],[12],[1,\"\\n      \"],[10,0],[15,0,[29,[[28,[37,1],[\"message\"],[[\"from\"],[\"cheddargorge/components/privacy-notification/styles\"]]]]]],[12],[1,\"\\n        We use cookies on this site to help give you the best online experience possible. Cookies enable us, and our third party partners, to personalise content you see on this and other websites, provide social media features and analyse our web traffic. By continuing to use this website you are consenting to our \"],[8,[39,2],[[16,0,[29,[[28,[37,1],[\"footer-link\"],[[\"from\"],[\"cheddargorge/components/privacy-notification/styles\"]]]]]]],[[\"@route\",\"@model\"],[\"mumbo-jumbo.show\",\"cookies\"]],[[\"default\"],[[[[1,\"Cookies Policy\"]],[]]]]],[1,\" and agreeing to the use of cookies. \\n      \"],[13],[1,\"\\n      \"],[10,0],[15,0,[29,[[28,[37,1],[\"actions\"],[[\"from\"],[\"cheddargorge/components/privacy-notification/styles\"]]]]]],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"label\",\"onclick\",\"class\"],[\"Accept & continue\",[28,[37,4],[[30,0],\"accept\"],null],[28,[37,5],[[28,[37,1],[\"accept-btn\"],[[\"from\"],[\"cheddargorge/components/privacy-notification/styles\"]]]],null]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"local-class\",\"link-to\",\"ui-button\",\"action\",\"concat\"]]",
    "moduleName": "cheddargorge/components/privacy-notification/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});