define("cheddargorge/components/commerce/field/session.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sessions": "_sessions_gqb564",
    "is-showing-finish-time": "_is-showing-finish-time_gqb564",
    "session-placeholder": "_session-placeholder_gqb564",
    "not-available-message": "_not-available-message_gqb564"
  };
  _exports.default = _default;
});