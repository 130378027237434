define("cheddargorge/components/site-search-result-partner/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result-partner',
    partner: (0, _computed.alias)('result.data'),
    image: (0, _computed.alias)('partner.cardImage.originalUrl'),
    typeSlug: (0, _computed.alias)('partner.contentGroup.apiIdentifier'),
    hasMetaTitle: (0, _computed.notEmpty)('partner.metaTitle'),
    route: (0, _object.computed)('slug', function () {
      return `products.${this.slug}`;
    }),
    slug: (0, _computed.alias)('partner.slug')
  });
  _exports.default = _default;
});