define("cheddargorge/components/calendar-key-small-print/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "calendar-key-small-print": "_calendar-key-small-print_f96vls",
    "small-print": "_small-print_f96vls",
    "qualifier": "_qualifier_f96vls"
  };
  _exports.default = _default;
});