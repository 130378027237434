define("cheddargorge/components/more-tesco-vouchers-message/component", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "ember-concurrency", "@ember/service", "@ember/utils"], function (_exports, _component, _computed, _object, _emberConcurrency, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'more-tesco-vouchers-message',
    orderId: null,
    order: null,
    orderPaymentMethods: (0, _computed.alias)('order.orderPaymentMethods'),
    store: (0, _service.inject)(),
    isLoading: (0, _computed.alias)('loadOrderTask.isRunning'),
    tescoBalance: (0, _object.computed)('orderPaymentMethods.@each.shopPaymentMethod', function () {
      const orderPaymentMethods = this.orderPaymentMethods;
      const tescoOrderPaymentMethod = orderPaymentMethods.findBy('shopPaymentMethod.paymentMethod.content.name', 'Tesco Clubcard');
      return tescoOrderPaymentMethod.maxPayableAmount === undefined ? 0 : tescoOrderPaymentMethod.maxPayableAmount;
    }),
    hasTescoBalance: (0, _computed.gt)('tescoBalance', 0),
    loadOrderTask: (0, _emberConcurrency.task)(function* (orderId) {
      let order = yield this.store.findRecord('order', orderId);
      (0, _object.set)(this, 'order', order);
    }).restartable(),
    didReceiveAttrs() {
      this._super();
      let orderId = this.orderId;
      if ((0, _utils.isEmpty)(this.orderId) == false) {
        this.loadOrderTask.perform(orderId);
      }
    }
  });
  _exports.default = _default;
});