define("cheddargorge/components/ui/message", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div local-class="ui-message {{this.themeClass}} {{if this.isCentered "is-centered"}} {{if this.hasBottomMargin "has-bottom-margin"}}" 
    data-test-message
    ...attributes
  >
    {{yield}}
  </div>
  
  */
  {
    "id": "a2lHjE1c",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[[28,[37,1],[\"ui-message \",[30,0,[\"themeClass\"]],\" \",[52,[30,0,[\"isCentered\"]],\"is-centered\"],\" \",[52,[30,0,[\"hasBottomMargin\"]],\"has-bottom-margin\"]],null]],[[\"from\"],[\"cheddargorge/components/ui/message.scss\"]]]]]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"local-class\",\"concat\",\"if\",\"yield\"]]",
    "moduleName": "cheddargorge/components/ui/message.hbs",
    "isStrictMode": false
  });
  class UiMessage extends _component2.default {
    get theme() {
      return this.args.theme ?? 'default';
    }
    get isCentered() {
      return this.args.isCentered ?? false;
    }
    get hasBottomMargin() {
      return this.args.hasBottomMargin ?? false;
    }
    get themeClass() {
      return `theme-${this.theme}`;
    }
  }
  _exports.default = UiMessage;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiMessage);
});