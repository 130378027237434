define("cheddargorge/components/payment-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "short-details": "_short-details_1uf4p2",
    "ui-form-fieldset": "_ui-form-fieldset_1uf4p2"
  };
  _exports.default = _default;
});