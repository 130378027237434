define("cheddargorge/components/iconic-actions/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "iconic-actions": "_iconic-actions_1m0v6u"
  };
  _exports.default = _default;
});