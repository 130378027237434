define("cheddargorge/components/grid-picture-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-picture-card": "_grid-picture-card_jmae6e",
    "card-inner": "_card-inner_jmae6e"
  };
  _exports.default = _default;
});