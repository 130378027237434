define("cheddargorge/components/notification-card/component", ["exports", "@ember/utils", "@ember/object", "@ember/component", "ember-concurrency-decorators", "@ember/service", "@ember/object/computed", "ember-css-modules"], function (_exports, _utils, _object, _component, _emberConcurrencyDecorators, _service, _computed, _emberCssModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NotificationCard = (_dec = (0, _emberCssModules.localClassNames)('notification-card'), _dec2 = (0, _emberCssModules.localClassName)(), _dec3 = (0, _computed.alias)('loadNotificationTask.isRunning'), _dec4 = (0, _emberConcurrencyDecorators.lastValue)('loadNotificationTask'), _dec(_class = (_class2 = class NotificationCard extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "isPopupExpanded", true);
      _initializerDefineProperty(this, "isPopupClosed", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "isLoading", _descriptor3, this);
      _initializerDefineProperty(this, "notification", _descriptor4, this);
    }
    onTogglePopup() {
      return (0, _object.set)(this, 'isPopupExpanded', !this.isPopupExpanded);
    }
    onClosePopup() {
      return (0, _object.set)(this, 'isPopupClosed', true);
    }
    *loadNotificationTask(store) {
      let notification = yield store.query('notification', {});
      if (!(0, _utils.isPresent)(notification)) {
        (0, _object.set)(this, 'isPopupClosed', true);
      }
      return (0, _object.get)(notification, 'firstObject');
    }
    didReceiveAttrs() {
      //@ts-ignore
      this.loadNotificationTask.perform(this.store);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isPopupClosed", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isLoading", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "notification", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onTogglePopup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onTogglePopup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onClosePopup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onClosePopup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadNotificationTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadNotificationTask"), _class2.prototype)), _class2)) || _class);
  _exports.default = NotificationCard;
});