define("cheddargorge/controllers/order/show/payment/tesco", ["exports", "@ember/controller", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    isSaving: false,
    errors: null,
    payment: (0, _computed.alias)("model"),
    isPaymentTokenEmpty: (0, _computed.empty)("payment.token"),
    cannotSubmit: (0, _computed.alias)("isPaymentTokenEmpty"),
    order: (0, _computed.alias)("payment.order"),
    payments: (0, _computed.alias)("order.payments"),
    persistedPayments: (0, _computed.filterBy)("payments", "isNew", false),
    tescoPayments: (0, _computed.filter)("persistedPayments", function (payment) {
      return (0, _object.get)(payment, "shopPaymentMethod.paymentMethod.content.name") === "Tesco Clubcard";
    }),
    hasTescoPayments: (0, _computed.notEmpty)("tescoPayments"),
    promotionLines: (0, _computed.filter)("payment.order.orderLines", function (orderLine) {
      return (0, _utils.isPresent)((0, _object.get)(orderLine, "promotion.content"));
    })
  });
  _exports.default = _default;
});