define("cheddargorge/components/template/upsell.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "upsell": "_upsell_1ei70g",
    "confirmation-pane": "_confirmation-pane_1ei70g",
    "confirmation-icon": "_confirmation-icon_1ei70g",
    "confirmation-title": "_confirmation-title_1ei70g",
    "confirmation-body": "_confirmation-body_1ei70g",
    "confirmation-content": "_confirmation-content_1ei70g",
    "actions": "_actions_1ei70g",
    "header": "_header_1ei70g"
  };
  _exports.default = _default;
});