define("cheddargorge/components/ui-form-inset-textfield/component", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _component, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'ui-form-inset-textfield',
    localClassNameBindings: ['isActivated', 'showMiniLabel', 'hasError', 'isValid', 'canClear', 'isDisabled'],
    notValidating: (0, _computed.not)('validations.isValidating'),
    didValidate: (0, _computed.oneWay)('target.didValidate'),
    hasContent: (0, _computed.notEmpty)('value'),
    isValid: (0, _computed.and)('hasContent', 'validations.isValid', 'notValidating'),
    isInvalid: (0, _computed.oneWay)('validations.isInvalid'),
    hasError: (0, _computed.alias)('showMessage'),
    hasTextfieldMessage: (0, _computed.notEmpty)('textfieldMessage'),
    canClear: (0, _computed.and)('isActivated', 'hasContent'),
    isDisabled: false,
    showErrorClass: (0, _computed.and)('notValidating', 'showMessage', 'hasContent', 'validations'),
    showMessage: (0, _object.computed)('validations.isDirty', 'isInvalid', 'didValidate', function () {
      return ((0, _object.get)(this, 'validations.isDirty') || this.didValidate) && this.isInvalid;
    }),
    showMiniLabel: (0, _object.computed)('isActivated', 'value', function () {
      return this.isActivated || (0, _utils.isPresent)(this.value);
    }),
    actions: {
      clear() {
        (0, _object.set)(this, 'value', null);
      }
    },
    focusIn() {
      (0, _object.set)(this, 'isActivated', true);
    }
  });
  _exports.default = _default;
});