define("cheddargorge/components/global-nav-buffer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "global-nav-buffer": "_global-nav-buffer_qco01g"
  };
  _exports.default = _default;
});