define("cheddargorge/components/site-search/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search": "_search_1bxnui",
    "search-input-wrapper": "_search-input-wrapper_1bxnui",
    "search-input": "_search-input_1bxnui",
    "results": "_results_1bxnui",
    "is-loading": "_is-loading_1bxnui",
    "ui-spinner": "_ui-spinner_1bxnui",
    "is-too-short": "_is-too-short_1bxnui",
    "no-results": "_no-results_1bxnui"
  };
  _exports.default = _default;
});