define("cheddargorge/components/calendar-month-day/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "X3soxGOZ",
    "block": "[[[10,0],[15,0,[29,[\"calendar-month-day--day \",[28,[37,0],[\"day\"],[[\"from\"],[\"cheddargorge/components/calendar-month-day/styles\"]]]]]],[12],[1,\"\\n  \"],[1,[28,[35,1],[[33,2,[\"day\"]],\"D\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"local-class\",\"moment-format\",\"day\"]]",
    "moduleName": "cheddargorge/components/calendar-month-day/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});