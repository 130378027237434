define("cheddargorge/components/order-completion-layout/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "order-completion-layout": "_order-completion-layout_1dhwov",
    "column-wrapper": "_column-wrapper_1dhwov",
    "please-wait": "_please-wait_1dhwov"
  };
  _exports.default = _default;
});