define("cheddargorge/components/global/menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "global-menu": "_global-menu_1b66fg",
    "megamenu": "_megamenu_1b66fg",
    "appearance-reveal": "_appearance-reveal_1b66fg",
    "emphasise": "_emphasise_1b66fg",
    "title": "_title_1b66fg",
    "title-link": "_title-link_1b66fg",
    "group": "_group_1b66fg",
    "appearance-default": "_appearance-default_1b66fg",
    "priority-group": "_priority-group_1b66fg",
    "primary-group": "_primary-group_1b66fg",
    "link": "_link_1b66fg",
    "is-disabled": "_is-disabled_1b66fg",
    "is-awesome": "_is-awesome_1b66fg"
  };
  _exports.default = _default;
});