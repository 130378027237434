define("cheddargorge/components/remove-vouchercode-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "remove-vouchercode-form-wrapper": "_remove-vouchercode-form-wrapper_11g0yz",
    "title": "_title_11g0yz",
    "code": "_code_11g0yz",
    "component-ui-button-text": "_component-ui-button-text_11g0yz",
    "label": "_label_11g0yz"
  };
  _exports.default = _default;
});