define("cheddargorge/components/site-search-result-attraction-area/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'site-search-result-attraction-area',
    attractionArea: (0, _computed.alias)('result.content_entry'),
    image: (0, _computed.alias)('attractionArea.attributes.hero-banner-image.firstObject'),
    typeSlug: (0, _computed.alias)('attractionArea.content_group.api_identifier'),
    hasMetaTitle: (0, _computed.notEmpty)('attractionArea.attributes.meta-title.firstObject'),
    route: (0, _object.computed)('slug', function () {
      const slug = this.slug;
      if (slug === 'adventure-activities') {
        return 'rocksport';
      }
      return slug;
    }),
    slug: (0, _computed.alias)('attractionArea.slug')
  });
  _exports.default = _default;
});