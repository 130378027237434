define("cheddargorge/components/box-raised/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: 'box-raised',
    localClassNames: 'box-raised'
  });
  _exports.default = _default;
});