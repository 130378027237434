define("cheddargorge/components/picture-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "image": "_image_3fysoj",
    "image-wrapper": "_image-wrapper_3fysoj",
    "content": "_content_3fysoj",
    "picture-card": "_picture-card_3fysoj"
  };
  _exports.default = _default;
});