define("cheddargorge/components/checkout-progress-bar/component", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'checkout-progress-bar',
    isDeliveryActive: (0, _computed.equal)('step', 'delivery'),
    isExtrasActive: (0, _computed.equal)('step', 'extras'),
    isDetailsActive: (0, _computed.equal)('step', 'details'),
    isPaymentActive: (0, _computed.equal)('step', 'payment'),
    isFinishActive: (0, _computed.equal)('step', 'finish'),
    isFinishDone: (0, _computed.alias)('isFinishActive'),
    isDeliveryDone: (0, _computed.or)('isDeliveryActive', 'isExtrasDone'),
    isExtrasDone: (0, _computed.or)('isDetailsActive', 'isDetailsDone'),
    isDetailsDone: (0, _computed.or)('isPaymentActive', 'isPaymentDone'),
    isPaymentDone: (0, _computed.or)('isFinishActive', 'isFinishDone')
  });
  _exports.default = _default;
});