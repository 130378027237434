define("cheddargorge/constants/basket-item-metadata-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CHRISTMAS_GIFT_FIELDS = void 0;
  const CHRISTMAS_GIFT_FIELDS = {
    fullName: {
      label: "Child's full name*",
      type: 'short-text'
    },
    age: {
      label: 'Age when visiting*',
      type: 'select',
      options: ['0 to 12 months', '1 year old', '2 years old', '3 years old', '4 years old', '5 years old', '6 years old', '7 years old', '8 years old', '9 years old', '10 years old and over']
    },
    favouriteHobbies: {
      label: 'What are your child’s favourite hobbies?',
      type: 'short-text'
    }
  };
  _exports.CHRISTMAS_GIFT_FIELDS = CHRISTMAS_GIFT_FIELDS;
});