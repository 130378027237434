define("cheddargorge/templates/search/query", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/pMD8JqN",
    "block": "[[[1,[28,[35,0],null,[[\"query\",\"index\",\"changedQuery\"],[[33,1,[\"query\"]],\"prod_cheddargorge_v2\",[28,[37,2],[[30,0],\"changedQuery\"],null]]]]]],[],false,[\"site-search\",\"model\",\"action\"]]",
    "moduleName": "cheddargorge/templates/search/query.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});