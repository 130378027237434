define("cheddargorge/helpers/pence-to-pounds", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.penceToPounds = penceToPounds;
  function penceToPounds(params) {
    return params[0] / 100;
  }
  var _default = _helper.default.helper(penceToPounds);
  _exports.default = _default;
});