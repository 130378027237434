define("cheddargorge/helpers/json-parse", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.jsonParse = jsonParse;
  function jsonParse(params /*, hash*/) {
    return JSON.parse(params[0]);
  }
  var _default = _helper.default.helper(jsonParse);
  _exports.default = _default;
});