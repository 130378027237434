define("cheddargorge/routes/visitor-info/maps", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service"], function (_exports, _route, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    model() {
      let store = this.store;
      return _rsvp.default.hash({
        maps: store.query("visitor-info", {
          filter: {
            slug: "maps"
          }
        }).then(this._getFirstObject.bind(this)),
        assets: store.query("asset", {
          filter: {
            slug: "cheddar-map-2022"
          }
        })
      });
    },
    afterModel(hash) {
      (0, _object.set)(this, "headData.title", hash.maps.metaTitle);
      (0, _object.set)(this, "headData.description", hash.maps.metaDescription);
    },
    _getFirstObject(object) {
      return object.firstObject;
    }
  });
  _exports.default = _default;
});