define("cheddargorge/components/commerce/product/selector-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "product-selector-card": "_product-selector-card_2ta6m4",
    "product-image": "_product-image_2ta6m4",
    "skus": "_skus_2ta6m4",
    "title": "_title_2ta6m4",
    "find-out-more-btn": "_find-out-more-btn_2ta6m4",
    "add-to-basket-btn": "_add-to-basket-btn_2ta6m4",
    "gallery": "_gallery_2ta6m4",
    "images": "_images_2ta6m4",
    "image": "_image_2ta6m4",
    "pager": "_pager_2ta6m4",
    "pager-item": "_pager-item_2ta6m4",
    "is-active": "_is-active_2ta6m4"
  };
  _exports.default = _default;
});