define("cheddargorge/templates/groups/resource", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RrsNFyur",
    "block": "[[[1,[28,[35,0],null,[[\"image\"],[[33,1,[\"heroBannerImage\"]]]]]],[1,\"\\n\\n\"],[6,[39,2],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\\n    \"],[1,[28,[35,4],null,[[\"label\",\"link\"],[\"Back to Groups\",\"groups\"]]]],[1,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[33,1,[\"name\"]]],[13],[1,\"\\n    \"],[1,[28,[35,5],[[33,1,[\"description\"]]],null]],[1,\"\\n\\n\"],[41,[33,1,[\"ctaLink\"]],[[[1,\"      \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,3],[15,6,[33,1,[\"ctaLink\"]]],[12],[1,[33,1,[\"ctaLabel\"]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"],[41,[33,1,[\"gridImage1\"]],[[[1,\"  \"],[1,[28,[35,8],null,[[\"large\",\"topSmall\",\"bottomSmall\"],[[33,1,[\"gridImage1\"]],[33,1,[\"gridImage2\"]],[33,1,[\"gridImage3\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"hero-banner\",\"model\",\"ui-background\",\"section-body\",\"ui-breadcrumbs\",\"wysiwyg-field\",\"if\",\"section-double-actions\",\"picture-grid\"]]",
    "moduleName": "cheddargorge/templates/groups/resource.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});