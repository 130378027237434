define("cheddargorge/components/iconic-action/component", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'iconic-action',
    localClassNameBindings: ['themeClass'],
    isExternalLink: false,
    themeClass: (0, _object.computed)('theme', function () {
      if ((0, _utils.isEmpty)(this.theme)) {
        return null;
      }
      return 'theme-' + this.theme;
    })
  });
  _exports.default = _default;
});