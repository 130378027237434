define("cheddargorge/components/park-map/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "park-map": "_park-map_9y08qn",
    "is-first": "_is-first_9y08qn",
    "title": "_title_9y08qn",
    "download-btn": "_download-btn_9y08qn"
  };
  _exports.default = _default;
});