define("cheddargorge/components/ui/button.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-button": "_ui-button_16j9gq",
    "ui-spinner": "_ui-spinner_16j9gq",
    "is-loading": "_is-loading_16j9gq",
    "label": "_label_16j9gq",
    "large": "_large_16j9gq",
    "small": "_small_16j9gq",
    "theme-text": "_theme-text_16j9gq"
  };
  _exports.default = _default;
});