define("cheddargorge/routes/groups/resource", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    storeHelper: (0, _service.inject)("store-helper"),
    model(params) {
      const storeHelper = this.storeHelper;
      return storeHelper.queryRecord("group-resource", {
        slug: params.slug
      }, true);
    },
    serialize: function (workshops) {
      return {
        slug: workshops.slug
      };
    },
    _getFirstObject(object) {
      return object.firstObject;
    }
  });
  _exports.default = _default;
});