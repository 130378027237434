define("cheddargorge/components/product-layout/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aMfZPLQU",
    "block": "[[[18,1,[[33,1]]],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,2],[\"product-layout-primary\"],[[\"from\"],[\"cheddargorge/components/product-layout/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,2],[\"carousel-wrapper\"],[[\"from\"],[\"cheddargorge/components/product-layout/styles\"]]]]]],[12],[1,\"\\n    \"],[18,1,[[33,3]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,2],[\"form-wrapper\"],[[\"from\"],[\"cheddargorge/components/product-layout/styles\"]]]]]],[12],[1,\"\\n    \"],[18,1,[[33,4]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,[[33,5]]],[1,\"\\n\"],[18,1,[[33,6]]],[1,\"\\n\"],[18,1,[[33,7]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"breadcrumbs\",\"local-class\",\"carousel\",\"form\",\"details\",\"needToKnow\",\"terms\"]]",
    "moduleName": "cheddargorge/components/product-layout/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});