define("cheddargorge/components/site-search-result-product/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lOLAn3+H",
    "block": "[[[8,[39,0],[[16,0,[29,[\"site-search-result--result \",[28,[37,1],[\"result\"],[[\"from\"],[\"cheddargorge/components/site-search-result-product/styles\"]]]]]]],[[\"@route\",\"@model\"],[\"product\",[30,0,[\"product\",\"slug\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"site-search-result--result-image\"],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"product\",\"cardHeroImage\",\"originalUrl\"]]],[15,\"alt\",[30,0,[\"product\",\"name\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"site-search-result--result-content\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"site-search-result--result-title \",[28,[37,1],[\"result-title\"],[[\"from\"],[\"cheddargorge/components/site-search-result-product/styles\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasMetaTitle\"]],[[[1,\"        \"],[1,[30,0,[\"product\",\"metaTitle\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"product\",\"name\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"site-search-result--result-summary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"product\",\"metaDescription\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"site-search-result--result-actions\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@label\",\"@onclick\"],[\"Prices and booking\",[28,[37,4],[\"product\",[30,0,[\"product\",\"slug\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"link-to\",\"local-class\",\"if\",\"ui-button\",\"transition-to\"]]",
    "moduleName": "cheddargorge/components/site-search-result-product/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});