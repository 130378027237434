define("cheddargorge/components/basket-item-summary/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "basket-item-summary": "_basket-item-summary_1avv4e",
    "has-zero-quantity": "_has-zero-quantity_1avv4e",
    "product-title": "_product-title_1avv4e",
    "line-price": "_line-price_1avv4e",
    "basket-item-properties": "_basket-item-properties_1avv4e",
    "label": "_label_1avv4e",
    "value": "_value_1avv4e",
    "sku-image": "_sku-image_1avv4e",
    "sku-details": "_sku-details_1avv4e",
    "sku-pricing": "_sku-pricing_1avv4e",
    "actions": "_actions_1avv4e",
    "separator": "_separator_1avv4e",
    "edit-btn": "_edit-btn_1avv4e",
    "remove-btn": "_remove-btn_1avv4e",
    "save-btn": "_save-btn_1avv4e",
    "cancel-btn": "_cancel-btn_1avv4e",
    "edit-basket-item": "_edit-basket-item_1avv4e",
    "error-messages": "_error-messages_1avv4e"
  };
  _exports.default = _default;
});