define("cheddargorge/components/commerce/upsell/card.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "product-name": "_product-name_1ky34r",
    "product-image": "_product-image_1ky34r",
    "product-description": "_product-description_1ky34r",
    "find-out-more": "_find-out-more_1ky34r",
    "visitors-summary": "_visitors-summary_1ky34r",
    "upsell-card": "_upsell-card_1ky34r",
    "visitors-field": "_visitors-field_1ky34r",
    "guests": "_guests_1ky34r",
    "change-label": "_change-label_1ky34r",
    "tickets": "_tickets_1ky34r",
    "gift-info": "_gift-info_1ky34r",
    "gifts-heading": "_gifts-heading_1ky34r",
    "gifts": "_gifts_1ky34r",
    "total-price": "_total-price_1ky34r",
    "total-price-label": "_total-price-label_1ky34r",
    "total-price-value": "_total-price-value_1ky34r",
    "total-price-placeholder": "_total-price-placeholder_1ky34r",
    "upsell-price-note": "_upsell-price-note_1ky34r",
    "entry-ticket-options": "_entry-ticket-options_1ky34r"
  };
  _exports.default = _default;
});