define("cheddargorge/components/calendar-key/component", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'calenday-key-wrapper',
    sorting: ['order:asc'],
    sortedKey: (0, _computed.sort)('key', 'sorting')
  });
  _exports.default = _default;
});