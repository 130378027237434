define("cheddargorge/components/product-layout/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "product-layout": "_product-layout_1y5ob9",
    "product-layout-primary": "_product-layout-primary_1y5ob9",
    "carousel-wrapper": "_carousel-wrapper_1y5ob9",
    "form-wrapper": "_form-wrapper_1y5ob9",
    "small-print": "_small-print_1y5ob9",
    "product-benefits": "_product-benefits_1y5ob9",
    "product-terms": "_product-terms_1y5ob9",
    "component-ui-breadcrumbs": "_component-ui-breadcrumbs_1y5ob9"
  };
  _exports.default = _default;
});