define("cheddargorge/components/price-table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "price-table": "_price-table_1ebjpk",
    "is-centered": "_is-centered_1ebjpk",
    "is-strong": "_is-strong_1ebjpk",
    "has-strikethrough": "_has-strikethrough_1ebjpk",
    "rows-are-selectable": "_rows-are-selectable_1ebjpk",
    "explanation-row": "_explanation-row_1ebjpk"
  };
  _exports.default = _default;
});