define("cheddargorge/components/global-footer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "global-footer": "_global-footer_4cbnj9",
    "content": "_content_4cbnj9",
    "contacts": "_contacts_4cbnj9",
    "contact": "_contact_4cbnj9",
    "social": "_social_4cbnj9",
    "links": "_links_4cbnj9",
    "corporate": "_corporate_4cbnj9",
    "cheddar": "_cheddar_4cbnj9",
    "mumbo-jumbo": "_mumbo-jumbo_4cbnj9",
    "footer-link": "_footer-link_4cbnj9",
    "icons": "_icons_4cbnj9",
    "icons-payment-cards": "_icons-payment-cards_4cbnj9",
    "icons-affiliations": "_icons-affiliations_4cbnj9",
    "customer-services": "_customer-services_4cbnj9",
    "copyright": "_copyright_4cbnj9"
  };
  _exports.default = _default;
});