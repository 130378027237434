define("cheddargorge/controllers/application", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    scrollToTop: true,
    dummy: (0, _computed.alias)("model")
  });
  _exports.default = _default;
});