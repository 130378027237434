define("cheddargorge/templates/head", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "V7oEmuJa",
    "block": "[[[10,\"title\"],[12],[1,[33,0,[\"title\"]]],[13],[1,\"\\n\"],[41,[33,0,[\"description\"]],[[[1,\"  \"],[10,\"meta\"],[14,3,\"description\"],[15,\"content\",[33,0,[\"description\"]]],[12],[13],[1,\"\\n\"]],[]],null],[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[33,0,[\"title\"]]],[12],[13],[1,\"\\n\"],[41,[33,0,[\"canonical\"]],[[[1,\"  \"],[10,\"link\"],[14,\"rel\",\"canonical\"],[15,6,[33,0,[\"canonical\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"model\",\"if\"]]",
    "moduleName": "cheddargorge/templates/head.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});