define("cheddargorge/components/purchase-promise/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "purchase-promise-wrapper": "_purchase-promise-wrapper_1qbvd8",
    "purchase-promise": "_purchase-promise_1qbvd8",
    "cheddar-mark": "_cheddar-mark_1qbvd8",
    "title": "_title_1qbvd8",
    "promises": "_promises_1qbvd8",
    "promise": "_promise_1qbvd8",
    "icon": "_icon_1qbvd8",
    "of-security": "_of-security_1qbvd8",
    "of-value": "_of-value_1qbvd8",
    "of-instant-tickets": "_of-instant-tickets_1qbvd8"
  };
  _exports.default = _default;
});