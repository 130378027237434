define("cheddargorge/controllers/order/show/payment/index", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/object/computed"], function (_exports, _controller, _object, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    queryParams: ["error"],
    isSubmitting: false,
    errors: null,
    error: null,
    payment: (0, _computed.alias)("model"),
    order: (0, _computed.alias)("payment.order"),
    isChallengingPayment: (0, _computed.notEmpty)("payment.challengeUrl"),
    promotionLines: (0, _computed.filter)("payment.order.orderLines", function (orderLine) {
      return (0, _utils.isPresent)((0, _object.get)(orderLine, "promotion.content"));
    })
  });
  _exports.default = _default;
});