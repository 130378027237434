define("cheddargorge/routes/partners", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    afterModel() {
      (0, _object.set)(this, "headData.title", "Business Partnership Scheme | Cheddar Gorge");
      (0, _object.set)(this, "headData.description", "");
    }
  });
  _exports.default = _default;
});