define("cheddargorge/components/hero-carousel/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YxhxISBa",
    "block": "[[[41,[30,0,[\"isSingleImage\"]],[[[41,[33,1,[\"firstObject\",\"ctaLink\"]],[[[1,\"    \"],[10,3],[15,6,[33,1,[\"firstObject\",\"ctaLink\"]]],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"image\"],[[33,1,[\"firstObject\",\"heroBannerImage\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"image\"],[[33,1,[\"firstObject\",\"heroBannerImage\"]]]]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@autoplay\",\"@speed\",\"@pagination\",\"@navigation\",\"@loop\"],[[28,[37,4],null,[[\"delay\"],[8000]]],300,[28,[37,4],null,[[\"clickable\"],[true]]],false,true]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,1]],null]],null],null,[[[1,\"      \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"ctaLink\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"ctaLink\"]]],[12],[1,\"\\n            \"],[1,[28,[35,2],null,[[\"image\"],[[30,1,[\"heroBannerImage\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,2],null,[[\"image\"],[[30,1,[\"heroBannerImage\"]]]]]],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"banner\"],false,[\"if\",\"banners\",\"hero-banner\",\"swiper-container\",\"hash\",\"each\",\"-track-array\",\"swiper-slide\"]]",
    "moduleName": "cheddargorge/components/hero-carousel/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});