define("cheddargorge/templates/products/partners", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1F1+o8Br",
    "block": "[[[6,[39,0],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n  \"],[1,[34,1]],[1,\"\\n\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Business Partnership Scheme\"],[13],[1,\"\\n\\n    \"],[10,\"h3\"],[12],[1,[33,3,[\"message\"]]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"      \"],[8,[30,1,[\"item\"]],null,[[\"@cardImage\",\"@product\",\"@ctaLinkLabel\",\"@displayProduct\",\"@label\"],[[30,2,[\"attributes\",\"cardHeroImage\"]],[30,2],\"Prices and booking\",[28,[37,8],[\"product\",[30,2,[\"slug\"]]],null],[30,2,[\"attributes\",\"tagline\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"template--title\"],[12],[1,\"\\n          \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"template--caption\"],[12],[1,\"\\n          \"],[1,[30,2,[\"summary\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\\n  \"],[1,[34,9]],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"],[1,[34,10]]],[\"card\",\"product\"],false,[\"ui-background\",\"global-nav-buffer\",\"section-intro\",\"partnerSkuFields\",\"product-cards\",\"each\",\"-track-array\",\"products\",\"transition-to\",\"purchase-promise\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/products/partners.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});