define("cheddargorge/components/checkout-layout/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "checkout": "_checkout_1sb5et",
    "major-content": "_major-content_1sb5et",
    "minor-content": "_minor-content_1sb5et",
    "checkout-layout": "_checkout-layout_1sb5et",
    "checkout-btn-xs": "_checkout-btn-xs_1sb5et",
    "component-ui-link-button-raised": "_component-ui-link-button-raised_1sb5et",
    "is-full-width": "_is-full-width_1sb5et"
  };
  _exports.default = _default;
});