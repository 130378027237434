define("cheddargorge/helpers/safe-background-image", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.safeBackgroundImage = safeBackgroundImage;
  function safeBackgroundImage(params) {
    return (0, _string.htmlSafe)(`background: url('${params[0]}') no-repeat center center / cover`);
  }
  var _default = _helper.default.helper(safeBackgroundImage);
  _exports.default = _default;
});