define("cheddargorge/components/ui-label-value/component", ["exports", "@ember/string", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _string, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let component = _component.default.extend({
    localClassNames: 'ui-label-value',
    safeLabelWidth: (0, _object.computed)('labelWidth', function () {
      if ((0, _utils.isEmpty)(this.labelWidth)) {
        return (0, _string.htmlSafe)('');
      }
      return (0, _string.htmlSafe)('width: ' + this.labelWidth + ';');
    }),
    safeMarginLeft: (0, _object.computed)('labelWidth', function () {
      if ((0, _utils.isEmpty)(this.labelWidth)) {
        return (0, _string.htmlSafe)('');
      }
      return (0, _string.htmlSafe)('margin-left: ' + this.labelWidth + ';');
    })
  });
  component.reopenClass({
    positionalParams: ['label']
  });
  var _default = component;
  _exports.default = _default;
});