define("cheddargorge/components/checkout-layout/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "446/jOLZ",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"checkout\"],[[\"from\"],[\"cheddargorge/components/checkout-layout/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"major-content\"],[[\"from\"],[\"cheddargorge/components/checkout-layout/styles\"]]]]]],[12],[1,\"\\n    \"],[18,1,[[33,2]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"minor-content\"],[[\"from\"],[\"cheddargorge/components/checkout-layout/styles\"]]]]]],[12],[1,\"\\n    \"],[18,1,[[33,3]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"yield\",\"majorContent\",\"minorContent\"]]",
    "moduleName": "cheddargorge/components/checkout-layout/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});