define("cheddargorge/helpers/is-mobiledoc-empty", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isMobiledocEmpty = isMobiledocEmpty;
  function isMobiledocEmpty(params /*, hash*/) {
    if ((0, _utils.isEmpty)(params[0])) {
      return true;
    }
    let json = JSON.parse(params[0]);
    return json.atoms.length === 0 && json.cards.length === 0 && json.markups.length === 0 && json.sections.length === 1 && json.sections[0][0] === 1 && json.sections[0][1] === 'p' && json.sections[0][2].length === 0;
  }
  var _default = _helper.default.helper(isMobiledocEmpty);
  _exports.default = _default;
});