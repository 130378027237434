define("cheddargorge/components/iconic-action/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "iconic-action": "_iconic-action_1hgy65",
    "small": "_small_1hgy65",
    "icon": "_icon_1hgy65",
    "theme-slate": "_theme-slate_1hgy65",
    "theme-sulphur": "_theme-sulphur_1hgy65",
    "theme-azurite": "_theme-azurite_1hgy65",
    "theme-jade": "_theme-jade_1hgy65"
  };
  _exports.default = _default;
});