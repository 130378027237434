define("cheddargorge/components/hero-banner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "l8Y4L6Qb",
    "block": "[[[1,\"\\n\"],[10,0],[15,0,[29,[\"hero-banner--banner \",[28,[37,0],[\"banner\"],[[\"from\"],[\"cheddargorge/components/hero-banner/styles\"]]]]]],[15,5,[36,1]],[14,\"role\",\"img\"],[15,\"aria-label\",[36,2]],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"local-class\",\"imageCSS\",\"alt\",\"yield\"]]",
    "moduleName": "cheddargorge/components/hero-banner/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});