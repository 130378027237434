define("cheddargorge/services/store-helper", ["exports", "@ember/service", "@ember/utils"], function (_exports, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    store: (0, _service.inject)("store"),
    async queryRecord(modelType, filter) {
      let raise404 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let include = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      let params = {
        filter: filter
      };
      if (!(0, _utils.isNone)(include)) {
        params.include = include;
      }
      let list = await this.store.query(modelType, params);
      if (raise404) {
        if (list.length === 0) {
          return new Promise(function (resolve, reject) {
            reject({
              status: 404
            });
          });
        }
      }
      return list.firstObject;
    }
  });
  _exports.default = _default;
});