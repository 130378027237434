define("cheddargorge/components/more-tesco-vouchers-message/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "message": "_message_1t56uw"
  };
  _exports.default = _default;
});