define("cheddargorge/components/privacy-notification/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "privacy-notification": "_privacy-notification_jv65kw",
    "slideInNotification": "_slideInNotification_jv65kw",
    "content": "_content_jv65kw",
    "message": "_message_jv65kw",
    "actions": "_actions_jv65kw",
    "accept-btn": "_accept-btn_jv65kw"
  };
  _exports.default = _default;
});