define("cheddargorge/components/hero-banner-large/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1tM44zFE",
    "block": "[[[8,[39,0],null,[[\"@image\",\"@alt\"],[[99,1,[\"@image\"]],[99,2,[\"@alt\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,1,[[28,[37,4],null,[[\"caption\"],[[50,\"hero-banner-large-caption\",0,null,null]]]]]],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"hero-banner\",\"image\",\"alt\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "cheddargorge/components/hero-banner-large/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});