define("cheddargorge/components/ui-label-value/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "G+nTPYy2",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"label\"],[[\"from\"],[\"cheddargorge/components/ui-label-value/styles\"]]]]]],[15,5,[36,1]],[12],[1,[34,2]],[1,\":\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"value\"],[[\"from\"],[\"cheddargorge/components/ui-label-value/styles\"]]]]]],[15,5,[36,3]],[12],[18,1,null],[13]],[\"&default\"],false,[\"local-class\",\"safeLabelWidth\",\"label\",\"safeMarginLeft\",\"yield\"]]",
    "moduleName": "cheddargorge/components/ui-label-value/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});