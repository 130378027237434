define("cheddargorge/components/site-search-result-partner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RjMJZY24",
    "block": "[[[8,[39,0],[[24,0,\"site-search-result--result\"]],[[\"@route\"],[[30,0,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"image\"]],[[[1,\"    \"],[10,0],[14,0,\"site-search-result--result-image\"],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,0,[\"image\"]]],[15,\"alt\",[30,0,[\"partner\",\"name\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"site-search-result--result-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"site-search-result--result-title\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasMetaTitle\"]],[[[1,\"        \"],[1,[30,0,[\"partner\",\"metaTitle\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"partner\",\"name\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"site-search-result--result-summary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"partner\",\"metaDescription\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"site-search-result--result-actions\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"site-search-result--action\"],[12],[1,\"Read more\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"link-to\",\"if\"]]",
    "moduleName": "cheddargorge/components/site-search-result-partner/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});