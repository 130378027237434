define("cheddargorge/components/ui-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1QH72jGG",
    "block": "[[[18,1,[[28,[37,1],null,[[\"fieldset\",\"checkbox\",\"select\",\"inset-textfield\",\"Fieldset\",\"TextfieldInset\",\"Select\"],[[50,\"ui-form-fieldset\",0,null,null],[50,\"ui-form-checkbox\",0,null,null],[50,\"ui-form-select\",0,null,null],[50,\"ui-form-inset-textfield\",0,null,null],[50,\"ui-form-fieldset\",0,null,null],[50,\"ui-form-inset-textfield\",0,null,null],[50,\"ui-form-select\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "cheddargorge/components/ui-form/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});