define("cheddargorge/components/calendar-month/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7DmmoEBZ",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"calendar\"],[[\"from\"],[\"cheddargorge/components/calendar-month/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"days-of-week\"],[[\"from\"],[\"cheddargorge/components/calendar-month/styles\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,0],[\"day-of-week\"],[[\"from\"],[\"cheddargorge/components/calendar-month/styles\"]]]]]],[12],[1,\"\\n        \"],[1,[28,[35,4],[[30,1],\"ddd\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"days\"],[[\"from\"],[\"cheddargorge/components/calendar-month/styles\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,5]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],null,[[\"day\",\"displayDate\",\"selectedDate\",\"selectDate\"],[[30,2],[33,7],[33,8],[33,9]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"dayOfWeek\",\"day\"],false,[\"local-class\",\"each\",\"-track-array\",\"daysOfWeek\",\"moment-format\",\"days\",\"calendar-month-day\",\"displayDate\",\"selectedDate\",\"selectDate\"]]",
    "moduleName": "cheddargorge/components/calendar-month/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});