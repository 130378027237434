define("cheddargorge/templates/checkout/privacy-policy", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qrIXvAAw",
    "block": "[[[8,[39,0],null,[[\"@closeLink\"],[\"checkout.index\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"Privacy Policy\"],[13],[1,\"\\n  \"],[1,[28,[35,1],[[33,2,[\"description\"]]],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"modal-smart\",\"wysiwyg-field\",\"model\"]]",
    "moduleName": "cheddargorge/templates/checkout/privacy-policy.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});