define("cheddargorge/components/section-intro/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "section-intro": "_section-intro_1sfbg8"
  };
  _exports.default = _default;
});