define("cheddargorge/components/basket/warning-message/rock-climbing-only-has-child", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="Please note"
    >
      <p>
        You only have child tickets in your basket - This activity is dedicated to older adventurers from age 13 up. If your children are between the ages of 13 and 15, they  must be supervised by an adult at all times while participating in the rock climbing activity.
      </p>
    </Basket::WarningMessage>
  {{/if}}
  */
  {
    "id": "HFSnHyHN",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"Please note\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      You only have child tickets in your basket - This activity is dedicated to older adventurers from age 13 up. If your children are between the ages of 13 and 15, they  must be supervised by an adult at all times while participating in the rock climbing activity.\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/rock-climbing-only-has-child.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageRockClimbingOnlyHasChild extends _component2.default {
    /**
     * Is the warning message visible? Shows if there are Rock Climbing
     * tickets for children in the basket and none for adults
     */
    get isVisible() {
      let productBasketItems = this.args.basketItems.filterBy('sku.content.product.slug', 'rocksport-rock-climbing');
      let adults = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Adult');
      let children = productBasketItems.filterBy('sku.content.attributes.name.firstObject', 'Child');
      return (0, _utils.isPresent)(children) && (0, _utils.isEmpty)(adults);
    }
  }
  _exports.default = BasketWarningMessageRockClimbingOnlyHasChild;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageRockClimbingOnlyHasChild);
});