define("cheddargorge/models/attraction", ["exports", "ember-data", "ember-goods/models/content-entry"], function (_exports, _emberData, _contentEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  var _default = _contentEntry.default.extend({
    slug: attr(),
    name: attr(),
    attrs: attr(),
    heroBannerImage: attr(),
    cardImage: attr(),
    cardCaption: attr(),
    description: attr(),
    extraInformation: attr(),
    ctaUrl: attr('string'),
    ctaLabel: attr('string'),
    attractionArea: belongsTo(),
    callToActions: hasMany('attraction-call-to-action'),
    gridImage1: attr(),
    gridImage2: attr(),
    gridImage3: attr(),
    gridImage4: attr(),
    gridImage5: attr(),
    gridImage6: attr(),
    fileDownload1: attr(),
    fileDownloadLabel1: attr(),
    order: attr(),
    metaTitle: attr(),
    metaDescription: attr(),
    likes: attr(),
    created: attr(),
    sections: hasMany('attraction-section')
  });
  _exports.default = _default;
});