define("cheddargorge/routes/subscriptions/subscription", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    storeHelper: (0, _service.inject)("store-helper"),
    model(params) {
      const storeHelper = this.storeHelper;
      return storeHelper.queryRecord("subscription-list", {
        slug: params.slug
      }, true);
    },
    serialize: function (subscriptionList) {
      return {
        slug: subscriptionList.slug
      };
    }
  });
  _exports.default = _default;
});