define("cheddargorge/routes/education/workshop-type", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service"], function (_exports, _route, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    storeHelper: (0, _service.inject)("store-helper"),
    queryParams: {
      filter: {
        refreshModel: true
      }
    },
    model(params) {
      const storeHelper = this.storeHelper;
      const store = this.store;
      return _rsvp.default.hash({
        education: storeHelper.queryRecord("organisation-department", {
          slug: "education"
        }),
        workshopType: storeHelper.queryRecord("education-workshop-type", {
          slug: params.slug
        }, true),
        resources: store.findAll("education-resource").then(this._getResources.bind(this))
      });
    },
    afterModel(hash) {
      (0, _object.set)(this, "headData.title", hash.workshopType.metaTitle);
      (0, _object.set)(this, "headData.description", hash.workshopType.metaDescription);
    },
    serialize: function (event) {
      return {
        slug: event.slug
      };
    },
    _getFirstObject(object) {
      return object.firstObject;
    },
    _getResources(resources) {
      return {
        planningAndBooking: resources.findBy("slug", "planning-and-booking"),
        healthAndSafety: resources.findBy("slug", "health-and-safety"),
        pricesAndPayment: resources.findBy("slug", "prices-and-payment"),
        prehistoryForTeachers: resources.findBy("slug", "prehistory-for-teachers-resources"),
        rocksAndGeographyForTeachers: resources.findBy("slug", "rocks-and-geography-for-teachers"),
        wildlifeAndEcologyForTeachers: resources.findBy("slug", "wildlife-and-ecology-for-teachers"),
        businessAndTourismForTeachers: resources.findBy("slug", "business-and-tourism-for-teachers")
      };
    }
  });
  _exports.default = _default;
});