define("cheddargorge/components/hero-banner/component", ["exports", "@ember/component", "@ember/object", "@ember/string", "ember"], function (_exports, _component, _object, _string, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Handlebars
  } = _ember.default;
  var _default = _component.default.extend({
    localClassNames: 'hero-banner',
    classNames: 'hero-banner',
    escapedImage: (0, _object.computed)('image', function () {
      return Handlebars.Utils.escapeExpression(this.image);
    }),
    imageCSS: (0, _object.computed)('escapedImage', function () {
      return new _string.htmlSafe("background: url('" + this.escapedImage + "') no-repeat center top; background-size: cover;");
    })
  });
  _exports.default = _default;
});