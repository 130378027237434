define("cheddargorge/components/attraction-social-actions/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jV/qs2tL",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"share-attraction\"],[[\"from\"],[\"cheddargorge/components/attraction-social-actions/styles\"]]]]]],[12],[1,\"\\n  \"],[1,[34,1]],[1,\"\\n\"],[13]],[],false,[\"local-class\",\"share-with-friends\"]]",
    "moduleName": "cheddargorge/components/attraction-social-actions/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});