define("cheddargorge/components/basket-items-summary/component", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _component, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'basket-items-summary',
    isVouchercodeFieldShowable: false,
    isLoading: (0, _computed.alias)('init.isRunning'),
    isBasketEmpty: (0, _computed.empty)('basketItems'),
    hasBasketItems: (0, _computed.notEmpty)('basketItems'),
    isShowingRemoveVouchercode: (0, _computed.alias)('hasVouchercode'),
    hasVouchercode: (0, _computed.notEmpty)('promotionLines'),
    hasNoVouchercode: (0, _computed.not)('hasVouchercode'),
    hasAPVoucherCode: (0, _computed.equal)('vouchercode.content.code', 'ANNUALPASSHOLDER'),
    visibleBasketItems: (0, _computed.filterBy)('basketItems', 'isHidden', false),
    hasTescoTickets: (0, _computed.notEmpty)('tescoTickets'),
    isShowingVouchercodeField: (0, _computed.or)('isVouchercodeFieldShowable', 'hasVouchercode'),
    isShowingApplyVouchercode: (0, _computed.and)('isVouchercodeFieldShowable', 'hasNoVouchercode'),
    tescoTickets: (0, _computed.filter)('basketItems', function (basketItem) {
      const isTescoField = (0, _object.get)(basketItem, 'sku.product.productFields').findBy('slug', 'is-tesco');
      return (0, _utils.isPresent)(isTescoField) && isTescoField.values == true;
    }),
    entryTicketBasketItems: (0, _computed.filter)('basketItems', function (basketItem) {
      return (0, _object.get)(basketItem, 'sku.product.attributes.is-entry-ticket') === true;
    }),
    hasNoEntryTickets: (0, _computed.empty)('entryTicketBasketItems'),
    adultSkus: (0, _computed.filter)('basketItems', function (basketItem) {
      return ['16-59yrs', '16yrs+', '60yrs+'].includes((0, _object.get)(basketItem.sku, 'content.attributes.age.firstObject')) || ['Child', 'Infant', 'Under 5', 'Carer'].includes((0, _object.get)(basketItem.sku, 'content.attributes.name.firstObject')) === false;
    }),
    childSkus: (0, _computed.filter)('basketItems', function (basketItem) {
      return ['5-15yrs'].includes((0, _object.get)(basketItem.sku, 'content.attributes.age.firstObject')) || ['Child', 'Infant', 'Under 5'].includes((0, _object.get)(basketItem.sku, 'content.attributes.name.firstObject')) === true;
    }),
    carerSkus: (0, _computed.filter)('basketItems', function (basketItem) {
      return ['Carer'].includes((0, _object.get)(basketItem.sku, 'content.attributes.name.firstObject'));
    }),
    bookingDates: (0, _computed.mapBy)('basketItems', 'sku.content.attributes.bookable-date.firstObject'),
    filteredBookingDates: (0, _computed.filter)('bookingDates', function (date) {
      return (0, _utils.isPresent)(date);
    }),
    uniqueBookingDates: (0, _computed.uniq)('filteredBookingDates'),
    hasMultipleBookableDates: (0, _object.computed)('uniqueBookingDates.length', function () {
      if (this.uniqueBookingDates.length > 1) {
        return true;
      }
      return false;
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'code', '');
      if ((0, _utils.isPresent)(this.vouchercode)) {
        (0, _object.set)(this, 'code', (0, _object.get)(this, 'vouchercode.code'));
      }
    },
    actions: {
      showVouchercodeField() {
        (0, _object.set)(this, 'isVouchercodeFieldShowable', true);
      }
    }
  });
  _exports.default = _default;
});