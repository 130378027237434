define("cheddargorge/components/ui-form-checkbox/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-form-checkbox": "_ui-form-checkbox_wxz933",
    "has-error": "_has-error_wxz933",
    "checkbox-input": "_checkbox-input_wxz933",
    "checkbox-label": "_checkbox-label_wxz933",
    "error": "_error_wxz933"
  };
  _exports.default = _default;
});