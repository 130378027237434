define("cheddargorge/modifiers/observe-intersection", ["exports", "ember-intersection-observer-modifier/modifiers/observe-intersection"], function (_exports, _observeIntersection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _observeIntersection.default;
    }
  });
});