define("cheddargorge/components/broadcast-message/component", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "ember-concurrency", "@ember/service", "@ember/utils"], function (_exports, _computed, _component, _object, _emberConcurrency, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'broadcast-message',
    storeHelper: (0, _service.inject)('store-helper'),
    group: null,
    hasBroadcast: (0, _computed.notEmpty)('broadcast.body'),
    isNotClosed: (0, _computed.not)('broadcast.isClosed'),
    showBroadcast: (0, _computed.and)('hasBroadcast', 'isNotClosed', 'isTriggered'),
    isTriggered: (0, _object.computed)('group', 'broadcast.groups', function () {
      const group = this.group;
      const groups = (0, _object.get)(this, 'broadcast.groups');
      if ((0, _utils.isEmpty)(group)) {
        return true;
      }
      return groups.includes(group);
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'broadcast', []);
    },
    didReceiveAttrs() {
      this._super();
      this.fetchBroadcast.perform();
    },
    fetchBroadcast: (0, _emberConcurrency.task)(function* () {
      const storeHelper = this.storeHelper;
      let broadcast = yield storeHelper.queryRecord('broadcast', {});
      return (0, _object.set)(this, 'broadcast', broadcast);
    }).restartable(),
    actions: {
      close() {
        (0, _object.set)(this, 'broadcast.isClosed', true);
      }
    }
  });
  _exports.default = _default;
});