define("cheddargorge/components/section-instagram/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tjlEEpbE",
    "block": "[[[10,\"h3\"],[12],[1,\"Have your own adventure to share?\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"description\"],[[\"from\"],[\"cheddargorge/components/section-instagram/styles\"]]]]]],[12],[1,\"\\n  Tag your photos with #CheddarGorge and share your pictures and experiences with our online community\\n\"],[13],[1,\"\\n\"],[1,[34,1]],[1,\"\\n\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"gallery\"],[[\"from\"],[\"cheddargorge/components/section-instagram/styles\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"    \"],[10,3],[15,0,[29,[[28,[37,0],[\"gallery-image\"],[[\"from\"],[\"cheddargorge/components/section-instagram/styles\"]]]]]],[15,6,[30,1,[\"link\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,1,[\"src\"]]],[14,\"alt\",\"Gallery\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[10,3],[15,0,[29,[[28,[37,0],[\"cta-link\"],[[\"from\"],[\"cheddargorge/components/section-instagram/styles\"]]]]]],[14,6,\"https://www.instagram.com/cheddargorgeofficial/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n  See your photos\\n\"],[13],[1,\"\\n\"]],[\"image\"],false,[\"local-class\",\"icons-social\",\"each\",\"-track-array\",\"images\"]]",
    "moduleName": "cheddargorge/components/section-instagram/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});