define("cheddargorge/components/commerce/field/collapsible", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isCollapsed}}
    <button 
      local-class="collapsible-field toggle-expanded-btn {{if @hasBorder "has-border"}}" 
      type="button" 
      disabled={{this.isDisabled}}
      {{on "click" @onToggleExpand}}
      ...attributes
    >
      {{#if @label}}
        <div local-class="label">
          {{@label}}
        </div>
      {{/if}}
      <div local-class="collapsed">
        {{yield to="collapsed"}}
      </div>
      {{#unless this.isDisabled}}
        <div local-class="expand-action">
          {{@expandLabel}}
        </div>
      {{/unless}}
    </button>
  {{else}}
    <div local-class="collapsible-field {{if @hasBorder "has-border"}}" ...attributes>
      {{#if @label}}
        <div local-class="label">
          {{@label}}
        </div>
      {{/if}}
      <div local-class="expanded">
        {{yield to="expanded"}}
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "GQpE2U2w",
    "block": "[[[41,[30,0,[\"isCollapsed\"]],[[[1,\"  \"],[11,\"button\"],[16,0,[29,[[28,[37,1],[[28,[37,2],[\"collapsible-field toggle-expanded-btn \",[52,[30,1],\"has-border\"]],null]],[[\"from\"],[\"cheddargorge/components/commerce/field/collapsible.scss\"]]]]]],[24,4,\"button\"],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[17,2],[4,[38,3],[\"click\",[30,3]],null],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,1],[\"label\"],[[\"from\"],[\"cheddargorge/components/commerce/field/collapsible.scss\"]]]]]],[12],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[15,0,[29,[[28,[37,1],[\"collapsed\"],[[\"from\"],[\"cheddargorge/components/commerce/field/collapsible.scss\"]]]]]],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"isDisabled\"]]],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,1],[\"expand-action\"],[[\"from\"],[\"cheddargorge/components/commerce/field/collapsible.scss\"]]]]]],[12],[1,\"\\n        \"],[1,[30,5]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[16,0,[29,[[28,[37,1],[[28,[37,2],[\"collapsible-field \",[52,[30,1],\"has-border\"]],null]],[[\"from\"],[\"cheddargorge/components/commerce/field/collapsible.scss\"]]]]]],[17,2],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[10,0],[15,0,[29,[[28,[37,1],[\"label\"],[[\"from\"],[\"cheddargorge/components/commerce/field/collapsible.scss\"]]]]]],[12],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[15,0,[29,[[28,[37,1],[\"expanded\"],[[\"from\"],[\"cheddargorge/components/commerce/field/collapsible.scss\"]]]]]],[12],[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@hasBorder\",\"&attrs\",\"@onToggleExpand\",\"@label\",\"@expandLabel\",\"&collapsed\",\"&expanded\"],false,[\"if\",\"local-class\",\"concat\",\"on\",\"yield\",\"unless\"]]",
    "moduleName": "cheddargorge/components/commerce/field/collapsible.hbs",
    "isStrictMode": false
  });
  class CommerceFieldVisitors extends _component2.default {
    /**
     * Is the input disabled
     */
    get isDisabled() {
      return this.args.isDisabled ?? false;
    }

    /**
     * Showing the expanded view? Defaults that passed isExpanded parameter to
     * true.
     */
    get isExpanded() {
      return this.args.isExpanded ?? true;
    }

    /**
     * Showing the collapsed view? Convenience getter that simply returns the
     * inverse of isExpanded.
     */
    get isCollapsed() {
      return !this.isExpanded;
    }
  }
  _exports.default = CommerceFieldVisitors;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CommerceFieldVisitors);
});