define("cheddargorge/templates/error404", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qOJ0vEnK",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@image\"],[\"/images/heroes/skeleton.jpg\"]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[30,1,[\"caption\"]],null,[[\"@heading\",\"@subheading\"],[\"Page not found\",\"Oops, looks like no one's been here in a while.\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],null,[[\"label\",\"onclick\",\"size\"],[\"Back to home\",[28,[37,3],[\"index\"],null],\"large\"]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\\n\"],[1,[34,4]]],[\"banner\"],false,[\"find-it-fast\",\"hero-banner-large\",\"ui-button\",\"transition-to\",\"global-footer\"]]",
    "moduleName": "cheddargorge/templates/error404.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});