define("cheddargorge/components/calendar-controls/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mP4230pP",
    "block": "[[[10,0],[15,0,[29,[\"calendar-controls--controls \",[28,[37,0],[\"calendar-controls\"],[[\"from\"],[\"cheddargorge/components/calendar-controls/styles\"]]]]]],[12],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[\"calendar-controls--btn-previous \",[28,[37,0],[\"btn-previous\"],[[\"from\"],[\"cheddargorge/components/calendar-controls/styles\"]]]]]],[16,\"disabled\",[36,1]],[24,4,\"button\"],[4,[38,2],[[30,0],\"previousMonth\"],null],[12],[1,\"\\n    \"],[41,[51,[33,4]],[[[1,\"Previous\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[15,0,[29,[\"calendar-controls--date \",[28,[37,0],[\"date\"],[[\"from\"],[\"cheddargorge/components/calendar-controls/styles\"]]]]]],[12],[1,\"\\n    \"],[1,[28,[35,5],[[33,6],\"MMMM YYYY\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[\"calendar-controls--btn-next \",[28,[37,0],[\"btn-next\"],[[\"from\"],[\"cheddargorge/components/calendar-controls/styles\"]]]]]],[16,\"disabled\",[36,7]],[24,4,\"button\"],[4,[38,2],[[30,0],\"nextMonth\"],null],[12],[1,\"\\n    \"],[41,[51,[33,8]],[[[1,\"Next\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"local-class\",\"canDecrementMonth\",\"action\",\"unless\",\"hidePrevLabel\",\"moment-format\",\"displayDate\",\"canIncrementMonth\",\"hideNextLabel\"]]",
    "moduleName": "cheddargorge/components/calendar-controls/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});