define("cheddargorge/components/sku-quantity-minus-plus/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sku-quantity-minus-plus": "_sku-quantity-minus-plus_rw3njf",
    "quantity": "_quantity_rw3njf",
    "sku-details": "_sku-details_rw3njf",
    "increment": "_increment_rw3njf",
    "decrement": "_decrement_rw3njf",
    "minus": "_minus_rw3njf",
    "plus": "_plus_rw3njf",
    "quantity-value": "_quantity-value_rw3njf",
    "name": "_name_rw3njf",
    "price": "_price_rw3njf",
    "explanation": "_explanation_rw3njf"
  };
  _exports.default = _default;
});