define("cheddargorge/components/calendar-key/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "calendar-key": "_calendar-key_1bwqsr",
    "headings": "_headings_1bwqsr",
    "key-item": "_key-item_1bwqsr",
    "title": "_title_1bwqsr",
    "status": "_status_1bwqsr",
    "times": "_times_1bwqsr",
    "subheading": "_subheading_1bwqsr",
    "colour": "_colour_1bwqsr"
  };
  _exports.default = _default;
});