define("cheddargorge/components/hero-carousel/component", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'hero-carousel',
    isSingleImage: (0, _computed.equal)('banners.length', 1)
  });
  _exports.default = _default;
});