define("cheddargorge/components/product-details/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KYoi7Sor",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"title\"],[[\"from\"],[\"cheddargorge/components/product-details/styles\"]]]]]],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[34,1]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[[28,[37,0],[\"product-columns\"],[[\"from\"],[\"cheddargorge/components/product-details/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"product-prices\"],[[\"from\"],[\"cheddargorge/components/product-details/styles\"]]]]]],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Ticket prices\"],[13],[1,\"\\n\\n    \"],[1,[28,[35,2],null,[[\"fields\",\"data\"],[[33,3],[33,4]]]]],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[[28,[37,0],[\"small-print\"],[[\"from\"],[\"cheddargorge/components/product-details/styles\"]]]]]],[12],[1,\"\\n\"],[41,[33,6],[[[1,\"        * \"],[2,[36,6]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"product-description\"],[[\"from\"],[\"cheddargorge/components/product-details/styles\"]]]]]],[12],[1,\"\\n    \"],[18,1,[[33,8]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"title\",\"price-table\",\"priceTableFields\",\"priceTableData\",\"if\",\"smallPrint\",\"yield\",\"description\"]]",
    "moduleName": "cheddargorge/components/product-details/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});