define("cheddargorge/components/ui/link-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gWxuRDvz",
    "block": "[[[8,[39,0],[[16,0,[29,[[28,[37,1],[\"link-button\"],[[\"from\"],[\"cheddargorge/components/ui/link-button/styles\"]]]]]],[17,1]],[[\"@href\",\"@route\",\"@model\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[18,5,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@href\",\"@route\",\"@model\",\"&default\"],false,[\"ui/link-to\",\"local-class\",\"yield\"]]",
    "moduleName": "cheddargorge/components/ui/link-button/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});