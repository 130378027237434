define("cheddargorge/components/hero-banner-large-caption/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hero-banner-large-caption": "_hero-banner-large-caption_8w4sak",
    "banner-caption": "_banner-caption_8w4sak",
    "heading": "_heading_8w4sak",
    "subheading": "_subheading_8w4sak"
  };
  _exports.default = _default;
});