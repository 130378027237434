define("cheddargorge/components/wysiwyg-field/component", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var component = _component.default.extend({
    localClassNames: 'wysiwyg-field',
    content: null,
    hasMobiledoc: (0, _object.computed)('content', 'cardNames', function () {
      let hasMobiledoc = false;
      try {
        JSON.parse(this.content);
        hasMobiledoc = true;
      } catch (e) {
        hasMobiledoc = false;
      }
      return hasMobiledoc;
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'cardNames', ['mobiledoc-image-card', 'mobiledoc-table-card', 'mobiledoc-video-embed-card']);
    }
  });
  component.reopenClass({
    positionalParams: ['content']
  });
  var _default = component;
  _exports.default = _default;
});