define("cheddargorge/components/ui-breadcrumbs/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qLtESCat",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"route\",\"model\"],[[33,3],[33,1]]],[[\"default\"],[[[[1,\"    \"],[1,[34,4]],[1,\"\\n\"]],[]]]]]],[]],[[[6,[39,2],null,[[\"route\"],[[33,3]]],[[\"default\"],[[[[1,\"    \"],[1,[34,4]],[1,\"\\n\"]],[]]]]]],[]]]],[],false,[\"if\",\"linkModel\",\"link-to\",\"link\",\"label\"]]",
    "moduleName": "cheddargorge/components/ui-breadcrumbs/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});