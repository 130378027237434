define("cheddargorge/models/partner", ["exports", "ember-goods/models/content-entry", "@ember-data/model"], function (_exports, _contentEntry, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _contentEntry.default.extend({
    description: (0, _model.attr)("string"),
    needToKnow: (0, _model.attr)("string"),
    metaTitle: (0, _model.attr)("string"),
    metaDescription: (0, _model.attr)("string")
  });
  _exports.default = _default;
});