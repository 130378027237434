define("cheddargorge/components/checkout-layout/component", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'checkout-layout',
    majorContent: {
      isMajorContent: true
    },
    minorContent: {
      isMinorContent: true
    }
  });
  _exports.default = _default;
});