define("cheddargorge/templates/add-extras", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y++d0z+T",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@productsData\"],[[30,0,[\"model\",\"productsData\"]]]],null],[1,\"\\n\\n\"],[1,[34,2]],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"global-nav-buffer\",\"commerce/extras\",\"global-footer\",\"component\",\"-outlet\"]]",
    "moduleName": "cheddargorge/templates/add-extras.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});